/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Divider, Image, Modal, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { fetchJobBoardDetail, selectJobBoardDetail } from '../store/jobBoardDetailSlice';
import { AppDispatch } from '../../../core/store';
import { JobBoardDetailProductList } from '../components/JobBoardDetailProductList';
import style from './jobBoardDetail.style';
import { ModalPage } from '../../../shared/ModalPage';
import { UpdateJobBoard } from '../components/UpdateJobBoard';
import { selectUser } from '../../auth/store/userSlice';
import { checkRoles, ROLE } from '../../auth/model/user';
import { removeJobBoard } from '../store/jobBoardsSlice';
import { useAppDispatch } from '../../../core/coreHooks';

export const JobBoardDetail = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [isDetailOpen, setDetailOpen] = useState(false);
  const jobBoardDetail = useSelector(selectJobBoardDetail);
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(fetchJobBoardDetail(id || ''));
  }, [dispatch, id, user]);

  const isBroker = useCallback(() => checkRoles(user, [ROLE.BROKER]), [user]);

  // eslint-disable-next-line consistent-return
  const onClickCard = () => {
    if (isBroker()) {
      return () => setDetailOpen(true);
    }
  };

  const onDeleteJobBoard = async () => {
    if (jobBoardDetail != null) {
      await dispatch(removeJobBoard(jobBoardDetail.id));
      navigate('/job-boards');
    }
  };

  return (
    <>
      {isDetailOpen && jobBoardDetail && (
        <ModalPage onClose={() => setDetailOpen(false)} title={t('jobBoard.updateJobBoard')}>
          <UpdateJobBoard jobBoard={jobBoardDetail} onSave={() => setDetailOpen(false)} />
        </ModalPage>
      )}
      <div css={style.container}>
        <Segment css={style.segmentContainer}>
          <Card fluid css={style.cardDetail}>
            <Card.Content css={style.cardContent}>
              <Detail />
              {isBroker() && (
                <div css={style.editButton}>
                  <Button icon='edit' size='big' basic onClick={onClickCard()} />
                  <Modal
                    trigger={<Button size='big' icon='trash alternate outline' basic />}
                    header={t('confirmDelete')}
                    content={t('jobBoard.deleteMessage')}
                    actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => onDeleteJobBoard() }]}
                  />
                </div>
              )}
            </Card.Content>
          </Card>
        </Segment>
        <Divider style={{ margin: '0' }} />
        <div css={style.listContainer}>
          <JobBoardDetailProductList />
        </div>
      </div>
    </>
  );
};

const Detail = (): JSX.Element => {
  const { t } = useTranslation();
  const jobBoardDetail = useSelector(selectJobBoardDetail);
  return (
    <div css={style.detailContainer}>
      <div css={style.detailImageContainer}>
        <div style={{ margin: 'auto' }}>
          {jobBoardDetail?.picture && (
            <Image css={style.detailImage} src={`data:image/jpeg;base64,${jobBoardDetail?.picture.data}`} />
          )}
        </div>
      </div>
      <Box width={1}>
        <Flex flexWrap='wrap'>
          <Box width={[1]} p={1}>
            <Flex>
              <Box width={[1 / 2]} p={0}>
                <h4>{jobBoardDetail?.name}</h4>
              </Box>
              <Box width={[1 / 2]} p={0}>
                <div css={style.detailStatus(jobBoardDetail?.status)}>
                  Status: <span>{t(`jobBoard.status.${jobBoardDetail?.status}`)}</span>
                </div>
              </Box>
            </Flex>
          </Box>
          <Box width={[1]} p={1}>
            <div css={style.detailContentContainer} title={jobBoardDetail?.about}>
              <span css={style.detailDescription}>{jobBoardDetail?.about}</span>
            </div>
          </Box>
        </Flex>
      </Box>
    </div>
  );
};
