/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { ORDER_STATUS } from '../features/orders/model/order';
import { ALL_STATUSES } from '../features/brokerOrders/store/brokerOrderSlice';
import style from './statusFilter.style';

interface StatusFilterProps {
  defaultValue: string;
  changeStatusFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  userRole: 'recruiter' | 'broker';
}

export const StatusFilter = ({ changeStatusFilterHandler, defaultValue, userRole }: StatusFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const statusOptions = () => {
    const statuses = Object.values(ORDER_STATUS).map((status: string, index) => {
      return { key: status, value: status, text: t(`orderStatus.${status}.${userRole}`) };
    });
    statuses.unshift({ key: ALL_STATUSES, value: ALL_STATUSES, text: 'All' });
    return statuses;
  };

  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2} width='max-content'>
          <div>{t('filters.statusFilterLabel')}</div>
        </Box>
        <Box p={2} flex={1}>
          <Dropdown
            css={style.dropdown}
            inline
            options={statusOptions()}
            defaultValue={defaultValue}
            onChange={(event, data) => changeStatusFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
