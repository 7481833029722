import axios from 'axios';
import paths from './paths';
import { Basket, BasketUpdateRequest, toBasket } from '../model/basket';

const api = {
  getBasket: async (): Promise<Basket> => {
    const response = await axios.get(paths.getBasket());
    return toBasket(response.data);
  },
  updateBasket: async (basket: BasketUpdateRequest): Promise<Basket> => {
    const response = await axios.put(paths.getBasket(), basket);
    return toBasket(response.data);
  },
  doCheckout: async (): Promise<Basket> => {
    const response = await axios.post(paths.getBasketCheckout());
    return toBasket(response.data);
  },
};

export default api;

export interface PictureBase64CreationRequest {
  data: string;
  contentType: string;
}
