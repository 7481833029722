import { css } from '@emotion/react';

const label = css`
  && {
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default {
  label,
};
