/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown, DropdownProps, Icon, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import style from './userMenu.style';
import { selectUser, updateUserRegionsAndCountries } from '../features/auth/store/userSlice';
import { logoutAction } from '../core/store';
import { getCountries, REGION } from '../features/jobBoards/model/countries';
import { useAppDispatch } from '../core/coreHooks';

export interface RegionsAndCountriesOption {
  key: string;
  text: string;
  value: string;
  flag?: string;
}

export const UserMenu = (): JSX.Element => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const regionsStrings = Object.values(REGION)
    .filter(r => r !== REGION.GLOBAL)
    .map(r => r.toString());

  const onRegionOrCountryChange = (event: SyntheticEvent, data: DropdownProps) => {
    const countryCodes: string[] = [];
    const regions: string[] = [];
    (data.value as string[]).forEach((e: string) => {
      if (regionsStrings.includes(e)) {
        regions.push(e);
      } else {
        countryCodes.push(e);
      }
    });
    if (user != null) {
      dispatch(updateUserRegionsAndCountries(user.id, regions, countryCodes));
    }
  };

  const regionsAndCountriesOptions: RegionsAndCountriesOption[] = regionsStrings
    .map(r => {
      return {
        key: r,
        text: r,
        value: r,
      };
    })
    .concat(
      getCountries().map(c => {
        return {
          key: c.code,
          text: c.name,
          value: c.code,
          flag: c.code.toLowerCase(),
        };
      })
    );
  return (
    <div css={style.popupStyle}>
      <div css={style.userContainer}>
        <div css={style.iconStyle}>
          <Icon size='big' name='user' />
        </div>
        <div css={style.userDataContainer}>
          <div css={style.userLabel}>{user ? user.name : ''}</div>
          <div css={style.userLabel}>{user ? user.email : ''}</div>
        </div>
      </div>
      <div css={style.userContainer}>
        <Button basic fluid onClick={() => navigate('/company-profiles')}>
          <Icon name='warehouse' />
          {t('menu.companyProfiles')}
        </Button>
      </div>
      <div css={style.userContainer}>
        <div css={style.userDataContainer}>
          <p>{t('menu.regionsAndCountries')}</p>
          <Dropdown
            css={style.regionsAndCountriesDropdown}
            style={{ width: '100%' }}
            placeholder={t('menu.global')}
            value={user ? user.regions.map(r => r.toString()).concat(user.countryCodes) : []}
            fluid
            multiple
            search
            pointing='top'
            selection
            options={regionsAndCountriesOptions}
            onChange={(event, data) => onRegionOrCountryChange(event, data)}
          />
        </div>
      </div>

      <List />
      <Button primary css={style.buttonStyle} type='button' onClick={() => logout()}>
        <Icon name='sign-out' />
        LOGOUT
      </Button>
    </div>
  );
};
