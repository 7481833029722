import { css } from '@emotion/react';

const datepickerContainer = css`
  &&& .react-datepicker-popper {
    z-index: 4;
  }
`;

const datepicker = css`
  height: 20px;
`;

const filter = css`
  display: flex;
  height: max-content;
  width: 200px;
  align-items: center;
  border-bottom: solid 1px var(--jbmp-primary-color-60);
`;

const filterInput = css`
  height: 22px;
  width: 150px;
  border-radius: 0 0 4px 4px !important;
  &&&& input {
    background-color: var(--jbmp-primary-color-10);
    border: none;
  }
`;

export default {
  filter,
  filterInput,
  datepickerContainer,
  datepicker,
};
