/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import style from './companyProfileCard.style';
import { Company, CompanyProfile } from '../model/companyProfile';

interface CompanyProfileCardProps {
  onClick?: () => void;
  companyProfile: CompanyProfile | Company | null;
  enableValidation?: boolean;
}

export const CompanyProfileCard = ({
  companyProfile,
  onClick,
  enableValidation = false,
}: CompanyProfileCardProps): JSX.Element => {
  const { t } = useTranslation();

  const isInvalid = useMemo(() => enableValidation && companyProfile == null, [companyProfile, enableValidation]);

  const cardStyle = useMemo(
    () => ({
      ...(isInvalid ? { border: '1px solid red', borderRadius: '7px', boxShadow: '0 0 4px red' } : {}),
      ...(onClick ? { cursor: 'pointer' } : {}),
    }),
    [isInvalid, onClick]
  );

  return (
    <div css={style.card} onClick={onClick} style={cardStyle}>
      <Flex flexDirection='column' style={{ height: '100%' }}>
        {companyProfile && (
          <>
            <Box css={style.companyText as never}>
              <strong>{companyProfile.name}</strong>
              <div css={style.address} title={companyProfile.address}>
                {companyProfile.address}
              </div>
            </Box>
            {companyProfile.vatNumber && (
              <Box css={style.vatText as never}>
                <span css={style.vat}>{t('companyProfile.vatNumber')}:</span> {companyProfile.vatNumber}
              </Box>
            )}
            {companyProfile.invoiceContact && (
              <Box css={style.invoiceContact as never}>
                <span css={style.vat}>{t('companyProfile.invoiceContact')}:</span> {companyProfile.invoiceContact}
              </Box>
            )}
          </>
        )}
        {companyProfile === null && <div>{t('companyProfile.noCompanyProfileSelected')}</div>}
      </Flex>
    </div>
  );
};
