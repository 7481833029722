import { css } from '@emotion/react';

const formContainer = css`
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const form = css`
  color: black;
  font-size: 1.2rem;
  overflow: auto;
  height: 100%;
`;

const label = css`
  float: left;
`;

const button = css`
  margin: 15px;
  &&& {
    width: min(200px, 40%);
  }
`;

const picturesListContainer = css`
  padding: 0 10px 10px;
`;

const textArea = css`
  width: 100%;
`;

const formButtons = css`
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 15px;
  margin-top: auto;
`;

const datepicker = css`
  //height: 20px;
`;

const datePickerInput = css`
  max-width: 150px;
`;

export default {
  formContainer,
  form,
  label,
  picturesListContainer,
  textArea,
  button,
  formButtons,
  datepicker,
  datePickerInput,
};
