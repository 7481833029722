import { css, SerializedStyles } from '@emotion/react';

const container: SerializedStyles = css`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export default {
  container,
};
