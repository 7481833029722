/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent } from 'react';
import { Button, Checkbox, Dropdown, DropdownProps, Form, Grid, Input, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ManagedUser } from '../model/managedUser';
import style from './updateManagedUser.style';
import { ROLE } from '../../auth/model/user';
import { BRANDS, BrandsOption } from '../../common/brand';

interface UpdateManagedUserProps {
  user: ManagedUser;
  onSubmit: (user: ManagedUser) => void;
}

export const UpdateManagedUser = ({ user, onSubmit }: UpdateManagedUserProps): JSX.Element => {
  const [firstName, setFirstName] = React.useState<string>(user.firstName);
  const [lastName, setLastName] = React.useState<string>(user.lastName);
  const [roles, setRoles] = React.useState<string[]>(user.roles);
  const [brands, setBrands] = React.useState<string[]>(user.brands);

  const { t } = useTranslation();

  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onChangeRoles = (role: ROLE, value: boolean) => {
    let newRoles = [...roles];
    if (value) {
      if (!newRoles.includes(role)) {
        newRoles.push(role);
      }
    } else if (newRoles.includes(role)) {
      newRoles = newRoles.filter(r => r !== role);
    }
    setRoles(newRoles);
  };

  const onBrandsChange = (event: SyntheticEvent, data: DropdownProps) => {
    setBrands(data.value as string[]);
  };

  const onUpdateManagedUser = async () => {
    onSubmit({
      ...user,
      firstName,
      lastName,
      roles,
      brands,
    } as ManagedUser);
  };

  return (
    <div css={style.form}>
      <Form p={0}>
        <Grid>
          <Grid.Column p={0}>
            {user.type === 'EXTERNAL' && (
              <>
                <Form.Field>
                  <Label css={style.label}>{t('managedUser.firstName')}</Label>
                  <Input fluid value={firstName} type='text' onChange={event => onChangeFirstName(event)} />
                </Form.Field>
                <Form.Field>
                  <Label css={style.label}>{t('managedUser.lastName')}</Label>
                  <Input value={lastName} type='text' onChange={event => onChangeLastName(event)} />
                </Form.Field>
              </>
            )}

            <Form.Field>
              <Label css={style.label}>{t('managedUser.roles')}</Label>
              <div css={style.rolesContainer}>
                <Checkbox
                  style={{ padding: '5px' }}
                  checked={roles.includes(ROLE.ADMIN)}
                  label='Admin'
                  onChange={(e, { checked }) => onChangeRoles(ROLE.ADMIN, !!checked)}
                />
                <Checkbox
                  style={{ padding: '5px' }}
                  checked={roles.includes(ROLE.BROKER)}
                  label='Broker'
                  onChange={(e, { checked }) => onChangeRoles(ROLE.BROKER, !!checked)}
                />
                <Checkbox
                  style={{ padding: '5px' }}
                  checked={roles.includes(ROLE.RECRUITER)}
                  label='Recruiter'
                  onChange={(e, { checked }) => onChangeRoles(ROLE.RECRUITER, !!checked)}
                />
                <Checkbox
                  style={{ padding: '5px' }}
                  checked={roles.includes(ROLE.RECRUITER_SUPERVISOR)}
                  label='Recruiter Supervisor'
                  onChange={(e, { checked }) => onChangeRoles(ROLE.RECRUITER_SUPERVISOR, !!checked)}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <Label css={style.label}>{t('managedUser.brands')}</Label>
              <Dropdown
                style={{ width: '100%' }}
                placeholder={t('managedUser.brands')}
                value={brands}
                multiple
                selection
                search
                options={brandsOptions}
                onChange={(event, data) => onBrandsChange(event, data)}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
        <div css={style.button}>
          <Button primary fluid onClick={() => onUpdateManagedUser()}>
            {t('managedUser.update')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export const brandsOptions: BrandsOption[] = BRANDS.map(b => {
  return {
    key: b.key,
    text: b.name,
    value: b.key,
  };
});
