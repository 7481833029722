import { css } from '@emotion/react';

const container = css`
  display: flex;
  width: 100%;
  height: 45px;
  background-color: var(--jbmp-primary-color);
  align-items: center;
`;

const title = css`
  && {
    margin: auto;
    color: var(--jbmp-white-color);
  }
`;

const iconClass = css`
  &&.icon {
    margin-bottom: auto;
    margin-top: auto;
    color: var(--jbmp-white-color);
    cursor: pointer;
    margin-right: 15px;
    :hover {
      color: var(--jbmp-primary-color-40);
    }
  }
`;

const menuButtonContainer = css`
  && {
    display: flex;
    align-items: center;
  }
`;

const backIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    color: var(--jbmp-white-color);
    :hover {
      color: var(--jbmp-primary-color-40);
    }
  }
`;

const menuBasket = css`
  display: flex;
  color: var(--jbmp-white-color);
  position: relative;
`;

const basketQuantity = css`
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: red;
  border-radius: 12px;
  padding: 2px;
  color: var(--jbmp-white-color);
  font-size: 1.1rem;
  font-weight: 600;
  min-width: 24px;
  text-align: center;
  cursor: pointer;
`;

export default {
  container,
  title,
  iconClass,
  menuButtonContainer,
  backIcon,
  menuBasket,
  basketQuantity,
};
