/** @jsxImportSource @emotion/react */
import React, { ChangeEvent } from 'react';
import { Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './searchBox.style';

interface SearchBoxProps {
  defaultValue: string;
  onChange: (value: string) => void;
}

export const SearchBox = ({ defaultValue, onChange }: SearchBoxProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Input
      icon='search'
      placeholder={t('filters.searchPlaceholder')}
      css={style.search}
      defaultValue={defaultValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
    />
  );
};
