import { API_URL } from '../../../constants';

const BASKET__URL = `${API_URL}/basket`;

const paths = {
  getBasket: (): string => `${BASKET__URL}`,
  getBasketCheckout: (): string => `${BASKET__URL}/checkout`,
};

export default paths;
