import { css, SerializedStyles } from '@emotion/react';
import { JOB_BOARD_STATUS } from '../model/jobBoard';

const container = css`
  overflow: auto;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const group = css`
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  padding-left: 5px;
`;

const sectionTitle = (status: JOB_BOARD_STATUS): SerializedStyles => {
  const border = `&& {box-shadow: inset 0 -2px 0 0 var(--jbmp-primary-color-40)}`;
  return css`
    && {
      margin: 10px 0 10px 5px;
      color: var(--jbmp-primary-color-80);
      padding-bottom: 3px;
      ${border}
    }
  `;
};

const cardsContainer = css`
  overflow: auto;
  padding-right: 5px;
`;

const emptyContainer = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border: solid 1px var(--jbmp-primary-color-40);
    height: 50px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--jbmp-primary-color-60);
  }
`;

const header = css`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  align-items: center;
  height: max-content;
`;

const addButton = css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
  }
  @media (min-width: 800px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: unset;
      border-radius: 4px;
      margin-right: auto;
      :before {
        content: 'Create Job Board';
        padding-right: 5px;
      }
    }
  }
`;

const searchBox = css`
  flex: 1;
  max-width: 400px;
`;

const filter = css`
  padding: 8px;
`;

export default {
  container,
  group,
  sectionTitle,
  cardsContainer,
  header,
  addButton,
  searchBox,
  emptyContainer,
  filter,
};
