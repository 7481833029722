import { css, SerializedStyles } from '@emotion/react';
import style from '../../../shared/global.style';
import { JOB_BOARD_STATUS } from '../model/jobBoard';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const listContainer = css`
  flex-grow: 1;
  min-height: 0;
`;

const cardContent = css`
  &&& {
    display: flex;
    padding: 0;
  }
`;
const cardDetail = css`
  &&& {
    border-radius: 0;
    box-shadow: none;
    display: flex;
    padding: 10px;
  }
`;

const detailContainer = css`
  &&& {
    display: flex;
    min-height: 70px;
    flex: 1;
    @media (max-width: 500px) {
      flex-direction: column;
      max-height: 145px;
      overflow: auto;
    }
  }
`;

const editButton = css`
  color: var(--jbmp-primary-color);
  text-align: right;
  display: flex;
  align-items: flex-end;
  height: 100%;
  button {
    height: max-content;
  }
  border-left: 1px solid var(--jbmp-primary-color-10);
  padding-left: 15px;
  margin-left: 10px;
`;

const detailImageContainer = css`
  padding-right: 10px;
  flex-shrink: 0;
  // margin: auto 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    margin: auto;
    padding-bottom: 10px;
  }
`;

const detailImage = css`
  && {
    max-width: 150px;
    max-height: 100px;
  }
`;

const detailStatus = (status: JOB_BOARD_STATUS | undefined): SerializedStyles => {
  const color = `color: ${style.statusColor(status)}`;
  return css`
    text-align: right;
    ${style.subtitleNormal};
    > span {
      font-weight: bold;
      ${color}
    }
  `;
};

const segmentContainer = css`
  && {
    display: flex;
    flex-direction: column;
    background-color: var(--jbmp-primary-color-10);
    border: none;
    box-shadow: none;
    margin: 0;
  }
`;

const detailContentContainer = css`
  overflow: hidden;
`;

const detailDescription = css`
  ...${style.subtitleSmall};
  color: var(--jbmp-primary-color-60);
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
`;

export default {
  container,
  cardDetail,
  cardContent,
  detailContainer,
  detailDescription,
  detailImageContainer,
  detailImage,
  segmentContainer,
  listContainer,
  detailContentContainer,
  detailStatus,
  editButton,
};
