import { css } from '@emotion/react';

const editLink = css`
  text-align: right;
  cursor: pointer;
`;

export default {
  editLink,
};
