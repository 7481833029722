import axios from 'axios';
import { BrokerOrder, toBrokerOrder } from '../model/brokerOrder';
// eslint-disable-next-line import/no-cycle
import { THREE_MONTHS } from '../store/brokerOrderSlice';
import { ContentRef, Price } from '../../jobBoards/model/product';
import paths from './paths';

const api = {
  getBrokerOrders: async (periodFilter: string): Promise<BrokerOrder[]> => {
    const yearFilter = periodFilter === THREE_MONTHS ? '' : `?creationYear=${periodFilter}`;
    const response = await axios.get(paths.getBrokerOrders(yearFilter));
    return response.data.map((o: Record<string, unknown>) => toBrokerOrder(o));
  },
  updateFinalPrice: async (broId: string, finalPrice: Price): Promise<BrokerOrder> => {
    const response = await axios.put(paths.getBrokerOrderSetFinalPrice(broId), { finalPrice });
    return toBrokerOrder(response.data);
  },
  close: async (broId: string, finalPrice: Price): Promise<BrokerOrder> => {
    const response = await axios.put(paths.getBrokerOrderClose(broId), { finalPrice });
    return toBrokerOrder(response.data);
  },
  cancel: async (broId: string): Promise<BrokerOrder> => {
    const response = await axios.put(paths.getBrokerOrderCancel(broId));
    return toBrokerOrder(response.data);
  },
  updateContentsBrokerOrderOrder: async (
    broId: string,
    orderId: string,
    contents: ContentRef[]
  ): Promise<BrokerOrder> => {
    const response = await axios.put(paths.getUpdateContentsBrokerOrderOrder(broId, orderId), { contents });
    return toBrokerOrder(response.data);
  },
  getBrokerOrdersByBrands: async (periodFilter: string): Promise<BrokerOrder[]> => {
    const yearFilter = periodFilter === THREE_MONTHS ? '' : `?creationYear=${periodFilter}`;
    const response = await axios.get(paths.getBrandBrokerOrders(yearFilter));
    return response.data.map((o: Record<string, unknown>) => toBrokerOrder(o));
  },
};

export default api;
