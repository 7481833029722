/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Input, Label, Select, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { useAppDispatch } from '../../../core/coreHooks';
import { updateJobBoardFields } from '../store/jobBoardsSlice';
import style from './updateJobBoard.style';
import { PicturesListView } from './PicturesListView';
import { JOB_BOARD_STATUS, JobBoard, PictureRef } from '../model/jobBoard';

interface UpdateJobBoardProps {
  onSave?: () => void;
  jobBoard: JobBoard;
}

export const UpdateJobBoard = ({ onSave = () => null, jobBoard }: UpdateJobBoardProps): JSX.Element => {
  const [status, setStatus] = React.useState<JOB_BOARD_STATUS>(jobBoard.status);
  const [name, setName] = React.useState<string>(jobBoard.name);
  const [about, setAbout] = React.useState<string>(jobBoard.about);
  const [pic, setPic] = React.useState<PictureRef | null>(jobBoard.picture);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeAbout = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAbout(event.target.value);
  };

  const onSaveJobBoard = () => {
    dispatch(updateJobBoardFields(jobBoard.id, name, about, status, pic ? pic.id : null));
    onSave();
  };

  const statusOptions = [
    {
      key: JOB_BOARD_STATUS.ACTIVE,
      value: JOB_BOARD_STATUS.ACTIVE,
      text: t(`jobBoard.status.${JOB_BOARD_STATUS.ACTIVE}`),
    },
    {
      key: JOB_BOARD_STATUS.DRAFT,
      value: JOB_BOARD_STATUS.DRAFT,
      text: t(`jobBoard.status.${JOB_BOARD_STATUS.DRAFT}`),
    },
  ];

  return (
    <div css={style.form} data-testid='create-job-board'>
      <Flex flexWrap='wrap'>
        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Flex flexWrap='wrap'>
                <Box width={1}>
                  <Label css={style.label}>{t('jobBoard.name')}</Label>
                </Box>
                <Box width={1}>
                  <Input
                    fluid
                    value={name}
                    type='text'
                    data-testid='job-board-name-text-input'
                    onChange={event => onChangeName(event)}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('jobBoard.about')}</Label>
            </Box>
            <Box width={1}>
              <TextArea
                css={style.textArea}
                fluid
                value={about}
                data-testid='job-board-about-text-area'
                onChange={event => onChangeAbout(event)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('jobBoard.statusLabel')}</Label>
            </Box>
            <Box width={1}>
              <Select
                fluid
                value={status}
                options={statusOptions}
                onChange={(event, data) => setStatus(data.value as JOB_BOARD_STATUS)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('jobBoard.picture')}</Label>
            </Box>
            <Box width={1}>
              <PicturesListView
                pictures={pic != null ? [pic] : []}
                onDeletePicture={() => {
                  setPic(null);
                }}
                onAddPicture={picture => {
                  setPic(picture);
                }}
                editable
              />
            </Box>
          </Flex>
        </Box>
      </Flex>

      <div css={style.buttons}>
        <Button
          fluid
          primary
          data-testid='job-board-save-button'
          onClick={() => onSaveJobBoard()}
          disabled={name == null || name === '' || about == null || about === ''}>
          {t('jobBoard.save')}
        </Button>
      </div>
    </div>
  );
};
