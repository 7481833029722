import { css } from '@emotion/react';

const card = css`
  min-height: 150px !important;
  height: 200px !important;
  padding: 10px !important;
  width: 100% !important;
  overflow-y: auto;
`;

const addCard = css`
  min-height: 200px !important;
  padding: 20px !important;
  align-items: center;
  justify-content: center;
  width: 100% !important;
`;

const addCardText = css`
  padding: 10px;
`;

const vat = css`
  font-size: x-small;
`;

const invoiceContact = css`
  font-size: x-small;
`;

export default {
  card,
  addCard,
  addCardText,
  vat,
  invoiceContact,
};
