import { css } from '@emotion/react';

const form = css`
  padding: 10px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  > div {
    overflow: auto;
    margin-bottom: 10px;
  }
`;

const label = css`
  float: left;
`;

const picturesListContainer = css`
  padding: 0 10px 10px;
`;

const textArea = css`
  width: 100%;
`;

const fileUpload = css`
  text-align: left;
`;

const createButton = css`
  &&&& {
    margin-top: auto;
    margin-left: auto;
    width: min(200px, 40%);
  }
`;

const datepicker = css`
  //height: 20px;
`;

const datePickerInput = css`
  max-width: 150px;
`;

export default {
  form,
  label,
  picturesListContainer,
  textArea,
  fileUpload,
  createButton,
  datepicker,
  datePickerInput,
};
