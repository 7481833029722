/** @jsxImportSource @emotion/react */
import React from 'react';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import style from './jobBoardCard.style';
import { JobBoard } from '../model/jobBoard';

interface JobBoardCardProps {
  jobBoard: JobBoard;
}

export const JobBoardCard = ({ jobBoard }: JobBoardCardProps): JSX.Element => {
  const { status } = jobBoard;
  return (
    <Link to={`/job-boards/${jobBoard.id}`}>
      <div css={style.card(status)}>
        <div css={style.header}>
          <Content jobBoard={jobBoard} />
        </div>
      </div>
    </Link>
  );
};

interface ContentProps {
  jobBoard: JobBoard;
}

export const Content = ({ jobBoard }: ContentProps): JSX.Element => {
  const { name, picture, availableProducts } = jobBoard;
  const numberOfProducts = availableProducts.toString();
  return (
    <div css={style.contentContainer}>
      <div css={style.imageContainer}>
        {picture && <Image css={style.image} src={`data:image/jpeg;base64,${picture.data}`} />}
      </div>
      <div css={style.titleContainer}>
        <span css={style.title}>{name}</span>
        <span css={style.subTitle}>Available products: {numberOfProducts}</span>
      </div>
    </div>
  );
};
