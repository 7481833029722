/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../../core/store';
import { fetchOrder, selectIsFetchingOrders, selectSelectedOrder } from '../store/orderSlice';
import { ViewOrder } from '../components/ViewOrder';
import style from './orders.style';
import { useAppDispatch } from '../../../core/coreHooks';

export const OrderDetails = (): JSX.Element => {
  const isFetching = useSelector(selectIsFetchingOrders);
  const dispatch: AppDispatch = useAppDispatch();

  const { orderId } = useParams<{ orderId: string }>();

  useEffect(() => {
    dispatch(fetchOrder(orderId || ''));
  }, [dispatch, orderId]);

  const o = useSelector(selectSelectedOrder);

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <div css={style.container}>{o && <ViewOrder key={o.id} order={o} />}</div>
  );
};
