import { css } from '@emotion/react';

const segmentContainer = css`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--jbmp-primary-color-10);
    border: none;
    box-shadow: none;
    margin: 0;
  }
`;

const productTableContainer = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const filterAndPageSizeContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
`;

const totalCount = css`
  margin-right: auto;
`;

const filter = css`
  padding: 8px;
`;

export default {
  segmentContainer,
  productTableContainer,
  filterAndPageSizeContainer,
  totalCount,
  filter,
};
