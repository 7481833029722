/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { getRegions, REGION } from '../features/jobBoards/model/countries';
import style from './regionFilter.style';

interface RegionFilterProps {
  defaultValue: string | null;
  changeRegionFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

export const RegionFilter = ({ changeRegionFilterHandler, defaultValue }: RegionFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const emptyDropdownField = { key: '', text: '-', value: '' };

  const options = getRegions()
    .filter(r => r !== REGION.GLOBAL)
    .map(region => {
      return {
        key: region.toString(),
        text: region.toString(),
        value: region.toString(),
      };
    });

  const value = useMemo(() => (defaultValue != null ? defaultValue : ''), [defaultValue]);

  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2} width='max-content'>
          <div>{t('filters.regionFilterLabel')}</div>
        </Box>
        <Box p={2} flex={1}>
          <Dropdown
            search
            css={style.dropdown}
            placeholder={t('filters.searchPlaceholder')}
            value={value}
            options={[emptyDropdownField, ...options]}
            onChange={(event, data) => changeRegionFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
