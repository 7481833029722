/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, DropdownProps, Loader, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../core/store';
import {
  changeCountryFilter,
  changeRegionFilter,
  fetchProducts,
  onChangeLimit,
  onChangePage,
  onSort,
  onSubmitFilter,
  selectCountryFilter,
  selectFilter,
  selectFilteredAndSortedAndPaginatedProducts,
  selectIsFetchingProducts,
  selectPagination,
  selectRegionFilter,
  selectSort,
  selectTotalPagesAndCount,
} from '../store/productsSlice';
import style from './productList.style';
import { ProductTable } from '../../jobBoards/components/productTable/ProductTable';
import { ProductPageSizeSelect } from '../../jobBoards/components/productTable/ProductPageSizeSelectProps';
import { ProductFilter } from '../../jobBoards/components/productTable/ProductFilter';
import { Country } from '../../jobBoards/model/countries';
import { RegionFilter } from '../../../shared/RegionFilter';
import { CountryFilter } from '../../../shared/CountryFilter';
import { useAppDispatch } from '../../../core/coreHooks';
import { Product } from '../../jobBoards/model/product';

export const ProductList = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const sort = useSelector(selectSort);
  const pagination = useSelector(selectPagination);
  const { totalPages } = useSelector(selectTotalPagesAndCount);
  const products = useSelector(selectFilteredAndSortedAndPaginatedProducts);
  const isFetching = useSelector(selectIsFetchingProducts);
  const navigate = useNavigate();

  const handleChangePage = useCallback((page: number) => dispatch(onChangePage(page)), [dispatch]);
  const handleSort = useCallback((clickedColumn: string) => dispatch(onSort(clickedColumn)), [dispatch]);

  const handleClickRow = useCallback(
    (product: Product) => {
      navigate(`/products/${product.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <Segment css={style.segmentContainer}>
      <div css={style.productTableContainer}>
        <ProductTableHeader />
        <ProductTable
          elements={products}
          totalPages={totalPages}
          currentPage={pagination.page}
          onChangePage={handleChangePage}
          column={sort.sortColumn}
          direction={sort.sortOrder}
          handleSort={handleSort}
          onClickRow={handleClickRow}
          withJobBoardColumns
        />
      </div>
    </Segment>
  );
};

const ProductTableHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const pagination = useSelector(selectPagination);
  const filter = useSelector(selectFilter);
  const { totalCount } = useSelector(selectTotalPagesAndCount);

  const countryFilter = useSelector(selectCountryFilter);
  const regionFilter = useSelector(selectRegionFilter);

  const changeRegionFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeRegionFilter(data.value != null ? (data.value as string) : null));
    dispatch(changeCountryFilter(null));
  };

  const changeCountryFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeCountryFilter(data.value != null ? ({ name: data.text, code: data.value } as Country) : null));
  };

  const handleChangeLimit = useCallback((limit: number) => dispatch(onChangeLimit(limit)), [dispatch]);
  const handleSubmitFilter = useCallback((value: string) => dispatch(onSubmitFilter(value)), [dispatch]);
  return (
    <div css={style.filterAndPageSizeContainer}>
      <div css={style.totalCount}>
        <ProductPageSizeSelect limit={pagination.limit} onChangeLimit={handleChangeLimit} totalCount={totalCount} />
      </div>
      <div css={style.filter}>
        <RegionFilter defaultValue={regionFilter} changeRegionFilterHandler={changeRegionFilterHandler} />
      </div>
      <div css={style.filter}>
        <CountryFilter
          defaultValue={countryFilter}
          changeCountryFilterHandler={changeCountryFilterHandler}
          region={regionFilter}
        />
      </div>
      <ProductFilter filter={filter} totalCount={totalCount} onSubmitFilter={handleSubmitFilter} />
    </div>
  );
};
