import { API_URL } from '../../../constants';

const BROKER_ORDERS__URL = `${API_URL}/orders/broker`;
const BRAND_ORDERS__URL = `${API_URL}/orders/brokerbybrands`;

const paths = {
  getBrokerOrders: (yearFilter: string): string => `${BROKER_ORDERS__URL}${yearFilter}`,
  getBrokerOrderSetFinalPrice: (brokerOrderId: string): string =>
    `${BROKER_ORDERS__URL}/${brokerOrderId}/set-final-price`,
  getBrokerOrderClose: (brokerOrderId: string): string => `${BROKER_ORDERS__URL}/${brokerOrderId}/close`,
  getBrokerOrderCancel: (brokerOrderId: string): string => `${BROKER_ORDERS__URL}/${brokerOrderId}/cancel`,
  getUpdateContentsBrokerOrderOrder: (brokerOrderId: string, orderId: string): string =>
    `${BROKER_ORDERS__URL}/${brokerOrderId}/order/${orderId}/update-contents`,
  getBrandBrokerOrders: (yearFilter: string): string => `${BRAND_ORDERS__URL}${yearFilter}`,
};

export default paths;
