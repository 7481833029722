import { css } from '@emotion/react';

const header = css`
  @media (min-width: 760px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &&&& th {
    overflow: hidden;
    word-break: break-word;
    white-space: pre-wrap;
    vertical-align: top;
  }
`;

export default {
  header,
};
