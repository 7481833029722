/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon, Input, Label, Modal } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { useAppDispatch } from '../../../core/coreHooks';
import { BasketItem } from '../model/basket';
import style from './viewBasketItem.style';
import { ViewPrice } from '../../../shared/ViewPrice';
import { CompanyProfile } from '../../companyProfiles/model/companyProfile';
import { CompanyProfileSelector } from './CompanyProfileSelector';
import { Product } from '../../jobBoards/model/product';
import { ACCEPTANCE_STATUS } from '../../orders/model/order';
// eslint-disable-next-line import/no-cycle
import { AcceptanceStatus } from '../../orders/components/ViewOrder';
import { disableCheckout } from '../store/basketSlice';
import { ViewProductDetails } from '../../../shared/ViewProductDetails';
import { isProductFinalPriceValid } from '../../../shared/utils';

interface ViewBasketItemProps {
  basketItem: BasketItem;
  onBasketItemChange: (b: BasketItem) => void;
  onBasketItemDelete: (key: string) => void;
}

export const ViewBasketItem = ({
  basketItem,
  onBasketItemChange,
  onBasketItemDelete,
}: ViewBasketItemProps): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { product, total, company } = basketItem;

  const [quantity, setQuantity] = useState(basketItem.quantity);

  const changeQuantity = (qty: number) => {
    if (qty > 0) {
      setQuantity(qty);
      dispatch(disableCheckout());
      debouncedChangeQuantityHandler(qty);
    }
  };

  const debouncedChangeQuantityHandler = useDebouncedCallback((qty: number) => {
    onBasketItemChange({
      ...basketItem,
      quantity: qty,
    });
  }, 1000);

  const onDeleteBasketItemHandler = () => {
    onBasketItemDelete(basketItem.key);
  };

  const onChangeCompanyProfileHandler = (cp: CompanyProfile | null) => {
    onBasketItemChange({
      ...basketItem,
      company: cp,
    });
  };

  return (
    <Card fluid css={style.basketItem}>
      <Flex flexDirection='row' flexWrap='wrap' p={1}>
        <Box width={[1, 5 / 10, 5 / 10, 8 / 10]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={[1, 1, 1, 4 / 6]}>
              <ProductTitleAndDescription product={product} flagDescription />
              <ViewProductDetails
                product={product}
                countryCodes={basketItem ? [basketItem.country] : product.countryCodes}
              />
            </Box>

            <Box width={[1, 1, 1, 2 / 6]} p={3}>
              <Flex flexDirection='column' marginTop='15px'>
                <Box width={1}>
                  <CompanyProfileSelector
                    companyProfile={company}
                    onSelectCompanyProfile={onChangeCompanyProfileHandler}
                    disabled={product.deleted}
                    enableValidation
                  />
                </Box>
                <Box width={1}>
                  <Input
                    onChange={(event, data) => changeQuantity(Number(data.value))}
                    size='small'
                    type='number'
                    fluid
                    label={t('product.quantity')}
                    value={quantity}
                    disabled={product.deleted}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box width={[1, 5 / 10, 5 / 10, 2 / 10]} p={3} marginTop='20px'>
          <Flex flexWrap='wrap' height='100%' flexDirection='column'>
            <Box width={[1]} p={2}>
              {isProductFinalPriceValid(product) ? (
                <Flex justifyContent='flex-end' flexWrap='wrap'>
                  <Box minWidth='100px' css={style.priceToLabel as never}>
                    {t('product.columns.finalPrice')}
                  </Box>
                  <Box minWidth='100px' css={style.priceTo as never}>
                    <ViewPrice value={product.finalPrice?.value} currency={product.finalPrice?.currency} />
                  </Box>
                </Flex>
              ) : (
                <Flex justifyContent='flex-end' flexWrap='wrap'>
                  <Box minWidth='100px' css={style.priceToLabel as never}>
                    {t('product.columns.priceTo')}
                  </Box>
                  <Box minWidth='100px' css={style.priceTo as never}>
                    <ViewPrice value={product.priceTo} currency={product.priceToCurrency} />
                  </Box>
                </Flex>
              )}
            </Box>
            <Box width={[1]} p={2}>
              <Flex justifyContent='flex-end' flexWrap='wrap'>
                <Box minWidth='100px' css={style.priceToLabel as never}>
                  {t('basketItem.total')}
                </Box>
                <Box minWidth='100px' css={style.priceTo as never}>
                  {total && <ViewPrice value={total.value} currency={total.currency} />}
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box width={1} p={2} style={{ marginTop: 'auto' }}>
          <Flex justifyContent='flex-end'>
            <Box>
              <Modal
                trigger={<Button size='big' icon='trash alternate outline' basic />}
                header={t('confirmDelete')}
                content={t('basketItem.deleteMessage')}
                actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => onDeleteBasketItemHandler() }]}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

interface ProductTitleAndDescriptionProps {
  product: Product;
  acceptanceStatus?: ACCEPTANCE_STATUS;
  creationDate?: string;
  flagDescription: boolean;
}

const ProductTitleAndDescription = ({
  product,
  acceptanceStatus,
  creationDate,
  flagDescription,
}: ProductTitleAndDescriptionProps): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const goToProduct = () => navigate(`/products/${product.id}`);

  return (
    <Flex flexDirection='column'>
      <Box width={[1]} p={1} css={style.productName as never}>
        <Flex
          flexDirection='row'
          alignItems='center'
          onClick={() => {
            if (!product.deleted && goToProduct) goToProduct();
          }}>
          <Box css={(!product.deleted as never) ? (style.link as never) : ''}>
            <Icon color='blue' name='linkify' size='small' />
          </Box>
          <Box>
            {product.deleted ? (
              <del>
                {product.name} ({product.jobBoardName})
              </del>
            ) : (
              <div css={style.link}>
                {product.name} ({product.jobBoardName})
              </div>
            )}
          </Box>
          <Box pl={2}>
            {product.deleted && (
              <Label color='red'>
                <Icon name='exclamation circle' /> {t('product.deleted')}
              </Label>
            )}
          </Box>
          {acceptanceStatus && acceptanceStatus !== ACCEPTANCE_STATUS.TO_BE_ACCEPTED && (
            <AcceptanceStatus status={acceptanceStatus} />
          )}
        </Flex>
      </Box>
      {creationDate != null && (
        <Box width={[1]} p={1}>
          <div css={style.date}>
            {t('order.creationDate')}{' '}
            <strong>
              <Moment format='YYYY/MM/DD HH:mm'>{creationDate}</Moment>
            </strong>
          </div>
        </Box>
      )}
      <Box width={[1]} p={1}>
        <div hidden={!flagDescription} css={style.text}>
          {product.description}
        </div>
      </Box>
    </Flex>
  );
};
