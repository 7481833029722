import { API_URL } from '../../../constants';

const COMPANY_PROFILES__URL = `${API_URL}/company-profile`;

const paths = {
  getCompanyProfiles: (): string => COMPANY_PROFILES__URL,
  updateCompanyProfile: (id: string): string => `${COMPANY_PROFILES__URL}/${id}`,
  createCompanyProfile: (): string => COMPANY_PROFILES__URL,
  deleteCompanyProfile: (id: string): string => `${COMPANY_PROFILES__URL}/${id}`,
};

export default paths;
