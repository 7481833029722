import { Price, Product, toProduct } from '../../jobBoards/model/product';
import { CompanyProfile, toCompanyProfile } from '../../companyProfiles/model/companyProfile';

export interface Basket {
  id: string;
  items: BasketItem[];
  lastUpdate: string;
  totalPrice: Price;
}

export interface AddToBasketItem {
  key: string;
  quantity: number;
  company: CompanyProfile | null;
  country: string;
}

export interface BasketItem {
  key: string;
  quantity: number;
  product: Product;
  company: CompanyProfile | null;
  brands: string[];
  total: Price;
  country: string;
}

export const toBasket = (o: Record<string, unknown>): Basket => {
  return {
    id: o.id as string,
    items: o.items != null ? (o.items as []).map((i, index) => toBasketItem(i, index)) : [],
    lastUpdate: o.lastUpdate as string,
    totalPrice: o.totalPrice as Price,
  };
};

const toBasketItem = (o: Record<string, unknown>, index: number | undefined): BasketItem => {
  const product = toProduct(o.product as Record<string, unknown>);
  const company = o.company != null ? toCompanyProfile(o.company as Record<string, unknown>) : null;
  return {
    key: product.id + (company != null ? company.id : '') + index,
    quantity: o.quantity as number,
    product,
    company: o.company != null ? toCompanyProfile(o.company as Record<string, unknown>) : null,
    brands: o.brands as string[],
    total: o.total as Price,
    country: o.country as string,
  };
};

export const toBasketRequest = (basket: Basket): BasketUpdateRequest => {
  return {
    items: basket.items.map(
      i =>
        ({
          quantity: i.quantity,
          productId: i.product.id,
          companyProfileId: i.company?.id,
          brands: i.brands,
          country: i.country,
        } as BasketItemUpdateRequest)
    ),
  };
};

export interface BasketItemUpdateRequest {
  quantity: number;
  productId: string;
  companyProfileId: string;
  brands: string[];
}

export interface BasketUpdateRequest {
  items: BasketItemUpdateRequest[];
}
