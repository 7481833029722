import axios from 'axios';
import paths from './paths';
import { CompanyProfile, CompanyProfileRequest, toCompanyProfile } from '../model/companyProfile';

const api = {
  getCompanyProfiles: async (): Promise<CompanyProfile[]> => {
    const response = await axios.get(paths.getCompanyProfiles());
    return response.data.map(toCompanyProfile);
  },
  updateCompanyProfile: async (id: string, request: CompanyProfileRequest): Promise<CompanyProfile> => {
    const response = await axios.put(paths.updateCompanyProfile(id), request);
    return toCompanyProfile(response.data);
  },
  createCompanyProfile: async (request: CompanyProfileRequest): Promise<CompanyProfile> => {
    const response = await axios.post(paths.createCompanyProfile(), request);
    return toCompanyProfile(response.data);
  },
  deleteCompanyProfile: async (id: string): Promise<null> => {
    await axios.delete(paths.deleteCompanyProfile(id));
    return null;
  },
};

export default api;
