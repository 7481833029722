/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './productPageSizeSelectProps.style';

const limitOptions: DropdownItemProps[] = [
  { key: '0', value: '10', text: '10' },
  { key: '1', value: '25', text: '25' },
  { key: '2', value: '50', text: '50' },
  { key: '3', value: '100', text: '100' },
];

interface ProductPageSizeSelectProps {
  limit: number;
  onChangeLimit(limit: number): void;
  totalCount: number;
}

export const ProductPageSizeSelect: React.FC<ProductPageSizeSelectProps> = ({ limit, onChangeLimit, totalCount }) => {
  const { t } = useTranslation();
  const handleChangeLimit = (event: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    onChangeLimit(value as number);
  };
  return (
    <div css={style.container}>
      <div css={style.dropdownContainer}>
        <span>{t('product.recordsPerPage')}: </span>
        <Dropdown inline options={limitOptions} defaultValue={String(limit)} onChange={handleChangeLimit} />
      </div>
      <span css={style.totalCount}>
        {t('product.totalCount')}: {totalCount}.
      </span>
    </div>
  );
};
