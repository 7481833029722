/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import Moment from 'react-moment';
import style from './viewProductDetails.style';
import { Product } from '../features/jobBoards/model/product';
import { ProductOrder } from '../features/orders/model/order';
import { ViewPrice } from './ViewPrice';
import { isProductFinalPriceValid } from './utils';

interface ProductDetailsProps {
  product: Product | ProductOrder;
  countryCodes: string[];
  showPriceTo?: boolean;
  creationDate?: Date;
}

export const ViewProductDetails = ({
  product,
  countryCodes,
  showPriceTo,
  creationDate,
}: ProductDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex>
      <Box width={[1]} p={1}>
        <Flex flexWrap='wrap'>
          <Box width={[3 / 6]} minWidth='100px'>
            <p css={style.inlineLabel}>{t('product.columns.target')}</p>
            <p css={style.inlineText}>{product.target}</p>
          </Box>
          <Box width={[3 / 6]}>
            <Flex flexWrap='wrap'>
              <Box width={[1 / 4]} minWidth='100px'>
                <p css={style.inlineLabel}>{t('product.columns.region')}</p>
                <p css={style.inlineText}>{product.region}</p>
              </Box>
              <Box width={[1 / 4]} minWidth='100px'>
                <p css={style.inlineLabel}>{t('product.columns.country')}</p>
                <p css={style.inlineText}>{countryCodes.join(', ')}</p>
              </Box>
              <Box width={[1 / 4]} minWidth='100px'>
                <p css={style.inlineLabel}>{t('product.columns.productType')}</p>
                <p css={style.inlineText}>{product.productType}</p>
              </Box>
              {isProductFinalPriceValid(product, creationDate) ? (
                <>
                  <Box width={[1 / 4]} minWidth='100px'>
                    <p css={style.inlineLabel}>{t('product.columns.finalPrice')}</p>
                    <p css={style.inlineText}>
                      <ViewPrice value={product.finalPrice?.value} currency={product.finalPrice?.currency} />
                    </p>
                  </Box>
                  <Box width={[1 / 4]} minWidth='100px'>
                    <p css={style.inlineLabel}>{t('product.columns.validityEndDate')}</p>
                    <p css={style.inlineText}>
                      <Moment format='YYYY/MM/DD'>{product.validityEndDate}</Moment>
                    </p>
                  </Box>
                </>
              ) : (
                <>
                  <Box width={[1 / 4]} minWidth='100px'>
                    <p css={style.inlineLabel}>{t('product.columns.marketPrice')}</p>
                    <p css={style.inlineText}>
                      <ViewPrice value={product.marketPrice} currency={product.marketPriceCurrency} />
                    </p>
                  </Box>
                  <Box width={[1 / 4]} minWidth='100px'>
                    <p css={style.inlineLabel}>{t('product.columns.priceFrom')}</p>
                    <p css={style.inlineText}>
                      <ViewPrice value={product.priceFrom} currency={product.priceFromCurrency} />
                    </p>
                  </Box>
                  {showPriceTo && (
                    <Box minWidth='100px'>
                      <p css={style.inlineLabel}>{t('product.columns.priceTo')}</p>
                      <p css={style.inlineText}>
                        <ViewPrice value={product.priceTo} currency={product.priceToCurrency} />
                      </p>
                    </Box>
                  )}
                </>
              )}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
