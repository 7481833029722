/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Form, Grid, Input, Label, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './createCompanyProfile.style';
import { CompanyProfile } from '../model/companyProfile';

interface UpdateCompanyProps {
  companyProfile: CompanyProfile;
  onUpdate: (cp: CompanyProfile) => void;
}

export const UpdateCompanyProfile = ({ companyProfile, onUpdate = () => null }: UpdateCompanyProps): JSX.Element => {
  const [name, setName] = React.useState<string>(companyProfile.name);
  const [address, setAddress] = React.useState<string>(companyProfile.address);
  const [vatNumber, setVatNumber] = React.useState<string>(companyProfile.vatNumber);
  const [invoiceContact, setInvoiceContact] = React.useState<string>(companyProfile.invoiceContact);

  const { t } = useTranslation();

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeAddress = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAddress(event.target.value);
  };

  const onChangeVatNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVatNumber(event.target.value);
  };

  const onChangeInvoiceContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceContact(event.target.value);
  };

  const onUpdateCompanyProfile = async () => {
    onUpdate({
      id: companyProfile.id,
      name,
      address,
      vatNumber,
      invoiceContact,
    });
  };

  return (
    <div css={style.form}>
      <Grid data-testid='create-company-profile'>
        <Grid.Column floated='left'>
          <Form>
            <Form.Field>
              <Label css={style.label}>
                {t('companyProfile.name')} ({t('companyProfile.legalEntity')})
              </Label>
              <Input
                fluid
                value={name}
                type='text'
                data-testid='company-profile-name-text-input'
                onChange={event => onChangeName(event)}
              />
            </Form.Field>
            <Form.Field>
              <Label css={style.label}>{t('companyProfile.address')}</Label>
              <TextArea
                value={address}
                data-testid='company-profile-address-text-area'
                onChange={event => onChangeAddress(event)}
              />
            </Form.Field>
            <Form.Field>
              <Label css={style.label}>{t('companyProfile.vatNumber')}</Label>
              <Input
                fluid
                value={vatNumber}
                type='text'
                data-testid='company-profile-vat-number-text-input'
                onChange={event => onChangeVatNumber(event)}
              />
            </Form.Field>
            <Form.Field>
              <Label css={style.label}>{t('companyProfile.invoiceContact')}</Label>
              <Input
                fluid
                value={invoiceContact}
                type='text'
                data-testid='company-profile-invoice-contact-text-input'
                onChange={event => onChangeInvoiceContact(event)}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
      <Button
        fluid
        primary
        data-testid='company-profile-save-button'
        onClick={() => onUpdateCompanyProfile()}
        disabled={name == null || name === '' || address == null || vatNumber === '' || invoiceContact === ''}>
        {t('companyProfile.update')}
      </Button>
    </div>
  );
};
