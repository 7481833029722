import { API_URL } from '../../../constants';

const PRODUCT__URL = `${API_URL}/products`;

const paths = {
  getProducts: (): string => PRODUCT__URL,
  updateProduct: (id: string): string => `${PRODUCT__URL}/${id}`,
  uploadFile: (): string => `${API_URL}/file-upload`,
  downloadFile: (fileId: string): string => `${API_URL}/file-upload/${fileId}`,
};

export default paths;
