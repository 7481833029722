import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getJwtToken, setRedirectPagePath } from '../services/webStorageService';
import { selectUser } from '../../features/auth/store/userSlice';

interface RequireAuthProps {
  children: JSX.Element;
}

// A wrapper that redirects to the login page
// screen if you're not yet authenticated.
// Note if token is expired the interceptor redirect to login
export const RequireAuth = ({ children }: RequireAuthProps) => {
  const user = useSelector(selectUser);
  const path = useLocation().pathname;
  setRedirectPagePath(path);
  if (getJwtToken()) {
    return user ? children : <Loader size='big' />;
  }
  return <Navigate to='/login' replace />;
};
