import { css } from '@emotion/react';

const search = css`
  &&&& {
    width: 100%;
    input {
      border: none;
    }

    i {
      background-color: var(--jbmp-primary-color-80);
      color: var(--jbmp-white-color);
      opacity: 1;
    }
  }
`;

export default {
  search,
};
