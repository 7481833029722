/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

export const toastService = {
  success: (options?: ToastOptions): React.ReactNode => toast.success('Successfully done', {}),
  warning: (msg: string | ReactNode, options?: ToastOptions): React.ReactNode => toast.warning(msg, options),
  error: (msg: string | ReactNode, options?: ToastOptions): React.ReactNode => toast.error(msg, options),
  info: (msg: string | ReactNode, options?: ToastOptions): React.ReactNode => toast.info(msg, options),
  default: (msg: string | ReactNode, options?: ToastOptions): React.ReactNode => toast(msg, options),
  dark: (msg: string | ReactNode, options?: ToastOptions): React.ReactNode => toast.dark(msg, options),
};
