import _ from 'lodash';
import { getCountries, getCountriesByRegion, REGION } from './countries';
// eslint-disable-next-line import/no-cycle
import { JOB_BOARD_STATUS } from './jobBoard';
// eslint-disable-next-line import/no-cycle
import { checkValueInclude } from '../../../shared/utils';

export interface Product {
  id: string;
  name: string;
  description: string;
  productType: PRODUCT_TYPE;
  region: REGION;
  countries: string[];
  countryCodes: string[];
  target: string;
  marketPrice: number;
  marketPriceCurrency: string;
  priceFrom: number;
  priceFromCurrency: string;
  priceTo: number;
  priceToCurrency: string;
  finalPrice?: Price;
  validityEndDate?: string;
  jobBoardName: string;
  jobBoardId: string;
  jobBoardStatus: JOB_BOARD_STATUS;
  lastUpdate: string;
  contents: ContentRef[];
  deleted: boolean;
}

export interface Price {
  currency: string;
  value: number;
}

export enum PRODUCT_TYPE {
  UNIT = 'UNIT',
  PACKAGE = 'PACKAGE',
  OTHER = 'OTHER',
}

export const toProduct = (o: Record<string, unknown>): Product => {
  const countries = (o.countryCodes as string[]).map((countryCode: string) => {
    const c = getCountries().find(ci => ci.code === countryCode);
    return c != null ? c.name : 'n/a';
  });
  return {
    id: o.id as string,
    name: o.name as string,
    description: o.description as string,
    productType: o.productType as PRODUCT_TYPE,
    region: o.region as REGION,
    countries,
    countryCodes: o.countryCodes != null ? (o.countryCodes as string[]) : [],
    target: o.target as string,
    marketPrice: (o.marketPrice as Price).value,
    marketPriceCurrency: (o.marketPrice as Price).currency,
    priceFrom: (o.priceFrom as Price).value,
    priceFromCurrency: (o.priceFrom as Price).currency,
    priceTo: (o.priceTo as Price).value,
    priceToCurrency: (o.priceTo as Price).currency,
    finalPrice: o.finalPrice as Price,
    validityEndDate: o.validityEndDate as string,
    jobBoardName: o.jobBoardName as string,
    jobBoardId: o.jobBoardId as string,
    jobBoardStatus: o.jobBoardStatus as JOB_BOARD_STATUS,
    lastUpdate: o.lastUpdate as string,
    contents: o.contents as ContentRef[],
    deleted: o.deleted as boolean,
  };
};
export interface Pagination {
  limit: number;
  page: number;
}

export type SortOrder = 'ascending' | 'descending';
export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface ProductCreationRequest {
  name?: string;
  description?: string;
  productType?: PRODUCT_TYPE;
  region?: REGION;
  countryCodes?: string[];
  target?: string;
  marketPrice?: Price;
  priceFrom?: Price;
  priceTo?: Price;
  finalPrice?: Price;
  validityEndDate?: Date;
  contents?: ContentRef[];
}

export interface ProductUpdateRequest {
  name: string;
  description: string;
  productType: PRODUCT_TYPE;
  region: REGION;
  countryCodes: string[];
  target: string;
  marketPrice: Price;
  priceFrom: Price;
  priceTo: Price;
  finalPrice?: Price;
  validityEndDate?: Date;
  contents: ContentRef[];
}

export const toProductUpdateRequest = (p: Product): ProductUpdateRequest => {
  return {
    name: p.name,
    description: p.description,
    productType: p.productType,
    region: p.region,
    countryCodes: p.countryCodes,
    target: p.target,
    marketPrice: {
      value: p.marketPrice,
      currency: p.marketPriceCurrency,
    } as Price,
    priceFrom: {
      value: p.priceFrom,
      currency: p.priceFromCurrency,
    } as Price,
    priceTo: {
      value: p.priceTo,
      currency: p.priceToCurrency,
    } as Price,
    finalPrice: p.finalPrice,
    validityEndDate: p.validityEndDate ? new Date(p.validityEndDate) : undefined,
    contents: p.contents != null ? p.contents : [],
  };
};

export const getProductCountryCodes = (p: Product): string[] => {
  if (p.countryCodes.length === 0 && p.region == null) {
    return getCountriesByRegion(REGION.GLOBAL).map(c => c.code);
  }
  return _.uniq(p.countryCodes.length > 0 ? p.countryCodes : getCountriesByRegion(p.region).map(c => c.code));
};

export const productContainsCountryCodes = (p: Product, countryCodes: string[]): boolean =>
  getProductCountryCodes(p).some(c => countryCodes.includes(c));

export const filterProductsByCountryCodes = (countryCodes: string[], products: Product[]): Product[] =>
  products.filter(p => productContainsCountryCodes(p, countryCodes));

export const productContainsString = (product: Product, filter: string): boolean =>
  checkValueInclude(product.name, filter) ||
  checkValueInclude(product.description, filter) ||
  checkValueInclude(product.region, filter) ||
  checkValueInclude(product.countries.join(', '), filter) ||
  checkValueInclude(product.target, filter) ||
  checkValueInclude(product.marketPrice.toString(), filter) ||
  checkValueInclude(product.priceFrom.toString(), filter) ||
  checkValueInclude(product.priceTo.toString(), filter) ||
  (product.finalPrice != null && checkValueInclude(product.finalPrice.value.toString(), filter));

export enum FiletType {
  ORDER_INVOICE = 'ORDER_INVOICE',
  ORDER_PROPOSAL = 'ORDER_PROPOSAL',
}

export interface ContentRef {
  id: string;
  fileName: string;
  mimeType: string;
  fileType?: FiletType;
}
