/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Button, Card, Dimmer, Loader, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import {
  deleteBasketItem,
  doCheckout,
  fetchBasket,
  selectBasket,
  selectDisableCheckout,
  selectIsFetchingBasket,
  updateBasketItem,
} from '../store/basketSlice';
import { AppDispatch } from '../../../core/store';
import { ViewBasketItem } from '../components/ViewBasketItem';
import { fetchCompanyProfiles } from '../../companyProfiles/store/companyProfilesSlice';
import { BasketItem } from '../model/basket';
import { ViewPrice } from '../../../shared/ViewPrice';
import style from './basket.style';
import { useAppDispatch } from '../../../core/coreHooks';

interface ValidationError {
  orderName: string;
  errors: string[];
}

export const Basket = (): JSX.Element => {
  const isFetching = useSelector(selectIsFetchingBasket);
  const dispatch: AppDispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    dispatch(fetchBasket());
    dispatch(fetchCompanyProfiles());
  }, [dispatch]);

  const basket = useSelector(selectBasket);
  const disableCheckoutSelector = useSelector(selectDisableCheckout);

  const validateBasketItem = (i: BasketItem) => {
    return i.company != null && !i.product.deleted;
  };

  useEffect(() => {
    const writeValidationError = (i: BasketItem): ValidationError => {
      const errors = [];
      if (i.company == null) {
        errors.push(t('basket.companyNull'));
      }
      if (i.product.deleted) {
        errors.push(t('basket.productDeleted'));
      }
      return { orderName: `${i.product.name} (${i.product.jobBoardName})`, errors };
    };

    if (basket != null && basket.items.length > 0) {
      setValidationErrors(basket.items.filter(i => !validateBasketItem(i)).map(i => writeValidationError(i)));
    } else {
      setValidationErrors([]);
    }
  }, [basket, t]);

  const basketItemChangeHandler = (b: BasketItem) => {
    dispatch(updateBasketItem(b));
  };

  const basketItemDeleteHandler = (key: string) => {
    dispatch(deleteBasketItem(key));
  };

  const doCheckoutHandler = () => {
    dispatch(doCheckout());
  };

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <Flex css={style.container as never} flexWrap='wrap'>
      {basket && basket.items.length > 0 && (
        <>
          <Box width={[1, 2 / 3, 3 / 4, 4 / 5]} p={2} pr={1}>
            <div>
              {basket.items.map(i => (
                <div key={i.key}>
                  <ViewBasketItem
                    basketItem={i}
                    onBasketItemChange={basketItemChangeHandler}
                    onBasketItemDelete={basketItemDeleteHandler}
                  />
                </div>
              ))}
            </div>
          </Box>

          <Box width={[1, 1 / 3, 1 / 4, 1 / 5]} p={2} pl={1}>
            <Card style={{ width: '100%' }}>
              <Flex p={2} flexDirection='column'>
                <Box p={2}>
                  {t('basket.totalPrice')}:
                  <ViewPrice currency={basket?.totalPrice?.currency} value={basket?.totalPrice?.value} />
                </Box>
                {validationErrors.length > 0 && (
                  <Box p={2}>
                    <div>{t('basket.validationErrors')}:</div>
                    {validationErrors.map(s => (
                      <ul>
                        <li>{s.orderName}</li>
                        <ul>
                          {s.errors.map(e => (
                            <li>{e}</li>
                          ))}
                        </ul>
                      </ul>
                    ))}
                  </Box>
                )}
                <Box p={2}>
                  <Modal
                    trigger={
                      <Button disabled={validationErrors.length > 0 || disableCheckoutSelector} fluid>
                        {t('basket.checkout')}
                      </Button>
                    }
                    header={t('basket.confirmCheckout')}
                    content={t('basket.checkoutMessage')}
                    actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => doCheckoutHandler() }]}
                  />
                </Box>
              </Flex>
            </Card>
          </Box>
        </>
      )}
      {basket != null && basket.items.length === 0 && (
        <Box margin='auto'>
          <div>
            {t('basket.basketIsEmpty')},{' '}
            <u style={{ cursor: 'pointer' }} onClick={() => navigate('/products')}>
              {t('basket.makeANewPreorder')}
            </u>{' '}
            {t('basket.or')}{' '}
            <u style={{ cursor: 'pointer' }} onClick={() => navigate('/orders')}>
              {t('basket.checkYourOrders')}
            </u>
            .
          </div>
        </Box>
      )}
    </Flex>
  );
};
