/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Button, DropdownProps } from 'semantic-ui-react';
import style from './jobBoardList.style';
import { fetchJobBoards, invalidateCache, selectJobBoardsGroupedByStatus } from '../store/jobBoardsSlice';
import { JobBoardCard } from '../components/JobBoardCard';
import { JOB_BOARD_STATUS } from '../model/jobBoard';
import { AppDispatch } from '../../../core/store';
import { SearchBox } from '../../../shared/SearchBox';
import {
  changeCountryFilter,
  changeFilter,
  changeRegionFilter,
  selectJobBoardsCountryFilter,
  selectJobBoardsFilter,
  selectJobBoardsRegionFilter,
} from '../store/jobBoardsFilterSlice';
import { ModalPage } from '../../../shared/ModalPage';
import { CreateJobBoard } from '../components/CreateJobBoard';
import { checkRoles, ROLE } from '../../auth/model/user';
import { selectUser } from '../../auth/store/userSlice';
import { CountryFilter } from '../../../shared/CountryFilter';
import { RegionFilter } from '../../../shared/RegionFilter';
import { Country } from '../model/countries';
import { useAppDispatch } from '../../../core/coreHooks';

export const JobBoardList = (): JSX.Element => {
  const jobBoardsGroupedByStatus = useSelector(selectJobBoardsGroupedByStatus);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(fetchJobBoards());
  }, [dispatch, user]);

  return (
    <div css={style.container}>
      <Header />
      <div css={style.cardsContainer}>
        {Object.entries(jobBoardsGroupedByStatus).map(([key, value]) => (
          <div style={{ margin: '15px 0' }} key={key}>
            <h5 css={style.sectionTitle(key as JOB_BOARD_STATUS)}>
              {t(`jobBoard.status.${key}`)}
              <span style={{ fontWeight: 400 }}>Boards</span>
            </h5>
            <div css={style.group}>
              {value.map(jb => (
                <JobBoardCard key={jb.id} jobBoard={jb} />
              ))}
            </div>
          </div>
        ))}
      </div>
      {Object.keys(jobBoardsGroupedByStatus).length === 0 && (
        <div css={style.emptyContainer}>
          <div>{t(`jobBoard.noBoardsFound`)}</div>
        </div>
      )}
    </div>
  );
};

const Header = (): JSX.Element => {
  const [isCreateJobBoardModalOpen, setCreateJobBoardModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSave = async () => {
    await dispatch(invalidateCache());
    await dispatch(fetchJobBoards());
    setCreateJobBoardModalOpen(false);
  };

  const user = useSelector(selectUser);
  const isBroker = useCallback(() => checkRoles(user, [ROLE.BROKER]), [user]);

  const countryFilter = useSelector(selectJobBoardsCountryFilter);
  const regionFilter = useSelector(selectJobBoardsRegionFilter);

  const changeRegionFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeRegionFilter(data.value != null ? (data.value as string) : null));
    dispatch(changeCountryFilter(null));
  };

  const changeCountryFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeCountryFilter(data.value != null ? ({ name: data.text, code: data.value } as Country) : null));
  };

  return (
    <div css={style.header}>
      {isCreateJobBoardModalOpen && (
        <ModalPage title={t('jobBoard.createJobBoard')} onClose={() => setCreateJobBoardModalOpen(false)}>
          <CreateJobBoard onSave={() => onSave()} />
        </ModalPage>
      )}
      {isBroker() && (
        <Button
          icon='plus'
          primary
          css={style.addButton}
          data-testid='create-job-board-button'
          onClick={() => setCreateJobBoardModalOpen(true)}
        />
      )}
      <div css={style.filter}>
        <RegionFilter defaultValue={regionFilter} changeRegionFilterHandler={changeRegionFilterHandler} />
      </div>
      <div css={style.filter}>
        <CountryFilter
          defaultValue={countryFilter}
          changeCountryFilterHandler={changeCountryFilterHandler}
          region={regionFilter}
        />
      </div>
      <div css={style.searchBox}>
        <SearchAssessmentBox />
      </div>
    </div>
  );
};

const SearchAssessmentBox = (): JSX.Element => {
  const filter = useSelector(selectJobBoardsFilter, () => true);
  const dispatch: AppDispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(changeFilter(value));
  }, 500);

  return <SearchBox defaultValue={filter} onChange={value => debounced(value)} />;
};
