import { css, keyframes } from '@emotion/react';

const fadeInOpacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const popupStyle = css`
  position: absolute;
  background: var(--jbmp-white-color);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  top: 45px;
  right: 0;
  width: 270px;
  opacity: 1;
  animation-name: ${fadeInOpacity};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.125s;
  display: flex;
  flex-direction: column;
  z-index: 10;
  overflow: hidden;
`;

const userContainer = css`
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid;
  margin: 5px;
  width: calc(100% - 10px);
  overflow: hidden;

`;

const iconStyle = css`&& {
  margin-right: 10px;
  width: 28px
}`;

const userDataContainer = css`
  && {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    
  }`;

const buttonStyle = css`&& {
  margin: auto 15px 15px;
}`;

const listItemStyle = css`&& {
  margin-left: 15px;

  a {
    color: var(--jbmp-primary-color);
    display: flex;
    align-items: center;
  }

  &:hover {
    opacity: 0.5
  }
}`;

const userLabel = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const regionsAndCountriesDropdown = css`
  &&&{
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    
    &&& .dropdown.icon{
      padding: 10px;
    }
    
    &&& a {
      width: 100%;
      align-items: flex-start;
      display: flex;
      i.delete.icon{
        margin-left: auto;
      }
    }
  }
  
  &&&&& .visible.menu.transition {    
    position: relative;
    margin-top: 5px;
    margin-left: -6px;
    width:240px;
    overflow: hidden;
    max-height: 100%;
   
  }
`;

export default {
  popupStyle,
  userContainer,
  iconStyle,
  buttonStyle,
  listItemStyle,
  userDataContainer,
  userLabel,
  regionsAndCountriesDropdown,
};
