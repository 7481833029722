/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './contactAdmin.style';

export const ContactAdmin = () => {
  return (
    <div css={style.container}>
      <div>
        Your account is not authorized to login Job Board Marketplace
        <br />
        <br />
        Contact the administrator
      </div>
    </div>
  );
};
