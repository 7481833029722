/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Button, Card, Icon, Image, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import style from './forgotPassword.style';
import keringLogo from '../../../assets/keringLogo_scritta.png';
import api from '../utils/api';
import globalStyle from '../../../shared/global.style';

export const ForgotPassword = (): JSX.Element => {
  return (
    <Card fluid css={style.card}>
      <Card.Content css={style.cardContent}>
        <div css={style.contentContainer}>
          <Link style={{ paddingBottom: '5px' }} to='/login'>
            <Icon name='chevron left' /> <span>Return to login</span>
          </Link>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              alignItems: 'center',
            }}>
            <Image src={keringLogo} wrapped />
            <ForgotPasswordForm />
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export const ForgotPasswordForm = (): JSX.Element => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [isRequestSent, setIsRequestSent] = React.useState(false);
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const reset = () => {
    if (!(email === '' || !validator.isEmail(email)))
      api.requestResetPassword(email).then(() => setIsRequestSent(true));
  };

  return (
    <Card css={style.forgotPasswordCard}>
      <Card.Content>
        {!isRequestSent && <h4 css={style.forgotPasswordCardTitle}>{t('login.resetPassword')}</h4>}
        <div css={style.forgotPasswordCardContent}>
          {!isRequestSent ? (
            <div css={style.formContainer}>
              <Input
                value={email}
                error={!(email !== '' && validator.isEmail(email)) && email !== ''}
                type='text'
                onChange={event => onChangeEmail(event)}
                icon='mail'
                iconPosition='left'
                placeholder='Email'
                css={style.emailInput}
                onKeyPress={(e: KeyboardEvent) => {
                  if (e.code === 'Enter') {
                    reset();
                  }
                }}
              />
              <Button
                style={{ 'margin-top': '15px' }}
                onClick={reset}
                disabled={email === '' || !validator.isEmail(email)}>
                {t('login.reset')}
              </Button>
            </div>
          ) : (
            <div>
              <div css={globalStyle.subtitleNormal}>{t('login.resetPasswordRequestSent')}</div>
              <Button
                style={{ 'margin-top': '15px' }}
                onClick={reset}
                disabled={email === '' || !validator.isEmail(email)}>
                {t('login.resendRequest')}
              </Button>
            </div>
          )}
        </div>
      </Card.Content>
    </Card>
  );
};
