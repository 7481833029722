/** @jsxImportSource @emotion/react */
import React from 'react';
import { Page } from './Page';
import { RequireAuth } from '../core/components/RequireAuth';

interface PageProps {
  title: string;
  backButton?: boolean;
  children: JSX.Element;
}

export const PageUnderSecurity = (props: PageProps) => {
  const { children, title, backButton } = props;
  return (
    <RequireAuth>
      <Page title={title} backButton={backButton}>
        {children}
      </Page>
    </RequireAuth>
  );
};
