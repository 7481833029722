/** @jsxImportSource @emotion/react */
import { Table } from 'semantic-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './productTableHeader.style';

interface ProductTableHeaderProps {
  column?: string;
  direction?: 'ascending' | 'descending';
  handleSort(column: string): void;
  withJobBoardColumns?: boolean;
  withCloneColumn?: boolean;
}
export const ProductTableHeader: React.FC<ProductTableHeaderProps> = ({
  column,
  direction,
  handleSort,
  withJobBoardColumns,
  withCloneColumn,
}) => {
  const { t } = useTranslation();
  return (
    <Table.Header css={style.header}>
      <Table.Row>
        <Table.HeaderCell sorted={column === 'name' ? direction : undefined} onClick={() => handleSort('name')}>
          {t('product.columns.name')}
        </Table.HeaderCell>
        {withJobBoardColumns && (
          <Table.HeaderCell
            sorted={column === 'jobBoardName' ? direction : undefined}
            onClick={() => handleSort('jobBoardName')}>
            {t('product.columns.jobBoardName')}
          </Table.HeaderCell>
        )}
        <Table.HeaderCell
          width={3}
          sorted={column === 'description' ? direction : undefined}
          onClick={() => handleSort('description')}>
          {t('product.columns.description')}
        </Table.HeaderCell>
        <Table.HeaderCell sorted={column === 'region' ? direction : undefined} onClick={() => handleSort('region')}>
          {t('product.columns.region')}
        </Table.HeaderCell>
        <Table.HeaderCell sorted={column === 'country' ? direction : undefined} onClick={() => handleSort('country')}>
          {t('product.columns.country')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          sorted={column === 'target' ? direction : undefined}
          onClick={() => handleSort('target')}>
          {t('product.columns.target')}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'productType' ? direction : undefined}
          onClick={() => handleSort('productType')}>
          {t('product.columns.productType')}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'marketPrice' ? direction : undefined}
          onClick={() => handleSort('marketPrice')}>
          {t('product.columns.marketPrice')}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'priceFrom' ? direction : undefined}
          onClick={() => handleSort('priceFrom')}>
          {t('product.columns.priceFrom')}
        </Table.HeaderCell>
        <Table.HeaderCell sorted={column === 'priceTo' ? direction : undefined} onClick={() => handleSort('priceTo')}>
          {t('product.columns.priceTo')}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'finalPrice' ? direction : undefined}
          onClick={() => handleSort('finalPrice')}>
          {t('product.columns.finalPrice')}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={column === 'validityEndDate' ? direction : undefined}
          onClick={() => handleSort('validityEndDate')}>
          {t('product.columns.validityEndDate')}
        </Table.HeaderCell>
        {withCloneColumn && <Table.HeaderCell />}
      </Table.Row>
    </Table.Header>
  );
};
