/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Dimmer, Portal, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../core/coreHooks';
import style from './companyProfileSelector.style';
import { SelectCompanyProfile } from '../../companyProfiles/components/SelectCompanyProfile';
import { Company, CompanyProfile } from '../../companyProfiles/model/companyProfile';
import { CompanyProfileCard } from '../../companyProfiles/components/CompanyProfileCard';
import { disableCheckout } from '../store/basketSlice';

interface CompanyProfileSelectorProps {
  companyProfile?: CompanyProfile | null;
  onSelectCompanyProfile: (cp: CompanyProfile | null) => void;
  disabled?: boolean;
  enableValidation?: boolean;
}

export const CompanyProfileSelector = ({
  onSelectCompanyProfile,
  companyProfile,
  disabled,
  enableValidation,
}: CompanyProfileSelectorProps): JSX.Element => {
  const { t } = useTranslation();

  const [isCompanyProfileModalOpen, setCompanyProfileModalOpen] = useState(false);
  const [selectedCompanyProfile, setSelectedCompanyProfile] = useState<CompanyProfile | Company | null>(
    companyProfile === undefined ? null : companyProfile
  );

  useEffect(() => {
    setSelectedCompanyProfile(companyProfile === undefined ? null : companyProfile);
  }, [companyProfile]);

  const dispatch = useAppDispatch();

  const handleClose = (c: CompanyProfile | null) => {
    setCompanyProfileModalOpen(false);
    if (c != null) {
      dispatch(disableCheckout());
      setSelectedCompanyProfile(c);
      onSelectCompanyProfile(c);
    }
  };
  const handleOpen = () => {
    if (disabled !== true) {
      setCompanyProfileModalOpen(true);
    }
  };
  const navigate = useNavigate();

  const getCompanyProfile = () => {
    return selectedCompanyProfile != null ? selectedCompanyProfile : null;
  };

  return (
    <div style={disabled ? { opacity: '0.4' } : {}}>
      <CompanyProfileCard
        onClick={handleOpen}
        companyProfile={getCompanyProfile()}
        enableValidation={enableValidation}
      />
      <Dimmer active={isCompanyProfileModalOpen} page>
        <Portal onClose={() => handleClose(null)} open={isCompanyProfileModalOpen}>
          <Segment
            style={{
              position: 'fixed',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              zIndex: 1001,
              maxHeight: '80vh',
              overflowY: 'scroll',
            }}>
            <SelectCompanyProfile onSelected={c => handleClose(c)} />
            <div css={style.editLink} onClick={() => navigate('/company-profiles')}>
              {t('companyProfile.editLink')}
            </div>
          </Segment>
        </Portal>
      </Dimmer>
    </div>
  );
};
