import { css } from '@emotion/react';

const filter = css`
  display: flex;
  height: max-content;
  width: 200px;
  min-width: 200px;
  max-width: 100%;
  align-items: center;
  border-bottom: solid 1px var(--jbmp-primary-color-60);
`;

const dropdown = css`
  &&&& {
    display: flex;
    align-items: center;
  }

  &&& .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 66px;
    line-height: 20px;
    height: 20px;
  }
  &&& .dropdown.icon {
    margin-right: 0;
    margin-left: auto;
  }
`;

export default {
  filter,
  dropdown,
};
