/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { User } from '../model/user';
import api from '../utils/api';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
// eslint-disable-next-line import/no-cycle
import { getCountriesByRegion, getRegionByCountry, getRegions, REGION } from '../../jobBoards/model/countries';

interface UserSliceState {
  data: User | null;
  isFetching: boolean;
  error: string;
}

const initialState = {
  data: null,
  isFetching: false,
  error: '',
} as UserSliceState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startFetch: (state: UserSliceState) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<UserSliceState>, action: PayloadAction<User>) => {
      return { isFetching: false, data: { ...action.payload }, error: '' };
    },
    fail: (state: UserSliceState, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    updatePolicy: (state: Draft<UserSliceState>, action: PayloadAction<boolean>) => {
      const user = state.data;
      const policiesAccepted = action.payload;
      if (user) {
        user.policiesAccepted = policiesAccepted;
      }
    },
    updateRegionsAndCountries: (
      state: UserSliceState,
      action: PayloadAction<{ regions: REGION[]; countryCodes: string[] }>
    ) => {
      const user = state.data;
      if (user) {
        user.regions = action.payload.regions;
        user.countryCodes = action.payload.countryCodes;
      }
    },
  },
});

export const { startFetch, finishFetch, fail, updatePolicy, updateRegionsAndCountries } = userSlice.actions;

export const fetchUser = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const user = await api.getUser();
    dispatch(finishFetch(user));
  } catch (error) {
    dispatch(fail(JSON.stringify(error)));
  }
};

export const acceptPolicy = (): AppThunk => async dispatch => {
  try {
    const user = await api.putUserPolicy();
    dispatch(updatePolicy(user.policiesAccepted));
  } catch (error) {
    dispatch(fail(JSON.stringify(error)));
  }
};

export const updateUserRegionsAndCountries =
  (id: string, regions: string[], countryCodes: string[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateRegionsAndCountries({ regions: regions as REGION[], countryCodes }));
      await api.putUserRegionsAndCountries(id, regions, countryCodes);
    } catch (error) {
      dispatch(fail(JSON.stringify(error)));
    }
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = (state: RootState) => state.user.data as User;

export const selectUserCountryCodes = (state: RootState) => {
  if (state.user.data == null) {
    return [];
  }
  if (state.user.data.countryCodes.length === 0 && state.user.data.regions.length === 0) {
    return getCountriesByRegion(REGION.GLOBAL).map(c => c.code);
  }

  const regionalCountries = state.user.data.regions.flatMap(r => getCountriesByRegion(r)).map(c => c.code);
  return [...state.user.data.countryCodes, ...regionalCountries];
};

export const selectUserRegions = (state: RootState) => {
  if (state.user.data == null) {
    return [];
  }
  if (state.user.data.regions.length === 0 && state.user.data.countryCodes.length === 0) {
    return getRegions();
  }
  const baseRegions = state.user.data.regions;
  const regionsFromCountries = state.user.data.countryCodes.flatMap(c => getRegionByCountry(c));
  return _.uniq([...baseRegions, ...regionsFromCountries]);
};

export const selectUserRoles = (state: RootState) => {
  if (state.user.data == null) {
    return [];
  }
  return state.user.data.roles;
};

export default userSlice.reducer;
