/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { Box, Flex } from 'rebass';
import 'react-datepicker/dist/react-datepicker.css';
import style from './datepickerFilter.style';
import { THREE_MONTHS } from '../features/brokerOrders/store/brokerOrderSlice';

interface DatepickerFilterProps {
  defaultValue?: Date | null;
  period: string;
  label: string;
  onChangeValue(value: Date | null | undefined): void;
}

export const DatepickerFilter = ({
  defaultValue,
  onChangeValue,
  period,
  label,
}: DatepickerFilterProps): JSX.Element => {
  const [value, setValue] = React.useState(defaultValue);

  const getMindateFromPeriod = (p: string) => {
    switch (period) {
      case THREE_MONTHS:
        return monthsBackFrom(new Date(), 3);
        break;
      default:
        return new Date(Number(p), 0, 1);
    }
  };

  function monthsBackFrom(date: Date, n: number): Date {
    // Create a new date so the original is not modified
    const result = new Date(date);

    // Careful, setMonth modifies the date object in place
    result.setMonth(result.getMonth() - n); // Set to n months back from current
    return result;
  }

  const getMaxdateFromPeriod = (p: string) => {
    switch (period) {
      case THREE_MONTHS:
        return new Date();
        break;
      default:
        return new Date(Number(p), 11, 31);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Flex css={style.filter as never} justifyContent='center'>
      <Box p={2} width='max-content'>
        <div>{label}</div>
      </Box>
      <Box p={2} flex={1}>
        <div css={style.datepickerContainer}>
          <DatePicker
            css={style.datepicker}
            minDate={getMindateFromPeriod(period)}
            maxDate={getMaxdateFromPeriod(period)}
            isClearable
            selected={value}
            dateFormat='yyyy-MM-dd'
            onChange={(v?: Date | null) => {
              setValue(v);
              onChangeValue(v);
            }}
            placeholderText='yyyy-mm-dd'
            customInput={<Input css={style.filterInput} />}
          />
        </div>
      </Box>
    </Flex>
  );
};
