/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Box, Flex } from 'rebass';
import { Button, Modal } from 'semantic-ui-react';
import React, { useMemo } from 'react';
import { useAppDispatch } from '../../../core/coreHooks';
import style from './brokerOrderPrice.style';
import { cancelBrokerOrder, closeBrokerOrder, updateFinalPrice } from '../store/brokerOrderSlice';
import { BrokerOrder } from '../model/brokerOrder';
import { Price } from '../../jobBoards/model/product';
import { ORDER_STATUS } from '../../orders/model/order';
import { PriceInput } from '../../../shared/PriceInput';
import { ViewPrice } from '../../../shared/ViewPrice';

export const BrokerOrderPrice = ({ brokerOrder: bro }: { brokerOrder: BrokerOrder }): JSX.Element => {
  return bro.status === ORDER_STATUS.PRE_ORDER || bro.status === ORDER_STATUS.FINAL_CONFIRMATION ? (
    <EditBrokerOrderPrice brokerOrder={bro} />
  ) : (
    <ViewBrokerOrderPrice brokerOrder={bro} />
  );
};

const EditBrokerOrderPrice = ({ brokerOrder: bro }: { brokerOrder: BrokerOrder }): JSX.Element => {
  const { t } = useTranslation();

  const [finalPrice, setFinalPrice] = React.useState<Price | null>(bro.finalPrice);
  const [totalPrice, setTotalPrice] = React.useState<Price>(bro.totalPrice);

  const changeFinalPrice = (value: number, currency: string) => {
    setFinalPrice(value ? { value, currency } : null);
    const totalValue = value ? value * bro.totalQuantity : 0;
    if (totalValue === 0 && bro.status === ORDER_STATUS.PRE_ORDER) {
      setTotalPrice(bro.totalPrice);
    } else {
      setTotalPrice({ value: totalValue, currency });
    }
  };

  const priceInputPlaceholder = useMemo(
    () => (bro.status === ORDER_STATUS.PRE_ORDER ? t('brokerOrder.setFinalPricePlaceholder') : undefined),
    [bro.status, t]
  );

  return (
    <Flex flexWrap='wrap' height='100%' flexDirection='column'>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 3} css={style.priceToLabel as never}>
            {t('brokerOrder.finalUnitPrice')}
          </Box>
          <Box width={2 / 3}>
            <PriceInput
              value={finalPrice?.value}
              currency={finalPrice?.currency}
              onChange={changeFinalPrice}
              placeholder={priceInputPlaceholder}
            />
          </Box>
        </Flex>
      </Box>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 3} css={style.priceToLabel as never}>
            {t('brokerOrder.totalQuantity')}
          </Box>
          <Box width={2 / 3} css={style.priceTo as never}>
            {bro.totalQuantity}
          </Box>
        </Flex>
      </Box>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 3} css={style.priceToLabel as never}>
            {t('brokerOrder.totalPrice')}
          </Box>
          <Box width={2 / 3} css={style.priceTo as never}>
            <ViewPrice currency={totalPrice.currency} value={totalPrice.value} />
          </Box>
        </Flex>
      </Box>
      <Box css={style.buttonsContainer as never} width={1} mb={1}>
        <Flex justifyContent='flex-end'>
          <Box width={1} p={1}>
            {bro.status === ORDER_STATUS.PRE_ORDER && (
              <SetFinalPriceButton
                brokerOrderId={bro.id}
                unitPrice={finalPrice}
                quantity={bro.totalQuantity}
                totalPrice={totalPrice}
              />
            )}
            {bro.status === ORDER_STATUS.FINAL_CONFIRMATION && (
              <CloseOrderButton
                brokerOrderId={bro.id}
                unitPrice={finalPrice}
                quantity={bro.totalQuantity}
                totalPrice={totalPrice}
              />
            )}
          </Box>
          <Box width={1} p={1}>
            <CancelBrokerOrderButton brokerOrderId={bro.id} />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

const ViewBrokerOrderPrice = ({ brokerOrder: bro }: { brokerOrder: BrokerOrder }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex flexWrap='wrap' height='100%' flexDirection='column'>
      <ViewPriceRecap unitPrice={bro.finalPrice} quantity={bro.totalQuantity} totalPrice={bro.totalPrice} />
      {bro.closedDate != null && (
        <Box width={1} p={2} css={style.closedDate as never}>
          {t('brokerOrder.closedDate')}{' '}
          <strong>
            <Moment format='YYYY/MM/DD HH:mm'>{bro.closedDate}</Moment>
          </strong>
        </Box>
      )}
      {bro.status !== ORDER_STATUS.CANCELED && bro.status !== ORDER_STATUS.CLOSED && (
        <Box css={style.buttonsContainer as never} width={1} mb={1}>
          <Flex justifyContent='flex-end'>
            <Box width={1 / 2} p={1}>
              <CancelBrokerOrderButton brokerOrderId={bro.id} />
            </Box>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

interface ViewPriceRecapProps {
  unitPrice: Price | null;
  quantity: number;
  totalPrice: Price;
}

const ViewPriceRecap = ({ unitPrice, quantity, totalPrice }: ViewPriceRecapProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {unitPrice && (
        <Box width={1} p={2}>
          <Flex justifyContent='flex-end' alignItems='center'>
            <Box width={1 / 2} css={style.priceToLabel as never}>
              {t('brokerOrder.finalUnitPrice')}
            </Box>
            <Box width={1 / 2} css={style.priceTo as never}>
              <ViewPrice currency={unitPrice.currency} value={unitPrice.value} />
            </Box>
          </Flex>
        </Box>
      )}
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 2} css={style.priceToLabel as never}>
            {t('brokerOrder.totalQuantity')}
          </Box>
          <Box width={1 / 2} css={style.priceTo as never}>
            {quantity}
          </Box>
        </Flex>
      </Box>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 2} css={style.priceToLabel as never}>
            {t('brokerOrder.totalPrice')}
          </Box>
          <Box width={1 / 2} css={style.priceTo as never}>
            <ViewPrice currency={totalPrice.currency} value={totalPrice.value} />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

interface SetFinalPriceProps {
  brokerOrderId: string;
  unitPrice: Price | null;
  quantity: number;
  totalPrice: Price;
}

const SetFinalPriceButton = ({ brokerOrderId, unitPrice, quantity, totalPrice }: SetFinalPriceProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const updateFinalPriceHandler = () => {
    if (unitPrice != null) {
      dispatch(updateFinalPrice(brokerOrderId, unitPrice));
    }
  };
  return (
    <Modal
      size='tiny'
      trigger={<Button fluid disabled={unitPrice == null} content={t('brokerOrder.setFinalPrice')} />}
      header={t('brokerOrder.confirmFinalPrice')}
      content={
        <Flex flexDirection='column'>
          <Box width={1} p={2} maxWidth='300px' margin='auto'>
            <ViewPriceRecap unitPrice={unitPrice} quantity={quantity} totalPrice={totalPrice} />
          </Box>
          <Box padding='1.5rem'>{t('brokerOrder.finalPriceMessage')}</Box>
        </Flex>
      }
      actions={['Cancel', { key: 'done', content: 'Ok', onClick: updateFinalPriceHandler }]}
    />
  );
};

const CloseOrderButton = ({ brokerOrderId, unitPrice, quantity, totalPrice }: SetFinalPriceProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    if (unitPrice != null) {
      dispatch(closeBrokerOrder(brokerOrderId, unitPrice));
    }
  };
  return (
    <Modal
      size='tiny'
      trigger={<Button fluid disabled={unitPrice == null} content={t('brokerOrder.close')} />}
      header={t('brokerOrder.close')}
      content={
        <Flex flexDirection='column'>
          <Box width={1} p={2} maxWidth='300px' margin='auto'>
            <ViewPriceRecap unitPrice={unitPrice} quantity={quantity} totalPrice={totalPrice} />
          </Box>
          <Box padding='1.5rem'>{t('brokerOrder.closeMessage')}</Box>
        </Flex>
      }
      actions={['Cancel', { key: 'done', content: 'Ok', onClick: closeHandler }]}
    />
  );
};

const CancelBrokerOrderButton = ({ brokerOrderId }: { brokerOrderId: string }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cancelHandler = () => {
    dispatch(cancelBrokerOrder(brokerOrderId));
  };
  return (
    <Modal
      size='tiny'
      trigger={<Button fluid content={t('brokerOrder.delete')} />}
      header={t('brokerOrder.confirmDelete')}
      content={t('brokerOrder.deleteMessage')}
      actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => cancelHandler() }]}
    />
  );
};
