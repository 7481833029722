export enum REGION {
  GLOBAL = 'GLOBAL',
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMERICA = 'AMERICA',
}

export const COUNTRIES: Country[] = [
  { name: 'Belgium', code: 'BE' },
  { name: 'China', code: 'CN' },
  { name: 'South Korea', code: 'KR' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Panama', code: 'PA' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'United Kingdom', code: 'UK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Finland', code: 'FI' },
  { name: 'Canada', code: 'CA' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Brazil', code: 'BR' },
  { name: 'India', code: 'IN' },
  { name: 'Qatar', code: 'QA' },
  { name: 'U.A.E.', code: 'AE' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Norway', code: 'NO' },
  { name: 'Russia', code: 'RU' },
  { name: 'Romania', code: 'RO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'United States', code: 'US' },
  { name: 'Austria', code: 'AT' },
  { name: 'Australia', code: 'AU' },
  { name: 'Guam', code: 'GU' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Greece', code: 'GR' },
  { name: 'Poland', code: 'PL' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'France', code: 'FR' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Chile', code: 'CL' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Denmark', code: 'DK' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Japan', code: 'JP' },
  { name: 'Italy', code: 'IT' },
  { name: 'Germany', code: 'DE' },
  { name: 'Spain', code: 'ES' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Macao', code: 'MO' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'The Bahamas', code: 'BS' },
];

export const getCountries = (): Country[] => {
  return COUNTRIES.sort((c1, c2) => c1.name.localeCompare(c2.name));
};
export const getCountriesByRegion = (region: string): Country[] => {
  return REGIONS.filter(r => r.name === region)
    .flatMap(r => r.countries)
    .sort((c1, c2) => c1.name.localeCompare(c2.name));
};

export const getCountryCodesByRegion = (region: string): string[] => {
  return REGIONS.filter(r => r.name === region)
    .flatMap(r => r.countries)
    .map(c => c.code);
};

export const getRegionByCountry = (country: string): REGION => {
  let region: REGION = REGION.GLOBAL;
  REGIONS.map(c =>
    // eslint-disable-next-line array-callback-return
    c.countries.some(d => {
      if (d.code === country) {
        region = c.name as REGION;
      }
    })
  );
  return region;
};

export const REGIONS: RegionCountries[] = [
  {
    name: 'GLOBAL',
    countries: COUNTRIES,
  },
  {
    name: 'EMEA',
    countries: [
      { name: 'Belgium', code: 'BE' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'United Kingdom', code: 'UK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Finland', code: 'FI' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Qatar', code: 'QA' },
      { name: 'U.A.E.', code: 'AE' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Norway', code: 'NO' },
      { name: 'Romania', code: 'RO' },
      { name: 'Austria', code: 'AT' },
      { name: 'Greece', code: 'GR' },
      { name: 'Poland', code: 'PL' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'France', code: 'FR' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Denmark', code: 'DK' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Italy', code: 'IT' },
      { name: 'Germany', code: 'DE' },
      { name: 'Spain', code: 'ES' },
      { name: 'Czech Republic', code: 'CZ' },
    ],
  },
  {
    name: 'AMERICA',
    countries: [
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Panama', code: 'PA' },
      { name: 'Canada', code: 'CA' },
      { name: 'Brazil', code: 'BR' },
      { name: 'Argentina', code: 'AR' },
      { name: 'United States', code: 'US' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Chile', code: 'CL' },
      { name: 'The Bahamas', code: 'BS' },
    ],
  },
  {
    name: 'APAC',
    countries: [
      { name: 'China', code: 'CN' },
      { name: 'South Korea', code: 'KR' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'India', code: 'IN' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Russia', code: 'RU' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Australia', code: 'AU' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Vietnam', code: 'VN' },
      { name: 'Japan', code: 'JP' },
      { name: 'Taiwan', code: 'TW' },
      { name: 'Macao', code: 'MO' },
      { name: 'Guam', code: 'GU' },
    ],
  },
];

export interface Country {
  name: string;
  code: string;
}

export interface RegionCountries {
  name: string;
  countries: Country[];
}

export const getCountriesMap = (): Map<string, string> => {
  const map = new Map();
  COUNTRIES.forEach(c => map.set(c.code, c.name));
  return map;
};

export const getRegions = (): REGION[] => {
  return REGIONS.map(c => c.name as REGION);
};
