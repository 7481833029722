/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dimmer, Dropdown, Icon, Input, Label, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { useSelector } from 'react-redux';
import style from './addToBasket.style';
import {
  fetchCompanyProfiles,
  selectDefaultCompanyProfile,
  selectIsFetchingCompanyProfiles,
} from '../../companyProfiles/store/companyProfilesSlice';
import { CompanyProfile } from '../../companyProfiles/model/companyProfile';
import { AppDispatch } from '../../../core/store';
import { CompanyProfileSelector } from './CompanyProfileSelector';
import { getProductCountryCodes, Product, PRODUCT_TYPE } from '../../jobBoards/model/product';
import { getCountriesMap } from '../../jobBoards/model/countries';
import { selectUserCountryCodes } from '../../auth/store/userSlice';
import { AddToBasketItem } from '../model/basket';
import { useAppDispatch } from '../../../core/coreHooks';

interface AddToBasketProps {
  onAddToBasket: (items: AddToBasketItem[]) => void;
  product: Product;
}

export const AddToBasket = ({ onAddToBasket = () => null, product }: AddToBasketProps): JSX.Element => {
  const { t } = useTranslation();
  const defaultCompanyProfile = useSelector(selectDefaultCompanyProfile);

  const userCountryCodes = useSelector(selectUserCountryCodes);

  const [totalQuantity, setTotalQuantity] = React.useState<number>(1);
  const [nbOfEntities, setNbOfEntities] = React.useState<number>(1);

  const [addToBasketItems, setAddToBasketItems] = useState<AddToBasketItem[]>([]);

  useEffect(() => {
    const generatedAddToBasketItems: AddToBasketItem[] = Array.from(Array(nbOfEntities).keys()).map(i => ({
      key: `addToBasketItem-${i}`,
      quantity: Number((totalQuantity / nbOfEntities).toFixed(2)),
      company:
        addToBasketItems[i] && addToBasketItems[i].company != null
          ? addToBasketItems[i].company
          : defaultCompanyProfile,
      country: addToBasketItems[i] ? addToBasketItems[i].country : '',
    }));
    setAddToBasketItems(generatedAddToBasketItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbOfEntities, totalQuantity, defaultCompanyProfile]);

  const dispatch: AppDispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanyProfiles());
  }, [dispatch]);

  const updateCountry = useCallback(
    (index: number, country: string) =>
      setAddToBasketItems(prevState => {
        const newState = [...prevState];
        newState[index].country = country;
        return newState;
      }),
    []
  );

  const updateQuantity = useCallback((index: number, quantity: number) => {
    setAddToBasketItems(prevState => {
      const newState = [...prevState];
      newState[index].quantity = quantity;
      return newState;
    });
  }, []);

  const updateCompanyProfile = useCallback(
    (index: number, companyProfile: CompanyProfile | null) =>
      setAddToBasketItems(prevState => {
        const newState = [...prevState];
        newState[index].company = companyProfile;
        return newState;
      }),
    []
  );

  const onAddToBasketItems = useCallback(() => {
    onAddToBasket(addToBasketItems);
  }, [addToBasketItems, onAddToBasket]);

  const canAddToBasket = useCallback(() => {
    return (
      totalQuantity > 0 &&
      nbOfEntities > 0 &&
      addToBasketItems.every(
        item => item.quantity > 0 && item.country != null && item.country !== '' && item.company != null
      )
    );
  }, [addToBasketItems, nbOfEntities, totalQuantity]);

  const isFetching = useSelector(selectIsFetchingCompanyProfiles);

  const COUNTRIES_MAP = getCountriesMap();
  const countriesOptions = useMemo(() => {
    return getProductCountryCodes(product)
      .filter(c => userCountryCodes.includes(c))
      .sort((c1, c2) => c1.localeCompare(c2))
      .map(c => {
        return {
          key: c,
          text: COUNTRIES_MAP.get(c) as string,
          value: c,
          flag: c.toLowerCase(),
        };
      })
      .filter((value, index, self) => index === self.findIndex(pr => pr.key === value.key))
      .sort((c1, c2) => c1.text.localeCompare(c2.text));
  }, [COUNTRIES_MAP, product, userCountryCodes]);

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <Flex css={style.container as never} flexDirection='column' p={3}>
      {product.productType === PRODUCT_TYPE.PACKAGE && (
        <Flex flexWrap='wrap' css={style.containerHeader as never}>
          <Flex flex='1' minWidth='max-content' flexDirection='column'>
            <Label css={style.label}>{t('product.totalQuantity')}</Label>
            <Input
              css={style.dropdown}
              size='small'
              type='number'
              fluid
              error={totalQuantity == null || totalQuantity <= 0}
              value={totalQuantity}
              onChange={(event, data) => setTotalQuantity(Number(data.value))}
            />
          </Flex>
          <Flex flex='1' minWidth='max-content' flexDirection='column'>
            <Label css={style.label}>{t('product.nbOfEntities')}</Label>
            <Input
              css={style.dropdown}
              size='small'
              type='number'
              fluid
              error={nbOfEntities == null || nbOfEntities <= 0}
              value={nbOfEntities}
              onChange={(event, data) => setNbOfEntities(Number(data.value))}
            />
          </Flex>
        </Flex>
      )}
      <Flex flexDirection='column' css={style.addToBasketItemContainer as never}>
        {addToBasketItems.map((addToBasketItem, index) => (
          <Flex key={addToBasketItem.key} flexWrap='wrap' flexDirection='column' css={style.addToBasketItem as never}>
            <Flex flexWrap='wrap'>
              <Label css={style.label}>{t('product.country')}</Label>
              <Dropdown
                css={style.dropdown}
                placeholder={t('menu.country')}
                search
                selection
                clearable
                error={addToBasketItem.country == null || addToBasketItem.country === ''}
                options={countriesOptions}
                onChange={(event, data) => updateCountry(index, String(data.value))}
              />
            </Flex>
            <Flex flexWrap='wrap'>
              <Label css={style.label}>{t('product.quantity')}</Label>
              <Input
                css={style.dropdown}
                size='small'
                type='number'
                fluid
                error={addToBasketItem.quantity == null || addToBasketItem.quantity <= 0}
                value={addToBasketItem.quantity}
                onChange={(event, data) => updateQuantity(index, Number(data.value))}
              />
            </Flex>
            <Box>
              <CompanyProfileSelector
                companyProfile={addToBasketItem.company}
                onSelectCompanyProfile={(cp: CompanyProfile | null) => updateCompanyProfile(index, cp)}
              />
            </Box>
          </Flex>
        ))}
      </Flex>
      <Box css={style.buttonsContainer as never}>
        <Button css={style.button} fluid primary disabled={!canAddToBasket()} onClick={() => onAddToBasketItems()}>
          <Icon name='shopping basket' />
          {t('product.addToBasket')}
        </Button>
      </Box>
    </Flex>
  );
};
