/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Flex } from 'rebass';
import { Icon, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ContentRef } from '../features/jobBoards/model/product';
import { FileDownload } from './FileDownload';

interface FilesDownloadProps {
  contents: ContentRef[];
  onDelete?: (content: ContentRef) => void;
}

export const FilesDownload = ({ contents, onDelete }: FilesDownloadProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex p={1} flexWrap='wrap'>
      {contents && contents.length > 0 ? (
        contents.map(content => (
          <Box key={content.id} p={1} width='100%' maxWidth='200px'>
            <Flex alignItems='center'>
              <Box flex='1'>
                <FileDownload content={content} />
              </Box>
              {onDelete != null && (
                <Box>
                  <Icon style={{ color: 'black', cursor: 'pointer' }} onClick={() => onDelete(content)} name='delete' />
                </Box>
              )}
            </Flex>
          </Box>
        ))
      ) : (
        <Box className='no-file-label'>
          <Label>{t('product.noFileAttached')}</Label>
        </Box>
      )}
    </Flex>
  );
};
