/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dimmer, DropdownProps, Loader, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductTable } from './productTable/ProductTable';
import {
  changeCountryFilter,
  changeRegionFilter,
  onChangeLimit,
  onChangePage,
  onSort,
  onSubmitFilter,
  selectCountryFilter,
  selectFilter,
  selectFilteredAndSortedAndPaginatedProducts,
  selectIsFetchingJobBoardDetail,
  selectPagination,
  selectRegionFilter,
  selectSort,
  selectTotalPagesAndCount,
} from '../store/jobBoardDetailSlice';
import style from './jobBoardDetailProductList.style';
import { ModalPage } from '../../../shared/ModalPage';
import { ProductPageSizeSelect } from './productTable/ProductPageSizeSelectProps';
import { ProductFilter } from './productTable/ProductFilter';
import { invalidateCache as jobBoardInvalidateCache } from '../store/jobBoardsSlice';
import { CreateProduct } from './CreateProduct';
import { checkRoles, ROLE } from '../../auth/model/user';
import { selectUser } from '../../auth/store/userSlice';
import { Product } from '../model/product';
import { Country } from '../model/countries';
import { RegionFilter } from '../../../shared/RegionFilter';
import { CountryFilter } from '../../../shared/CountryFilter';
import { useAppDispatch } from '../../../core/coreHooks';

export const JobBoardDetailProductList: React.FC = () => {
  const dispatch = useAppDispatch();
  const sort = useSelector(selectSort);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const isBroker = useMemo(() => checkRoles(user, [ROLE.BROKER]), [user]);

  const pagination = useSelector(selectPagination);
  const { totalPages } = useSelector(selectTotalPagesAndCount);
  const products = useSelector(selectFilteredAndSortedAndPaginatedProducts);
  const isFetching = useSelector(selectIsFetchingJobBoardDetail);

  const handleChangePage = useCallback((page: number) => dispatch(onChangePage(page)), [dispatch]);
  const handleSort = useCallback((clickedColumn: string) => dispatch(onSort(clickedColumn)), [dispatch]);

  const [isCreateProductModalOpen, setCreateProductModalOpen] = useState(false);
  const [productToClone, setProductToClone] = useState<Product>();

  const { id: jobBoardId } = useParams<{ id: string }>();

  const handleClickRow = useCallback(
    (product: Product) => {
      navigate(`/job-boards/${jobBoardId}/products/${product.id}`);
    },
    [navigate, jobBoardId]
  );

  const onClickCloneProduct = (cloneFrom?: Product) => {
    setProductToClone(cloneFrom);
    setCreateProductModalOpen(true);
  };

  const onCloseModal = () => {
    setCreateProductModalOpen(false);
    setProductToClone(undefined);
  };

  const onSave = () => {
    dispatch(jobBoardInvalidateCache());
    onCloseModal();
  };

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <>
      {isCreateProductModalOpen && (
        <ModalPage title={t('product.createProduct')} onClose={onCloseModal}>
          <CreateProduct onSave={() => onSave()} jobBoardId={jobBoardId || ''} cloneFrom={productToClone} />
        </ModalPage>
      )}
      <Segment css={style.segmentContainer}>
        <div css={style.productTableContainer}>
          <ProductTableHeader canCreateProduct={isBroker} onOpenModal={() => setCreateProductModalOpen(true)} />
          <ProductTable
            elements={products}
            totalPages={totalPages}
            currentPage={pagination.page}
            onChangePage={handleChangePage}
            column={sort.sortColumn}
            direction={sort.sortOrder}
            handleSort={handleSort}
            onClickRow={handleClickRow}
            onClickCloneProduct={isBroker ? onClickCloneProduct : undefined}
          />
        </div>
      </Segment>
    </>
  );
};

interface ProductTableHeaderProps {
  canCreateProduct: boolean;
  onOpenModal: () => void;
}

const ProductTableHeader = ({ canCreateProduct, onOpenModal }: ProductTableHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const pagination = useSelector(selectPagination);
  const filter = useSelector(selectFilter);
  const { totalCount } = useSelector(selectTotalPagesAndCount);
  const handleChangeLimit = useCallback((limit: number) => dispatch(onChangeLimit(limit)), [dispatch]);
  const handleSubmitFilter = useCallback((value: string) => dispatch(onSubmitFilter(value)), [dispatch]);

  const countryFilter = useSelector(selectCountryFilter);
  const regionFilter = useSelector(selectRegionFilter);

  const changeRegionFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeRegionFilter(data.value != null ? (data.value as string) : null));
    dispatch(changeCountryFilter(null));
  };

  const changeCountryFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeCountryFilter(data.value != null ? ({ name: data.text, code: data.value } as Country) : null));
  };

  return (
    <div css={style.filterAndPageSizeContainer}>
      {canCreateProduct && (
        <Button icon='plus' primary css={style.addButton} data-testid='create-product-button' onClick={onOpenModal} />
      )}
      <div css={style.totalCount}>
        <ProductPageSizeSelect limit={pagination.limit} onChangeLimit={handleChangeLimit} totalCount={totalCount} />
      </div>
      <div css={style.filter}>
        <RegionFilter defaultValue={regionFilter} changeRegionFilterHandler={changeRegionFilterHandler} />
      </div>
      <div css={style.filter}>
        <CountryFilter
          defaultValue={countryFilter}
          changeCountryFilterHandler={changeCountryFilterHandler}
          region={regionFilter}
        />
      </div>
      <ProductFilter filter={filter} totalCount={totalCount} onSubmitFilter={handleSubmitFilter} />
    </div>
  );
};
