/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../features/jobBoards/model/product';
import { ViewProduct } from './ViewProduct';
import { UpdateProduct } from './UpdateProduct';
import { deleteProduct } from '../features/jobBoards/store/jobBoardDetailSlice';
import { JOB_BOARD_STATUS } from '../features/jobBoards/model/jobBoard';
import { ModalPage } from './ModalPage';
import { useAppDispatch } from '../core/coreHooks';

interface ViewProductContainerProps {
  product: Product;
  jobBoardId: string;
  jobBoardStatus: JOB_BOARD_STATUS;
  onDelete: () => void;
  onUpdate: (product: Product) => void;
}

export const ViewProductContainer = ({
  product,
  jobBoardId,
  jobBoardStatus,
  onDelete,
  onUpdate,
}: ViewProductContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [updateMode, setUpdateMode] = useState(false);

  const onDeleteProduct = () => {
    dispatch(deleteProduct(jobBoardId, product));
    onDelete();
  };

  const onUpdateProduct = (updatedProduct: Product) => {
    onUpdate(updatedProduct);
    setUpdateMode(false);
  };

  return (
    <>
      <ViewProduct
        product={product}
        jobBoardStatus={jobBoardStatus}
        onEdit={() => setUpdateMode(true)}
        onDelete={() => onDeleteProduct()}
      />
      {updateMode && (
        <ModalPage title={t('product.updateProduct')} onClose={() => setUpdateMode(false)}>
          <UpdateProduct
            product={product}
            onChange={updatedProduct => onUpdateProduct(updatedProduct)}
            onCancel={() => setUpdateMode(false)}
          />
        </ModalPage>
      )}
    </>
  );
};
