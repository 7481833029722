/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Flex } from 'rebass';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import moment from 'moment/moment';
import { useAppDispatch } from '../../../core/coreHooks';
import { BrokerOrder, BrokerOrderOrder } from '../model/brokerOrder';
import { AcceptanceStatus, OrderStatus } from '../../orders/components/ViewOrder';
import { ViewPrice } from '../../../shared/ViewPrice';
import { CompanyProfileCard } from '../../companyProfiles/components/CompanyProfileCard';
import { updateContentsBrokerOrderOrder } from '../store/brokerOrderSlice';
import { ContentRef, FiletType } from '../../jobBoards/model/product';
import { ACCEPTANCE_STATUS, ORDER_STATUS, ProductOrder } from '../../orders/model/order';
import style from './viewBrokerOrder.style';
import { FilesDownload } from '../../../shared/FilesDownload';
import { FileUpload } from '../../../shared/FileUpload';
import { BrokerOrderPrice } from './BrokerOrderPrice';
import { ViewProductDetails } from '../../../shared/ViewProductDetails';
import { Country } from '../../jobBoards/model/countries';

interface ViewBrokerOrderProps {
  brokerOrder: BrokerOrder;
  countryFilter: Country | null;
  brandFilter: string | null;
  dateFromFilter: string | null;
  dateToFilter: string | null;
}

interface ProductTitleAndDescriptionProps {
  product: ProductOrder;
  acceptanceStatus?: ACCEPTANCE_STATUS;
  creationDate?: string;
  flagDescription: boolean;
}

const ProductTitleAndDescription = ({
  product,
  acceptanceStatus,
  creationDate,
  flagDescription,
}: ProductTitleAndDescriptionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column'>
      <Box width={[1]} p={1} css={style.productName as never}>
        <Flex flexDirection='row'>
          <Box>
            <Link to={`/products/${product.id}`}>
              <Icon color='blue' name='linkify' size='small' />
            </Link>
          </Box>
          <Box>
            <Link to={`/products/${product.id}`}>
              {product.name} ({product.jobBoardName})
            </Link>
          </Box>
          {acceptanceStatus && acceptanceStatus !== ACCEPTANCE_STATUS.TO_BE_ACCEPTED && (
            <AcceptanceStatus status={acceptanceStatus} />
          )}
        </Flex>
      </Box>
      {creationDate != null && (
        <Box width={[1]} p={1}>
          <div css={style.date}>
            {t('order.creationDate')}{' '}
            <strong>
              <Moment format='YYYY/MM/DD HH:mm'>{creationDate}</Moment>
            </strong>
          </div>
        </Box>
      )}
      <Box width={[1]} p={1}>
        <div hidden={!flagDescription} css={style.text}>
          {product.description}
        </div>
      </Box>
    </Flex>
  );
};

export const ViewBrokerOrder = ({
  brokerOrder,
  countryFilter,
  brandFilter,
  dateFromFilter,
  dateToFilter,
}: ViewBrokerOrderProps): JSX.Element => {
  const { t } = useTranslation();
  const bro = brokerOrder;

  const dispatch = useAppDispatch();

  const fileUploadHandler = (content: ContentRef, o: BrokerOrderOrder, broId: string) => {
    dispatch(updateContentsBrokerOrderOrder(broId, o.id, [...o.contents, content]));
  };

  const fileDeleteHandler = (content: ContentRef, o: BrokerOrderOrder, broId: string) => {
    const contents = o.contents.filter(c => c.id !== content.id);
    dispatch(updateContentsBrokerOrderOrder(broId, o.id, contents));
  };

  const showOrder = (order: BrokerOrderOrder) =>
    checkCountryFilter(order) && checkBrandFilter(order) && checkDateFilter(order);

  const checkCountryFilter = (order: BrokerOrderOrder) =>
    countryFilter == null || countryFilter.code === '' || order.country === countryFilter.code;

  const checkBrandFilter = (order: BrokerOrderOrder) =>
    brandFilter == null || brandFilter === '' || (order.brands != null && order.brands.includes(brandFilter));

  const checkDateFilter = (order: BrokerOrderOrder) =>
    (dateFromFilter === null || moment(order.creationDate).isSameOrAfter(moment(dateFromFilter).startOf('day'))) &&
    (dateToFilter === null || moment(order.creationDate).isSameOrBefore(moment(dateToFilter).endOf('day')));

  return (
    <Card fluid>
      <Flex p={2} flexWrap='wrap'>
        <Box width={[1, 1, 2 / 3, 3 / 4]} pl={3} pr={3}>
          <Flex flexDirection='row'>
            <Box>
              <OrderStatus status={bro.status} userRole='broker' />
            </Box>
            <Box pt={1} css={style.date as never}>
              {t('brokerOrder.updated')} <Moment fromNow>{bro.lastUpdate}</Moment>
            </Box>
          </Flex>

          <ProductTitleAndDescription product={bro.product} flagDescription={false} />
          <ViewProductDetails
            product={bro.product}
            countryCodes={bro.product.countryCodes}
            showPriceTo
            creationDate={new Date(bro.creationDate)}
          />
          <Flex flexWrap='wrap'>
            {bro?.orders &&
              bro.orders
                .filter(o => showOrder(o))
                .map(o => (
                  <Box key={o.id} width={[1, 1 / 2, 1 / 2, 1 / 3]} p={2}>
                    <div style={{ backgroundColor: '#e7edf2', borderRadius: '5px', padding: '10px' }}>
                      <div>
                        {o.user.firstName} {o.user.lastName}
                      </div>
                      <div>{o.user.email}</div>

                      <div css={style.orderDate}>
                        {t('brokerOrder.creationDate')}{' '}
                        <strong>
                          <Moment format='YYYY/MM/DD HH:mm'>{o.creationDate}</Moment>
                        </strong>
                      </div>
                      <div css={style.orderDate}>
                        {t('brokerOrder.updated')}{' '}
                        <strong>
                          <Moment fromNow>{o.lastUpdate}</Moment>
                        </strong>
                      </div>

                      <AcceptanceStatus status={o.acceptanceStatus} />
                      <CompanyProfileCard companyProfile={o.company} />
                      <Flex>
                        <Box pr={2}>{t('brokerOrder.country')}</Box>
                        <Box>{o.country != null ? o.country : '-'}</Box>
                      </Flex>
                      <Flex>
                        <Box pr={2}>{t('brokerOrder.quantity')}</Box>
                        <Box>{o.quantity}</Box>
                      </Flex>
                      <Flex>
                        <Box pr={2}>{t('brokerOrder.totalPrice')}</Box>
                        <Box>
                          <ViewPrice currency={o.totalPrice.currency} value={o.totalPrice.value} />
                        </Box>
                      </Flex>
                      {bro.status === ORDER_STATUS.CLOSED && o.acceptanceStatus === ACCEPTANCE_STATUS.ACCEPTED && (
                        <Flex>
                          <Box width={1}>
                            <Flex flexWrap='wrap' margin='0 -5px'>
                              <Box width={[1, 1 / 2]} minWidth='150px' margin='5px 0 5px'>
                                <Flex
                                  css={style.attachments as never}
                                  flexDirection='column'
                                  height='100%'
                                  alignItems='center'>
                                  <Box>
                                    <strong>{t('brokerOrder.invoices')}</strong>
                                  </Box>
                                  <Box width='100%'>
                                    <FilesDownload
                                      onDelete={content => fileDeleteHandler(content, o, bro.id)}
                                      contents={o.contents.filter(
                                        c => !c.fileType || c.fileType === FiletType.ORDER_INVOICE
                                      )}
                                    />
                                  </Box>
                                  <Box marginTop='auto'>
                                    <FileUpload
                                      id={o.id}
                                      onFileUpload={(content: ContentRef) => fileUploadHandler(content, o, bro.id)}
                                      buttonLabel={t('brokerOrder.addInvoice')}
                                      fileType={FiletType.ORDER_INVOICE}
                                    />
                                  </Box>
                                </Flex>
                              </Box>
                              <Box width={[1, 1 / 2]} minWidth='150px' margin='5px 0 5px'>
                                <Flex
                                  css={style.attachments as never}
                                  flexDirection='column'
                                  height='100%'
                                  alignItems='center'>
                                  <Box>
                                    <strong>{t('brokerOrder.proposals')}</strong>
                                  </Box>
                                  <Box width='100%'>
                                    <FilesDownload
                                      onDelete={content => fileDeleteHandler(content, o, bro.id)}
                                      contents={o.contents.filter(c => c.fileType === FiletType.ORDER_PROPOSAL)}
                                    />
                                  </Box>
                                  <Box marginTop='auto'>
                                    <FileUpload
                                      id={o.id}
                                      onFileUpload={(content: ContentRef) => fileUploadHandler(content, o, bro.id)}
                                      buttonLabel={t('brokerOrder.addProposal')}
                                      fileType={FiletType.ORDER_PROPOSAL}
                                    />
                                  </Box>
                                </Flex>
                              </Box>
                            </Flex>
                          </Box>
                        </Flex>
                      )}
                    </div>
                  </Box>
                ))}
          </Flex>
        </Box>
        <Box css={style.orderPriceContainer as never} width={[1, 1, 1 / 3, 1 / 4]}>
          <BrokerOrderPrice brokerOrder={bro} />
        </Box>
      </Flex>
    </Card>
  );
};
