/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Dimmer, DropdownProps, Icon, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { AppDispatch } from '../../../core/store';
import {
  changeBrandFilter,
  changeCountryFilter,
  changeFromOrderDateFilter,
  changeJobBoardFilter,
  changePeriodFilter,
  changeProductTypeFilter,
  changeStatusFilter,
  changeToOrderDateFilter,
  fetchOrders,
  selectBrandFilter,
  selectCountryFilter,
  selectFilteredOrders,
  selectFromOrderDateFilter,
  selectIsFetchingOrders,
  selectJobBoardFilter,
  selectPeriodFilter,
  selectProductTypeFilter,
  selectStatusFilter,
  selectToOrderDateFilter,
} from '../store/orderSlice';
import { ViewOrder } from '../components/ViewOrder';
import style from './orders.style';
import { StatusFilter } from '../../../shared/StatusFilter';
import { PeriodFilter } from '../../../shared/PeriodFilter';
import XLSXUtils from '../../../shared/XLSXUtils';
import { DatepickerFilter } from '../../../shared/DatepickerFilter';
import { CountryFilter } from '../../../shared/CountryFilter';
import { JobBoardFilter } from '../../../shared/JobBoardFilter';
import { BrandFilter } from '../../../shared/BrandFilter';

import { selectUser } from '../../auth/store/userSlice';
import { ProductTypeFilter } from '../../../shared/ProductTypeFilter';
import { useAppDispatch } from '../../../core/coreHooks';

export const Orders = (): JSX.Element => {
  const isFetching = useSelector(selectIsFetchingOrders);
  const dispatch: AppDispatch = useAppDispatch();
  const { t } = useTranslation();

  const periodFilter = useSelector(selectPeriodFilter);
  const statusFilter = useSelector(selectStatusFilter);
  const countryFilter = useSelector(selectCountryFilter);
  const jobBoardFilter = useSelector(selectJobBoardFilter);
  const brandFilter = useSelector(selectBrandFilter);
  const dateFromFilter = useSelector(selectFromOrderDateFilter);
  const dateToFilter = useSelector(selectToOrderDateFilter);
  const productTypeFilter = useSelector(selectProductTypeFilter);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch, periodFilter]);

  const orders = useSelector(selectFilteredOrders);

  const changeStatusFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeStatusFilter(data.value as string));
  };

  const changePeriodFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changePeriodFilter(data.value as string));
  };

  const changeCountryFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(
      changeCountryFilter(data.value != null ? { name: data.text as string, code: data.value as string } : null)
    );
  };

  const changeJobBoardFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeJobBoardFilter(data.value as string));
  };

  const changeBrandFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeBrandFilter(data.value as string));
  };

  const changeDateFromFilterHandler = (data: Date) => {
    dispatch(changeFromOrderDateFilter(data ? moment(new Date(data)).format('YYYY-MM-DD') : null));
  };

  const changeDateToFilterHandler = (data: Date) => {
    dispatch(changeToOrderDateFilter(data ? moment(new Date(data)).format('YYYY-MM-DD') : null));
  };

  const changeProductTypeFilterHandler = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(changeProductTypeFilter(data.value as string));
  };

  const xlsDownloadHandler = () => {
    XLSXUtils.exportXls(
      [
        'product',
        'product type',
        'job board',
        'company name (legal entity)',
        'company address',
        'company vat',
        'invoice contact',
        'final price',
        'quantity',
        'total price',
        'acceptance status',
        'status',
        'created',
        'updated',
        'closed',
      ],
      orders.map(o => [
        o.product.name,
        o.product.productType,
        o.product.jobBoardName,
        o.company.name,
        o.company.address,
        o.company.vatNumber,
        o.company.invoiceContact,
        o.finalPrice != null ? `${o.finalPrice.value.toFixed(2)} ${o.finalPrice.currency}` : '',
        o.quantity.toString(),
        o.totalPrice != null ? `${o.totalPrice.value.toFixed(2)} ${o.totalPrice.currency}` : '',
        o.acceptanceStatus,
        t(`orderStatus.${o.brokerOrderStatus}.recruiter`),
        o.creationDate,
        o.lastUpdate,
        o.closedDate,
      ]),
      'my-orders',
      'My Orders'
    );
  };

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <div css={style.container}>
      {orders && (
        <>
          <Flex flexWrap='wrap' justifyContent='flex-start' flexDirection='row' width='100%'>
            <Box flex={1} minWidth='200px'>
              <Flex flexWrap='wrap' justifyContent='flex-start'>
                <Box p={2}>
                  <StatusFilter
                    defaultValue={statusFilter}
                    changeStatusFilterHandler={changeStatusFilterHandler}
                    userRole='recruiter'
                  />
                </Box>
                <Box p={2}>
                  <PeriodFilter defaultValue={periodFilter} changePeriodFilterHandler={changePeriodFilterHandler} />
                </Box>
                <Box p={2}>
                  <DatepickerFilter
                    onChangeValue={changeDateFromFilterHandler}
                    defaultValue={dateFromFilter != null ? moment(dateFromFilter).toDate() : null}
                    period={periodFilter}
                    label={t('filters.dateFromFilterLabel')}
                  />
                </Box>
                <Box p={2}>
                  <DatepickerFilter
                    onChangeValue={changeDateToFilterHandler}
                    defaultValue={dateToFilter != null ? moment(dateToFilter).toDate() : null}
                    period={periodFilter}
                    label={t('filters.dateToFilterLabel')}
                  />
                </Box>
                <Box p={2}>
                  <CountryFilter defaultValue={countryFilter} changeCountryFilterHandler={changeCountryFilterHandler} />
                </Box>
                <Box p={2}>
                  <JobBoardFilter defaultValue={jobBoardFilter} changeJBFilterHandler={changeJobBoardFilterHandler} />
                </Box>
                {user.brands.length > 1 && (
                  <Box p={2}>
                    <BrandFilter
                      defaultValue={brandFilter}
                      options={[...user.brands.map(r => ({ key: r, text: r, value: r }))]}
                      changeBrandFilterHandler={changeBrandFilterHandler}
                    />
                  </Box>
                )}
                <Box p={2}>
                  <ProductTypeFilter
                    defaultValue={productTypeFilter}
                    changeProductTypeFilterHandler={changeProductTypeFilterHandler}
                  />
                </Box>
              </Flex>
            </Box>
            <Box p={2}>
              <Button icon labelPosition='right' onClick={() => xlsDownloadHandler()} primary>
                {t('brokerOrder.exportExcel')}
                <Icon name='download' />
              </Button>
            </Box>
          </Flex>

          <Flex flexWrap='wrap'>
            <Box width={[1]} p={2} pr={1}>
              <div>
                {orders.map(o => (
                  <ViewOrder key={o.id} order={o} />
                ))}
              </div>
            </Box>
          </Flex>
          {orders.length === 0 && (
            <div css={style.emptyContainer}>
              <div>{t('orders.noOrders')}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
