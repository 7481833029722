/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { Country, getCountries, getCountriesByRegion } from '../features/jobBoards/model/countries';
import style from './countryFilter.style';

interface CountryFilterProps {
  defaultValue: Country | null;
  changeCountryFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  region?: string | null;
}

export const CountryFilter = ({
  changeCountryFilterHandler,
  defaultValue,
  region,
}: CountryFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const emptyDropdownField = { key: '', text: '-', value: '' };

  const countriesOptions = useMemo(
    () =>
      (region ? getCountriesByRegion(region) : getCountries()).map(c => {
        return {
          key: c.code,
          text: c.name,
          value: c.code,
          flag: c.code.toLowerCase(),
        };
      }),
    [region]
  );

  const value = useMemo(() => (defaultValue != null ? defaultValue.code : ''), [defaultValue]);

  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2} width='max-content'>
          <div>{t('filters.countryFilterLabel')}</div>
        </Box>
        <Box p={2} flex={1}>
          <Dropdown
            search
            css={style.dropdown}
            placeholder={t('filters.searchPlaceholder')}
            value={value}
            options={[emptyDropdownField, ...countriesOptions]}
            onChange={(event, data) => changeCountryFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
