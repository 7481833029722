/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Dimmer } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { acceptPolicy, selectUser } from '../store/userSlice';
import style from './privacyModal.style';
import { User } from '../model/user';
import { useAppDispatch } from '../../../core/coreHooks';

export const PrivacyModal = (): JSX.Element => {
  const user = useSelector(selectUser) as User;
  const [policiesAccepted, setPoliciesAccepted] = useState((user as User).policiesAccepted);
  const dispatchUser = useAppDispatch();
  const dispatchPolicy = useCallback(() => dispatchUser(acceptPolicy()), [dispatchUser]);

  return !user.policiesAccepted ? (
    <Dimmer active css={style.dimmer}>
      <div css={style.modal}>
        <div css={style.body}>
          <p>
            You are about to share certain information with Kering for login purposes. Legal basis is further explained
            in the{' '}
            <a
              href='https://keringconnect.service-now.com/sp/?id=digital_kb_article&sys_id=86880aa91b5064d0bc126534b24bcbe5'
              target='_blank'
              rel='noreferrer'>
              Privacy Policy
            </a>{' '}
            . The Personal information are collected based on your freely given consent and will be kept for duration of
            your contract. You may have the right to access, delete, modify, rectify, restrict, object processing or
            personal information concerning you, as well as the right to data portability if applicable. You can also
            lodge a complaint to your applicable supervisory authority. You can withdraw your consent at any time.
          </p>
          <p>
            By submitting your answer to this survey, you agree to the processing of your Personal Data as described
            above. If you need any further information regarding our privacy practices, contact us at{' '}
            <a href='mailto:privacy@kering.com'>privacy@kering.com</a>.
          </p>
          <Checkbox
            label='Accept'
            checked={policiesAccepted}
            onClick={(e, data) => setPoliciesAccepted(data.checked as boolean)}
          />
        </div>
        <div css={style.actions}>
          <Button disabled={!policiesAccepted} onClick={dispatchPolicy}>
            Ok
          </Button>
        </div>
      </div>
    </Dimmer>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};
