import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
// eslint-disable-next-line import/no-cycle
import api from '../utils/api';
import { BrokerOrder } from '../model/brokerOrder';
import { ContentRef, Price } from '../../jobBoards/model/product';
import { toastService } from '../../../core/services/toastService';
import { checkValueInclude } from '../../../shared/utils';
import { Country } from '../../jobBoards/model/countries';

export const THREE_MONTHS = 'THREE_MONTHS';
export const ALL_STATUSES = 'ALL_STATUSES';

interface BrokerOrderSliceState {
  value: BrokerOrder[];
  isFetching: boolean;
  error: string;
  periodFilter: string;
  statusFilter: string;
  countryFilter: Country | null;
  jobBoardFilter: string;
  brandFilter: string;
  fromOrderDate: string | null;
  toOrderDate: string | null;
  productTypeFilter: string;
}

const initialState = {
  value: [],
  isFetching: false,
  error: '',
  periodFilter: THREE_MONTHS,
  statusFilter: ALL_STATUSES,
  countryFilter: null,
  jobBoardFilter: '',
  brandFilter: '',
  fromOrderDate: null,
  toOrderDate: null,
  productTypeFilter: '',
} as BrokerOrderSliceState;

export const brokerOrderSlice = createSlice({
  name: 'brokerOrders',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrokerOrderSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<BrokerOrder[]>) => {
      action.payload.sort((a: BrokerOrder, b: BrokerOrder) => {
        const ad = new Date(a.lastUpdate);
        const bd = new Date(b.lastUpdate);
        return ad.getTime() > bd.getTime() ? -1 : 1;
      });

      return {
        ...state,
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    updateOrder: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<BrokerOrder>) => {
      const bros = JSON.parse(JSON.stringify(state.value));
      const broIndex = bros.findIndex((i: BrokerOrder) => i.id === action.payload.id);
      if (broIndex > -1) {
        bros[broIndex] = action.payload;
      }
      return {
        ...state,
        value: bros,
      };
    },
    changePeriodFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      periodFilter: action.payload,
    }),
    changeStatusFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      statusFilter: action.payload,
    }),
    changeCountryFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<Country | null>) => ({
      ...state,
      countryFilter: action.payload,
    }),
    changeJobBoardFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      jobBoardFilter: action.payload,
    }),
    changeBrandFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      brandFilter: action.payload,
    }),
    changeFromOrderDateFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string | null>) => ({
      ...state,
      fromOrderDate: action.payload,
    }),
    changeToOrderDateFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string | null>) => ({
      ...state,
      toOrderDate: action.payload,
    }),
    changeProductTypeFilter: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      productTypeFilter: action.payload,
    }),
    httpError: (state: Draft<BrokerOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  updateOrder,
  changePeriodFilter,
  changeStatusFilter,
  changeCountryFilter,
  changeJobBoardFilter,
  changeBrandFilter,
  changeFromOrderDateFilter,
  changeToOrderDateFilter,
  changeProductTypeFilter,
} = brokerOrderSlice.actions;

export const selectBrokerOrders = (state: RootState): BrokerOrder[] => state.brokerOrders.value;
export const selectIsFetchingBrokerOrders = (state: RootState): boolean => state.brokerOrders.isFetching;
export const selectPeriodFilter = (state: RootState): string => state.brokerOrders.periodFilter;
export const selectStatusFilter = (state: RootState): string => state.brokerOrders.statusFilter;
export const selectCountryFilter = (state: RootState): Country | null => state.brokerOrders.countryFilter;
export const selectJobBoardFilter = (state: RootState): string => state.brokerOrders.jobBoardFilter;
export const selectBrandFilter = (state: RootState): string => state.brokerOrders.brandFilter;
export const selectFromOrderDateFilter = (state: RootState): string | null => state.brokerOrders.fromOrderDate;
export const selectToOrderDateFilter = (state: RootState): string | null => state.brokerOrders.toOrderDate;
export const selectProductTypeFilter = (state: RootState): string => state.brokerOrders.productTypeFilter;

export const fetchBrokerOrders = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const orders = await api.getBrokerOrders(state().brokerOrders.periodFilter);
    dispatch(finishFetch(orders));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

const checkCountryFilter = (countryFilter: Country | null, order: BrokerOrder) =>
  countryFilter == null || countryFilter.code === '' || order.orders.some(o => o.country === countryFilter.code);

const checkBrandFilter = (brandFilter: string | null, order: BrokerOrder) =>
  brandFilter == null ||
  brandFilter === '' ||
  order.orders.some(o => o.brands != null && o.brands.includes(brandFilter));

const checkDateFilter = (fromDate: string | null, toDate: string | null, order: BrokerOrder) => {
  return order.orders.some(
    o =>
      (fromDate === null || moment(o.creationDate).isSameOrAfter(moment(fromDate).startOf('day'))) &&
      (toDate === null || moment(o.creationDate).isSameOrBefore(moment(toDate).endOf('day')))
  );
};
export const selectFilteredBrokerOrders = createSelector(
  selectBrokerOrders,
  selectStatusFilter,
  selectCountryFilter,
  selectJobBoardFilter,
  selectBrandFilter,
  selectFromOrderDateFilter,
  selectToOrderDateFilter,
  selectProductTypeFilter,
  (orders, statusFilter, countryFilter, jobBoardFilter, brandFilter, fromDate, toDate, productType) => {
    return orders.filter(order => {
      return (
        (statusFilter === ALL_STATUSES || order.status === statusFilter) &&
        checkCountryFilter(countryFilter, order) &&
        checkValueInclude(order.product.jobBoardId, jobBoardFilter) &&
        checkBrandFilter(brandFilter, order) &&
        checkDateFilter(fromDate, toDate, order) &&
        (checkValueInclude(order.product.productType, productType) ||
          (productType === '' && order.product.productType == null))
      );
    });
  }
);

export const updateFinalPrice =
  (brokerOrderId: string, finalPrice: Price): AppThunk =>
  async dispatch => {
    try {
      const order = await api.updateFinalPrice(brokerOrderId, finalPrice);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const closeBrokerOrder =
  (brokerOrderId: string, finalPrice: Price): AppThunk =>
  async dispatch => {
    try {
      const order = await api.close(brokerOrderId, finalPrice);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const cancelBrokerOrder =
  (brokerOrderId: string): AppThunk =>
  async dispatch => {
    try {
      const order = await api.cancel(brokerOrderId);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateContentsBrokerOrderOrder =
  (brokerOrderId: string, orderId: string, contents: ContentRef[]): AppThunk =>
  async dispatch => {
    try {
      const order = await api.updateContentsBrokerOrderOrder(brokerOrderId, orderId, contents);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export default brokerOrderSlice.reducer;
