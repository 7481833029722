import { css } from '@emotion/react';

const price = css`
  width: 100%;
  && > input {
    text-align: right;
  }
`;

const currency = css`
  width: 100%;
`;

export default {
  price,
  currency,
};
