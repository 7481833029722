/* eslint-disable @typescript-eslint/no-explicit-any,import/no-cycle */
import moment from 'moment';
import { REGION } from '../features/jobBoards/model/countries';
import { Price, Product } from '../features/jobBoards/model/product';
import { ProductOrder } from '../features/orders/model/order';

export const groupBy = (key: string) => {
  return function group(array: any[]): Record<string, unknown> {
    return array.reduce((acc, obj) => {
      const property = deepFind(obj, key);
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
};

const deepFind = (obj: any, path: string): any => {
  const paths = path.split('.');
  let current = obj;
  let i;

  for (i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] == null) {
      return undefined;
    }
    current = current[paths[i]];
  }
  return current;
};

export const checkValueInclude = (value: string, filter: string): boolean => {
  return value != null && value.toLowerCase().includes(filter.toLowerCase());
};

export const stringIsNotUndefinedOrEmpty = (value: string | undefined): boolean => {
  return value != null && value !== '';
};

export const priceIsNotUndefinedOrEmpty = (price: Price | undefined): boolean => {
  return price != null && stringIsNotUndefinedOrEmpty(price.currency) && price.value != null;
};

export const countriesAreSelected = (countries: string[] | undefined): boolean => {
  return countries !== undefined && countries.length > 0;
};

export const regionsAreSelected = (regions: REGION | undefined): boolean => {
  return regions !== undefined && regions.length > 0;
};

export const isProductFinalPriceValid = (product: Product | ProductOrder, refDate?: Date): boolean => {
  return (
    product.finalPrice != null &&
    product.validityEndDate != null &&
    moment(refDate).isSameOrBefore(product.validityEndDate, 'day')
  );
};
