import axios from 'axios';
import paths from './paths';
import { ContentRef, Product, ProductUpdateRequest, toProduct } from '../../jobBoards/model/product';

const api = {
  getProducts: async (): Promise<Product[]> => {
    const response = await axios.get(paths.getProducts());
    return response.data.map(toProduct);
  },
  updateProduct: async (productId: string, request: ProductUpdateRequest): Promise<Product> => {
    const response = await axios.put(paths.updateProduct(productId), request);
    return toProduct(response.data);
  },
  uploadFile: async (formData: FormData): Promise<ContentRef> => {
    const response = await axios.post(paths.uploadFile(), formData);
    return response.data;
  },
  downloadFile: async (fileId: string): Promise<BlobPart> => {
    const response = await axios.get(paths.downloadFile(fileId), { responseType: 'blob' });
    return response.data;
  },
};

export default api;
