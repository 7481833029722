import { css } from '@emotion/react';

const card = css`
  padding: 10px 10px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100% !important;
  color: black;
  text-align: left;
  background-color: white;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid rgba(34, 36, 38, 0.15);
`;

const companyText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const vat = css`
  font-size: x-small;
`;

const vatText = css`
  padding-top: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const invoiceContact = css`
  padding-top: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const address = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default {
  card,
  vat,
  companyText,
  vatText,
  invoiceContact,
  address,
};
