/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { ContentRef } from '../features/jobBoards/model/product';
import api from '../features/products/utils/api';
import style from './fileDownload.style';

interface FileDownloadProps {
  content: ContentRef;
}

export const FileDownload = ({ content }: FileDownloadProps): JSX.Element => {
  const onDownloadClick = async () => {
    const url = window.URL.createObjectURL(new Blob([await api.downloadFile(content.id)]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', content.fileName);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Label css={style.label} title={content.fileName} onClick={() => onDownloadClick()}>
      {content.mimeType.includes('pdf') ? <Icon name='file pdf outline' /> : <Icon name='file outline' />}
      {content.fileName}
    </Label>
  );
};
