/** @jsxImportSource @emotion/react */
import { Button, Card, Checkbox, Icon, Label, Modal } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import React from 'react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../core/coreHooks';
import { ManagedUser } from '../model/managedUser';
import { AppDispatch } from '../../../core/store';
import { deleteManagedUser } from '../store/usersAdminSlice';

interface UserCardProps {
  user: ManagedUser;
  onUserEdit: () => void;
}

export const UserCard = ({ user, onUserEdit }: UserCardProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();

  const onEditHandler = () => {
    onUserEdit();
  };
  const onDeleteHandler = () => {
    dispatch(deleteManagedUser(user.id));
  };

  return (
    <Card fluid>
      <Flex flexDirection='column' p={2}>
        {(user.firstName || user.lastName) && (
          <Box p={2}>
            {t('managedUser.name')}: {user.firstName} {user.lastName}
          </Box>
        )}
        <Box p={2} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {t('managedUser.email')}: {user.email}
        </Box>
        <Box p={2}>
          {t('managedUser.type')}:{' '}
          <Label>
            <Icon name='group' />
            {user.type}
          </Label>
        </Box>
        <Box p={2}>
          {t('managedUser.roles')}:{' '}
          {user.roles.map(r => (
            <Label>{r}</Label>
          ))}
        </Box>
        <Box p={2}>
          {t('managedUser.brands')}:{' '}
          {user.brands.map(r => (
            <Label>{r}</Label>
          ))}
        </Box>
        <Box p={2}>
          Policy accepted: <Checkbox style={{ padding: '4px' }} disabled checked={user.policiesAccepted} />
        </Box>

        <Box style={{ marginTop: 'auto', textAlign: 'right' }}>
          <Button size='big' icon='edit' basic onClick={() => onEditHandler()} />

          <Modal
            trigger={<Button size='big' icon='trash alternate outline' basic />}
            header={t('managedUser.confirmDelete')}
            content={t('managedUser.deleteMessage')}
            actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => onDeleteHandler() }]}
          />
        </Box>
      </Flex>
    </Card>
  );
};
