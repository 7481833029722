import axios from 'axios';
import paths from './paths';
import { ManagedUser, ManagedUserCreateRequest, toManagedUser, toManagedUserUpdateRequest } from '../model/managedUser';

const api = {
  getManagedUsers: async (): Promise<ManagedUser[]> => {
    const response = await axios.get(paths.getUsers());
    return response.data.map(toManagedUser);
  },
  deleteManagedUser: async (id: string): Promise<null> => {
    await axios.delete(paths.deleteUser(id));
    return null;
  },
  updateManagedUser: async (id: string, user: ManagedUser): Promise<ManagedUser> => {
    const response = await axios.put(paths.updateManagedUser(id), toManagedUserUpdateRequest(user));
    return toManagedUser(response.data as Record<string, unknown>);
  },
  createManagedUser: async (request: ManagedUserCreateRequest): Promise<ManagedUser> => {
    const response = await axios.post(paths.createManagedUser(), request);
    return toManagedUser(response.data as Record<string, unknown>);
  },
};

export default api;
