import { createSlice, Dispatch, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
import { ManagedUser, ManagedUserCreateRequest } from '../model/managedUser';
import { toastService } from '../../../core/services/toastService';
// eslint-disable-next-line import/no-cycle
import { httpError } from '../../companyProfiles/store/companyProfilesSlice';
import { checkValueInclude } from '../../../shared/utils';

// eslint-disable-next-line import/no-cycle

interface ManagedUsersAdminSliceState {
  values: ManagedUser[];
  isFetching: boolean;
  error: string;
  filter: string;
}

const initialState = {
  values: [],
  isFetching: false,
  error: '',
  filter: '',
} as ManagedUsersAdminSliceState;

export const usersAdminSlice = createSlice({
  name: 'usersAdmin',
  initialState,
  reducers: {
    startFetch: (state: ManagedUsersAdminSliceState) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<ManagedUsersAdminSliceState>, action: PayloadAction<ManagedUser[]>) => {
      return { ...state, isFetching: false, values: [...action.payload], error: '' };
    },
    fail: (state: ManagedUsersAdminSliceState, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    addStateManagedUser: (state: ManagedUsersAdminSliceState, action: PayloadAction<ManagedUser>) => {
      const users = [...state.values];
      users.push(action.payload);
      return {
        ...state,
        isFetching: false,
        values: users,
      };
    },
    updateStateManagedUser: (state: ManagedUsersAdminSliceState, action: PayloadAction<ManagedUser>) => {
      const users = [...state.values];
      const existingItemIndex = users.findIndex((u: ManagedUser) => u.id === action.payload.id);
      if (existingItemIndex > -1) {
        users[existingItemIndex] = action.payload;
      }

      return {
        ...state,
        isFetching: false,
        values: users,
      };
    },
    removeManagedUser: (state: Draft<ManagedUsersAdminSliceState>, action: PayloadAction<string>) => {
      return {
        ...state,
        values: state.values.filter(p => p.id !== action.payload),
      };
    },
    setManagedUsersFilter: (state: Draft<ManagedUsersAdminSliceState>, action: PayloadAction<string>) => {
      return {
        ...state,
        filter: action.payload,
      };
    },
  },
});

export const {
  startFetch,
  finishFetch,
  fail,
  removeManagedUser,
  updateStateManagedUser,
  addStateManagedUser,
  setManagedUsersFilter,
} = usersAdminSlice.actions;

export const fetchManagedUsers = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const users = await api.getManagedUsers();
    dispatch(finishFetch(users));
  } catch (error) {
    dispatch(fail(JSON.stringify(error)));
  }
};

export const deleteManagedUser =
  (id: string): AppThunk =>
  async (dispatch): Promise<null> => {
    try {
      await api.deleteManagedUser(id);
      dispatch(removeManagedUser(id));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
    return null;
  };

export const updateManagedUser =
  (user: ManagedUser): AppThunk =>
  async (dispatch): Promise<ManagedUser | null> => {
    try {
      dispatch(startFetch());
      const u = await api.updateManagedUser(user.id, user);
      dispatch(updateStateManagedUser(u));
      toastService.success();
      return u;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const createManagedUser =
  (request: ManagedUserCreateRequest) =>
  async (dispatch: Dispatch): Promise<ManagedUser | null> => {
    try {
      const u = await api.createManagedUser(request);
      dispatch(addStateManagedUser(u));
      toastService.success();
      return u;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectManagedUsers = (state: RootState): ManagedUser[] =>
  state.usersAdmin.values.filter(
    u =>
      checkValueInclude(u.email, state.usersAdmin.filter) ||
      checkValueInclude(u.firstName, state.usersAdmin.filter) ||
      checkValueInclude(u.lastName, state.usersAdmin.filter)
  );
export const selectIsFetchingManagedUsers = (state: RootState): boolean => state.usersAdmin.isFetching;
export const selectManagedUsersFilter = (state: RootState): string => state.usersAdmin.filter;
export default usersAdminSlice.reducer;
