/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUser } from '../../auth/store/userSlice';
import { AppDispatch } from '../../../core/store';
import { fetchJobBoardDetail, selectJobBoardDetail, updateJobBoardProduct } from '../store/jobBoardDetailSlice';
import { ViewProductContainer } from '../../../shared/ViewProductContainer';
import { Product } from '../model/product';
import { useAppDispatch } from '../../../core/coreHooks';

export const JobBoardProductDetail = (): JSX.Element => {
  const { id: jobBoardId } = useParams<{ id: string }>();
  const { productId } = useParams<{ productId: string }>();
  const dispatch: AppDispatch = useAppDispatch();

  const jobBoardDetail = useSelector(selectJobBoardDetail);
  const product = jobBoardDetail != null ? jobBoardDetail.products.find(p => p.id === productId) : undefined;

  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchJobBoardDetail(jobBoardId || ''));
  }, [dispatch, jobBoardId, user]);

  const onUpdate = (updatedProduct: Product) => {
    if (product != null) {
      dispatch(updateJobBoardProduct(jobBoardId || '', updatedProduct));
    }
  };

  const onDelete = () => {
    navigate(`/job-boards/${jobBoardId}`);
  };

  return jobBoardDetail && product ? (
    <ViewProductContainer
      jobBoardId={jobBoardDetail.id}
      jobBoardStatus={jobBoardDetail.status}
      product={product}
      onDelete={() => onDelete()}
      onUpdate={updatedProduct => onUpdate(updatedProduct)}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};
