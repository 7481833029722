/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
import { AddToBasketItem, Basket, BasketItem, toBasketRequest } from '../model/basket';
import api from '../utils/api';
import { Product } from '../../jobBoards/model/product';
import { toastService } from '../../../core/services/toastService';

interface BasketSliceState {
  value: Basket | null;
  isFetching: boolean;
  disableCheckout: boolean;
  error: string;
}

const initialState = {
  value: null,
  isFetching: false,
  disableCheckout: false,
  error: '',
} as BasketSliceState;

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    startFetch: (state: Draft<BasketSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<BasketSliceState>, action: PayloadAction<Basket>) => {
      return {
        ...state,
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    addToBasket: (state: Draft<BasketSliceState>, action: PayloadAction<BasketItem>) => {
      const basket = JSON.parse(JSON.stringify(state.value)) as Basket;
      if (basket != null) {
        if (basket.items == null) {
          basket.items = [];
        }
        const existingBasketItemIndex = basket.items.findIndex(
          i =>
            i.product.id === action.payload.product.id &&
            i.company?.id === action.payload.company?.id &&
            i.country === action.payload.country
        );
        if (existingBasketItemIndex > -1) {
          const newBasketItem = action.payload;
          newBasketItem.quantity = basket.items[existingBasketItemIndex].quantity + newBasketItem.quantity;
          basket.items[existingBasketItemIndex] = newBasketItem;
        } else {
          basket.items.push(action.payload);
        }

        return {
          ...state,
          isFetching: false,
          value: basket,
          error: '',
        };
      }
      return {
        ...state,
        isFetching: false,
        value: null,
        error: 'basket is not initialized',
      };
    },
    updateStateBasketItem: (state: Draft<BasketSliceState>, action: PayloadAction<BasketItem>) => {
      const stateBasket: Basket | null = state.value;
      if (stateBasket != null) {
        if (stateBasket.items == null) {
          stateBasket.items = [];
        }
        const existingBasketItemIndex = stateBasket.items.findIndex((i: BasketItem) => i.key === action.payload.key);
        if (existingBasketItemIndex > -1) {
          stateBasket.items[existingBasketItemIndex] = action.payload;
        }
      }
    },
    deleteStateBasketItem: (state: Draft<BasketSliceState>, action: PayloadAction<string>) => {
      const stateBasket: Basket | null = state.value;
      if (stateBasket != null) {
        if (stateBasket.items == null) {
          stateBasket.items = [];
        }
        const existingBasketItemIndex = stateBasket.items.findIndex((i: BasketItem) => i.key === action.payload);
        if (existingBasketItemIndex > -1) {
          stateBasket.items.splice(existingBasketItemIndex, 1);
        }
      }
    },
    httpError: (state: Draft<BasketSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
    disableCheckout: (state: Draft<BasketSliceState>) => ({
      ...state,
      disableCheckout: true,
    }),
    enableCheckout: (state: Draft<BasketSliceState>) => ({
      ...state,
      disableCheckout: false,
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  addToBasket,
  updateStateBasketItem,
  deleteStateBasketItem,
  disableCheckout,
  enableCheckout,
} = basketSlice.actions;

export const fetchBasket = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const basket = await api.getBasket();
    dispatch(finishFetch(basket));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const addProductToBasket =
  (product: Product, brands: string[] | null, items: AddToBasketItem[]): AppThunk =>
  async (dispatch, state) => {
    if (state().basket.value == null) {
      await dispatch(fetchBasket());
    }
    items.map(async item => {
      dispatch(
        addToBasket({
          product,
          quantity: item.quantity,
          company: item.company,
          brands,
          country: item.country,
        } as BasketItem)
      );
    });
    dispatch(updateServerBasket());
  };

export const updateBasketItem =
  (basketItem: BasketItem): AppThunk =>
  async (dispatch, state) => {
    if (state().basket.value == null) {
      await dispatch(fetchBasket());
    }
    dispatch(updateStateBasketItem(basketItem));
    await dispatch(updateServerBasket());
  };

export const doCheckout = (): AppThunk => async dispatch => {
  try {
    const basket = await api.doCheckout();
    dispatch(finishFetch(basket));
    toastService.success();
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const deleteBasketItem =
  (basketItemKey: string): AppThunk =>
  async (dispatch, state) => {
    if (state().basket.value == null) {
      await dispatch(fetchBasket());
    }
    dispatch(deleteStateBasketItem(basketItemKey));
    await dispatch(updateServerBasket());
  };

export const updateServerBasket = (): AppThunk => async (dispatch, state) => {
  try {
    const newBasket = state().basket.value;
    if (newBasket != null) {
      const updatedBasket: Basket = await api.updateBasket(toBasketRequest(newBasket));
      dispatch(finishFetch(updatedBasket));
      dispatch(enableCheckout());
    }
    toastService.success();
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectBasketState = (state: RootState) => state.basket;
export const selectBasket = (state: RootState) => state.basket.value;
export const selectIsFetchingBasket = (state: RootState) => state.basket.isFetching;
export const selectDisableCheckout = (state: RootState) => state.basket.disableCheckout;

export default basketSlice.reducer;
