import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';

export default class XLSXUtils {
  static exportXls = (columnNames: string[], elements: string[][], fileName: string, title: string): void => {
    const wb = utils.book_new();
    wb.Props = {
      Title: title,
      CreatedDate: new Date(),
    };
    wb.SheetNames.push(title);
    const wsData = [columnNames, ...elements];
    wb.Sheets[title] = utils.aoa_to_sheet(wsData);

    const wbOut = write(wb, { bookType: 'xlsx', type: 'binary' });

    return saveAs(
      new Blob([XLSXUtils.binaryToOctet(wbOut)], {
        type: 'application/octet-stream',
      }),
      XLSXUtils.getXLSFileName(fileName)
    );
  };

  static getXLSFileName = (fileName: string): string => `${fileName}-${new Date().toISOString()}.xlsx`;

  static binaryToOctet = (s: string): ArrayBuffer => {
    const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
    const view = new Uint8Array(buf); // create uint8array as viewer
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff; // convert to octet
    }
    return buf;
  };
}
