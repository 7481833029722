/** @jsxImportSource @emotion/react */
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, Menu, Popup, SemanticICONS, Sidebar } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import style from './mainMenu.style';
import { selectUser } from '../features/auth/store/userSlice';
import { useMediaQuery } from '../core/coreHooks';
import { checkRoles, ROLE } from '../features/auth/model/user';

export const MainMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const isPageLarge = useMediaQuery('(min-width: 1000px)');
  const [visible, setVisible] = React.useState(isPageLarge);
  const [isFullSize, setIsFullSize] = React.useState(false);
  const [animationMenu, setAnimationMenu] = React.useState(false);

  const handleOnClick = useCallback(() => {
    setAnimationMenu(true);
    setIsFullSize(!isFullSize);
  }, [isFullSize]);

  useEffect(() => {
    setVisible(isPageLarge);
  }, [isPageLarge]);

  const sidebarStyle = useMemo(() => {
    if (animationMenu) {
      return isFullSize
        ? style.sidebarLargePageWithOpenAnimation(animationMenu)
        : style.sidebarLargePageWithCloseAnimation(animationMenu);
    }
    return style.sidebar;
  }, [animationMenu, isFullSize]);

  return (
    <div>
      <Button icon css={style.button} onClick={handleOnClick}>
        <Icon name='bars' css={style.buttonIcon} />
      </Button>
      <Sidebar as={Menu} inverted vertical visible={(!isPageLarge && isFullSize) || visible} css={sidebarStyle}>
        <MainMenuSection isFullSize={isFullSize} label={t('menu.products')}>
          <MainMenuItem
            path='/job-boards'
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='list alternate'
            label={t('menu.jobBoardList')}
            roles={[ROLE.BROKER, ROLE.RECRUITER]}
          />
          <MainMenuItem
            path='/products'
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='clipboard list'
            label={t('menu.productList')}
            roles={[ROLE.BROKER, ROLE.RECRUITER]}
          />
        </MainMenuSection>
        <MainMenuSection isFullSize={isFullSize} label={t('menu.ordersTitle')}>
          <MainMenuItem
            path='/orders'
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='suitcase'
            label={t('menu.orders')}
            roles={[ROLE.RECRUITER]}
          />
          <MainMenuItem
            path='/client-orders'
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='copy'
            label={t('menu.clientOrders')}
            roles={[ROLE.BROKER]}
          />
          <MainMenuItem
            path='/brand-orders'
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='shopping bag'
            label={t('menu.brandOrders')}
            roles={[ROLE.RECRUITER_SUPERVISOR]}
          />
        </MainMenuSection>
        <MainMenuSection isFullSize={isFullSize} label={t('menu.administration')}>
          <MainMenuItem
            path='/users-admin'
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='users'
            label={t('menu.usersAdmin')}
            roles={[ROLE.ADMIN]}
          />
          <MainMenuItem
            path=''
            onClick={() => setIsFullSize(false)}
            isFullSize={isFullSize}
            iconName='code'
            label=''
            roles={[]}
          />
        </MainMenuSection>
      </Sidebar>
    </div>
  );
};

interface MainMenuItemProps {
  path: string;
  iconName: SemanticICONS;
  label: string;
  onClick: () => void;
  isFullSize: boolean;
  roles: ROLE[];
}

const MainMenuItem = ({ path, iconName, label, onClick, isFullSize, roles }: MainMenuItemProps) => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsLabelOpen(!isFullSize);
  }, [isFullSize]);

  const handleOnClose = useCallback(() => {
    setIsLabelOpen(false);
  }, []);

  const isActive = (currentPath: string) => {
    return location.pathname === currentPath;
  };

  return checkRoles(user, roles) ? (
    <Popup
      css={style.popup}
      content={label}
      position='right center'
      open={isLabelOpen}
      onOpen={handleOpen}
      onClose={handleOnClose}
      closeOnTriggerMouseLeave
      trigger={
        <Link to={path} onClick={onClick}>
          <Menu.Item css={style.menuItem} active={isActive(path)}>
            <Icon name={iconName} />
            {isFullSize && <div> {label}</div>}
          </Menu.Item>
        </Link>
      }
    />
  ) : null;
};

interface MainMenuSectionProps {
  isFullSize: boolean;
  label: string;
  children: ReactElement<MainMenuItemProps>[];
}

const MainMenuSection: React.FunctionComponent<MainMenuSectionProps> = props => {
  const { children, isFullSize, label } = props;
  const [roles, setRoles] = useState([] as ROLE[]);

  useEffect(() => {
    const rs = new Set<ROLE>();
    React.Children.map(children, (c: ReactElement) => c.props.roles.forEach((r: ROLE) => rs.add(r)));
    setRoles(Array.from(rs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useSelector(selectUser);
  return (
    <div css={isFullSize ? style.menuGroup : ''}>
      {checkRoles(user, roles) && isFullSize && <div css={style.menuGroupHeader}>{label}</div>}
      {children}
    </div>
  );
};
