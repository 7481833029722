/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { AppDispatch } from '../../../core/store';
import {
  deleteCompanyProfile,
  fetchCompanyProfiles,
  selectCompanyProfiles,
  updateCompanyProfile,
} from '../store/companyProfilesSlice';
import { ModalPage } from '../../../shared/ModalPage';
import { CreateCompanyProfile } from '../components/CreateCompanyProfile';
import style from './companyProfilesList.style';
import { UpdateCompanyProfile } from '../components/UpdateCompanyProfile';
import { CompanyProfile } from '../model/companyProfile';
import { useAppDispatch } from '../../../core/coreHooks';

export const CompanyProfilesList = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const { t } = useTranslation();

  const [isCompanyProfileModalOpen, setCompanyProfileModalOpen] = useState(false);

  const [selectedCompanyProfileForEdit, setSelectedCompanyProfileForEdit] = useState<null | CompanyProfile>(null);

  useEffect(() => {
    dispatch(fetchCompanyProfiles());
  }, [dispatch]);

  const companyProfiles = useSelector(selectCompanyProfiles);

  const onEditHandler = (c: CompanyProfile) => {
    setSelectedCompanyProfileForEdit(c);
  };
  const onDeleteHandler = (cp: CompanyProfile) => {
    dispatch(deleteCompanyProfile(cp.id));
  };

  const onUpdateHandler = async (cp: CompanyProfile) => {
    await dispatch(updateCompanyProfile(cp));
    setSelectedCompanyProfileForEdit(null);
  };

  return (
    <>
      {isCompanyProfileModalOpen && (
        <ModalPage title={t('companyProfile.createLabel')} onClose={() => setCompanyProfileModalOpen(false)}>
          <CreateCompanyProfile onSave={() => setCompanyProfileModalOpen(false)} />
        </ModalPage>
      )}
      {selectedCompanyProfileForEdit && (
        <ModalPage title={t('companyProfile.editLabel')} onClose={() => setSelectedCompanyProfileForEdit(null)}>
          <UpdateCompanyProfile companyProfile={selectedCompanyProfileForEdit} onUpdate={cp => onUpdateHandler(cp)} />
        </ModalPage>
      )}
      <Flex flexWrap='wrap' p={3}>
        <Box width={[1, 1 / 3, 1 / 4, 1 / 5]} p={2}>
          <Card css={style.addCard} onClick={() => setCompanyProfileModalOpen(true)}>
            <Icon name='add' size='big' />
            <div css={style.addCardText}>{t('companyProfile.createNew')}</div>
          </Card>
        </Box>

        {companyProfiles &&
          companyProfiles.map(c => (
            <Box width={[1, 1 / 3, 1 / 4, 1 / 5]} key={c.id} p={2}>
              <Card css={style.card}>
                <Flex flexDirection='column' style={{ height: '100%' }}>
                  <Box>
                    <strong>{c.name}</strong>
                  </Box>
                  <Box>{c.address}</Box>
                  {c.vatNumber && (
                    <Box>
                      <span css={style.vat}>{t('companyProfile.vatNumber')}:</span> {c.vatNumber}
                    </Box>
                  )}
                  {c.invoiceContact && (
                    <Box>
                      <span css={style.invoiceContact}>{t('companyProfile.invoiceContact')}:</span> {c.invoiceContact}
                    </Box>
                  )}

                  <Box style={{ marginTop: 'auto', textAlign: 'right' }}>
                    <Button size='big' icon='edit' basic onClick={() => onEditHandler(c)} />

                    <Modal
                      trigger={<Button size='big' icon='trash alternate outline' basic />}
                      header={t('confirmDelete')}
                      content={t('companyProfile.deleteMessage')}
                      actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => onDeleteHandler(c) }]}
                    />
                  </Box>
                </Flex>
              </Card>
            </Box>
          ))}
      </Flex>
    </>
  );
};
