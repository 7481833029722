import { css } from '@emotion/react';

const container = css`
  display: flex;
`;

const dropdownContainer = css`
  padding-right: 10px;
`;

const totalCount = css`
  padding-right: 10px;
`;

export default {
  container,
  dropdownContainer,
  totalCount,
};
