import { REGION } from '../../jobBoards/model/countries';

export interface User {
  id: string;
  name: string;
  pictureUrl: string;
  email: string;
  surname: string;
  firstName: string;
  policiesAccepted: boolean;
  roles: ROLE[];
  regions: REGION[];
  countryCodes: string[];
  brands: string[];
}

export const toUser = (o: Record<string, unknown>): User => ({
  id: o.id as string,
  name: o.name as string,
  pictureUrl: o.picture as string,
  email: o.email as string,
  surname: o.family_name as string,
  firstName: o.given_name as string,
  policiesAccepted: o.policiesAccepted as boolean,
  roles: o.roles as ROLE[],
  regions: o.regions as REGION[],
  countryCodes: o.countryCodes as string[],
  brands: (o.brands as string[]).sort(),
});

export enum ROLE {
  ADMIN = 'ADMIN',
  BROKER = 'BROKER',
  RECRUITER = 'RECRUITER',
  RECRUITER_SUPERVISOR = 'RECRUITER_SUPERVISOR',
}

export const checkRole = (user: User | null, role: ROLE): boolean => (user ? user.roles.includes(role) : false);
export const checkRoles = (user: User | null, roles: ROLE[]): boolean =>
  user ? user.roles.some(r => roles.includes(r)) : false;
