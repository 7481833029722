import { ContentRef, Price } from '../../jobBoards/model/product';
import { Company, toCompany } from '../../companyProfiles/model/companyProfile';
import { ACCEPTANCE_STATUS, ORDER_STATUS, ProductOrder, toProductOrder } from '../../orders/model/order';

export interface UserDetail {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface BrokerOrderOrder {
  id: string;
  company: Company;
  user: UserDetail;
  lastUpdate: string;
  creationDate: string;
  acceptanceStatus: ACCEPTANCE_STATUS;
  quantity: number;
  totalPrice: Price;
  contents: ContentRef[];
  brands: string[];
  country: string;
}

export interface BrokerOrder {
  id: string;
  creationDate: string;
  lastUpdate: string;
  closedDate: string;
  status: ORDER_STATUS;
  product: ProductOrder;
  finalPrice: Price | null;
  totalPrice: Price;
  totalQuantity: number;
  orders: BrokerOrderOrder[];
}

export const toBrokerOrder = (o: Record<string, unknown>): BrokerOrder => {
  return {
    id: o.id as string,
    creationDate: o.creationDate as string,
    lastUpdate: o.lastUpdate as string,
    closedDate: o.closedDate as string,
    status: o.status as ORDER_STATUS,
    product: toProductOrder(o.product as Record<string, unknown>),
    finalPrice: o.finalPrice as Price,
    totalPrice: o.totalPrice as Price,
    totalQuantity: o.totalQuantity as number,
    orders: (o.orders as Record<string, unknown>[]).map(toBrokerOrderOrder),
  };
};

const toBrokerOrderOrder = (o: Record<string, unknown>): BrokerOrderOrder => {
  return {
    id: o.id as string,
    company: toCompany(o.company as Record<string, unknown>),
    user: toUserDetail(o.user as Record<string, unknown>),
    lastUpdate: o.lastUpdate as string,
    creationDate: o.creationDate as string,
    acceptanceStatus: o.acceptanceStatus as ACCEPTANCE_STATUS,
    quantity: o.quantity as number,
    totalPrice: o.totalPrice as Price,
    contents: o.contents as ContentRef[],
    brands: o.brands as string[],
    country: o.country as string,
  };
};

const toUserDetail = (o: Record<string, unknown>): UserDetail => {
  return {
    id: o.id as string,
    firstName: o.firstName as string,
    lastName: o.lastName as string,
    email: o.email as string,
  };
};
