export interface JobBoard {
  id: string;
  brokerCompany: string;
  name: string;
  about: string;
  status: JOB_BOARD_STATUS;
  picture: PictureRef;
  availableProducts: number;
}

export interface PictureRef {
  id: string;
  data: string;
}

export enum JOB_BOARD_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export const toJobBoard = (o: Record<string, unknown>): JobBoard => {
  return {
    id: o.id as string,
    brokerCompany: o.brokerCompany as string,
    name: o.name as string,
    about: o.about as string,
    status: o.status as JOB_BOARD_STATUS,
    picture: o.picture as PictureRef,
    availableProducts: o.availableProducts as number,
  };
};
