import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { DEFAULT_CACHE_TTL } from '../../../constants';
import { JOB_BOARD_STATUS, JobBoard } from '../model/jobBoard';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
import { checkValueInclude, groupBy } from '../../../shared/utils';
// eslint-disable-next-line import/no-cycle
import {
  selectJobBoardsCountryFilter,
  selectJobBoardsRegionFilter,
  selectJobBoardsFilter,
} from './jobBoardsFilterSlice';
// eslint-disable-next-line import/no-cycle
import { fetchJobBoardDetail } from './jobBoardDetailSlice';
import { toastService } from '../../../core/services/toastService';
// eslint-disable-next-line import/no-cycle
import { selectUserCountryCodes } from '../../auth/store/userSlice';
import { JobBoardDetail } from '../model/JobBoardDetail';
import { filterProductsByCountryCodes } from '../model/product';
import { getCountryCodesByRegion } from '../model/countries';

interface JobBoardsSliceState {
  data: JobBoardDetail[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

const initialState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
} as JobBoardsSliceState;

export const jobBoardsSlice = createSlice({
  name: 'jobBoards',
  initialState,
  reducers: {
    startFetch: (state: Draft<JobBoardsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<JobBoardsSliceState>, payloadAction: PayloadAction<JobBoardDetail[]>) => {
      return {
        isFetching: false,
        data: [...payloadAction.payload],
        error: '',
        isValidCache: true,
      };
    },
    httpError: (state: Draft<JobBoardsSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    invalidateCache: (state: Draft<JobBoardsSliceState>) => ({ ...state, isValidCache: false }),
    addJobBoard: (state: Draft<JobBoardsSliceState>, payloadAction: PayloadAction<JobBoard>) => {
      const { data } = JSON.parse(JSON.stringify(state));
      data.push({ ...payloadAction.payload, products: [] });
      return {
        ...state,
        data,
      };
    },
    deleteJobBoard: (state: Draft<JobBoardsSliceState>, payloadAction: PayloadAction<string>) => {
      const { data } = JSON.parse(JSON.stringify(state));
      const index = data.findIndex((e: { id: string }) => e.id === payloadAction.payload);
      if (index === -1) {
        return state;
      }
      data.splice(index, 1);
      return { ...state, data };
    },
    updateJobBoard: (state: Draft<JobBoardsSliceState>, payloadAction: PayloadAction<JobBoard>) => {
      const { data } = JSON.parse(JSON.stringify(state));
      const index = data.findIndex((e: { id: string }) => e.id === payloadAction.payload.id);
      if (index === -1) {
        return {
          ...state,
          error: `Job Board with id: ${payloadAction.payload.id} does not exist`,
        };
      }
      data[index] = payloadAction.payload;
      return {
        ...state,
        data,
      };
    },
    fail: (state: Draft<JobBoardsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError, invalidateCache, addJobBoard, deleteJobBoard, updateJobBoard } =
  jobBoardsSlice.actions;

export const fetchJobBoards = (): AppThunk => async (dispatch, state) => {
  if (!state().jobBoards.isValidCache) {
    await dispatch(startFetch());
    try {
      const fetchedJobBoards = await api.getJobBoards();
      await dispatch(finishFetch(fetchedJobBoards));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
    setTimeout(() => dispatch(invalidateCache()), DEFAULT_CACHE_TTL);
  }
};

export const selectJobBoards = (state: RootState): JobBoardDetail[] => state.jobBoards.data;

export const selectActiveJobBoards = createSelector(selectJobBoards, jbs => {
  return jbs.filter(j => j.status === JOB_BOARD_STATUS.ACTIVE);
});

export const selectJobBoardsGroupedByStatus = createSelector(
  selectJobBoards,
  selectJobBoardsRegionFilter,
  selectJobBoardsCountryFilter,
  selectJobBoardsFilter,
  selectUserCountryCodes,
  (jbs, regionFilter, countryFilter, filter, countryCodes) => {
    const filteredJobBoards = jbs
      .filter(jb => !regionFilter || getCountryCodesByRegion(regionFilter).some(c => jb.countryCodes.includes(c)))
      .filter(jb => !countryFilter?.code || jb.countryCodes.includes(countryFilter.code))
      .filter(jb => checkValueInclude(jb.name, filter))
      .filter(jb => jb.products.length === 0 || jb.countryCodes.some(c => countryCodes.includes(c)))
      .map(jb => ({
        ...jb,
        availableProducts: filterProductsByCountryCodes(countryCodes, jb.products).length,
      }));

    return groupBy('status')(filteredJobBoards) as Record<JOB_BOARD_STATUS, JobBoard[]>;
  }
);

export const createJobBoard =
  (name: string, about: string, picId: string | null): AppThunk =>
  async (dispatch): Promise<JobBoard | null> => {
    try {
      let createdJobBoard = await api.createJobBoard(name, about);
      if (picId != null) {
        createdJobBoard = await api.updateJobBoardPicture(createdJobBoard.id, picId);
      }
      await dispatch(addJobBoard(createdJobBoard));
      toastService.success();
      return createdJobBoard;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const updateJobBoardFields =
  (id: string, name: string, about: string, status: JOB_BOARD_STATUS, picId: string | null): AppThunk =>
  async (dispatch): Promise<JobBoard | null> => {
    try {
      let updatedJobBoard = await api.updateJobBoard(id, name, about, status);
      if (picId != null) {
        updatedJobBoard = await api.updateJobBoardPicture(updatedJobBoard.id, picId);
      }
      dispatch(updateJobBoard(updatedJobBoard));
      dispatch(fetchJobBoardDetail(id));
      toastService.success();
      return updatedJobBoard;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const removeJobBoard =
  (id: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      await api.deleteJobBoard(id);
      await dispatch(deleteJobBoard(id));
      await dispatch(invalidateCache());
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export default jobBoardsSlice.reducer;
