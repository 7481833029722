import { css } from '@emotion/react';

const form = css`
  padding: 10px;
  height: 100%;

  .form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const button = css`
  margin-top: auto;
  margin-left: auto;
  width: min(200px, 40%);
  padding: 10px 0 10px;
`;

const label = css`
  float: left;
`;

const picturesListContainer = css`
  padding: 0 10px 10px;
`;

const rolesContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default {
  form,
  button,
  label,
  picturesListContainer,
  rolesContainer,
};
