import { API_URL } from '../../../constants';

const AUTH_URL = `${API_URL}/users`;
const DELETE_URL = `${API_URL}/user-orders`;

const paths = {
  getUsers: (): string => `${AUTH_URL}`,
  deleteUser: (id: string): string => `${DELETE_URL}/${id}`,
  updateManagedUser: (id: string): string => `${AUTH_URL}/${id}`,
  createManagedUser: (): string => `${AUTH_URL}`,
};

export default paths;
