import { css, SerializedStyles } from '@emotion/react';
import style from '../../../shared/global.style';
import { JOB_BOARD_STATUS } from '../model/jobBoard';

const card = (status: JOB_BOARD_STATUS): SerializedStyles => {
  return css`
    &&&&& {
      text-decoration: none;
      color: var(--jbmp-primary-color);
      width: 355px;
      border-radius: 0;
      flex-shrink: 0;
      margin: 10px 10px 10px 0;
      background-color: var(--jbmp-white-color);
      cursor: pointer;
      border-left: solid 5px ${style.statusColor(status)};

      @media (max-width: 400px) {
        width: 100%;
      }

      :hover {
        box-shadow: 0 1px 3px 0 var(--jbmp-primary-color-60), 0 0 0 1px var(--jbmp-primary-color-40);
      }
    }
  `;
};

const header = css`
  &&& {
    display: flex;
    padding: 0;
    flex-grow: 0;
  }
`;

const contentContainer = css`
  &&& {
    display: flex;
    height: 90px;
    padding: 10px;
    flex-grow: 0;
    width: 100%;
    overflow: hidden;
  }
`;

const title = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${style.subtitleNormal}
`;

const subTitle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ...${style.subtitleSmall};
    color: var(--jbmp-primary-color-60)
`;

const titleContainer = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
  justify-content: center;
`;

const imageContainer = css`
  padding-right: 10px;
  padding-left: 10px;
  flex-shrink: 0;
  margin: auto 0;
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const image = css`
  && {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
`;

export default {
  header,
  card,
  contentContainer,
  title,
  subTitle,
  titleContainer,
  imageContainer,
  image,
};
