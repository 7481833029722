/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
import {
  Pagination,
  Product,
  productContainsCountryCodes,
  productContainsString,
  SortField,
  SortOrder,
  toProductUpdateRequest,
} from '../../jobBoards/model/product';
import { toastService } from '../../../core/services/toastService';
import api from '../utils/api';
// eslint-disable-next-line import/no-cycle
import { selectUserCountryCodes } from '../../auth/store/userSlice';
import { Country, getCountryCodesByRegion } from '../../jobBoards/model/countries';

interface ProductsSliceState {
  value: Product[];
  isFetching: boolean;
  error: string;
  pagination: Pagination;
  sort: SortField;
  filter: string;
  regionFilter: string | null;
  countryFilter: Country | null;
  selectedProduct: Product | null;
}

const initialState = {
  value: [],
  isFetching: false,
  error: '',
  pagination: { page: 1, limit: 10 },
  sort: { sortColumn: 'name', sortOrder: 'ascending' },
  filter: '',
  regionFilter: null,
  countryFilter: null,
  selectedProduct: null,
} as ProductsSliceState;

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductsSliceState>) => ({
      ...state,
      pagination: { page: 1, limit: 10 },
      sort: { sortColumn: 'name', sortOrder: 'ascending' } as SortField,
      filter: '',
      isFetching: true,
    }),
    finishFetch: (state: Draft<ProductsSliceState>, action: PayloadAction<Product[]>) => {
      return {
        ...state,
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    setProduct: (state: Draft<ProductsSliceState>, action: PayloadAction<Product>) => {
      const foundProductIndex = state.value.findIndex(p => p.id === action.payload.id);
      if (foundProductIndex > -1) {
        state.value[foundProductIndex] = action.payload;
      }
    },
    httpError: (state: Draft<ProductsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
    change: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<Product[]>) => {
      return {
        ...state,
        isFetching: false,
        value: payload,
        error: '',
      };
    },
    selectProduct: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<string>) => {
      const foundProduct = state.value.find(p => p.id === payload);
      return {
        ...state,
        selectedProduct: foundProduct != null ? foundProduct : null,
      };
    },
    setPagination: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<Pagination>) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setSort: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<SortField>) => {
      return {
        ...state,
        sort: payload,
      };
    },
    setFilter: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        filter: payload,
      };
    },
    changeRegionFilter: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<string | null>) => {
      return { ...state, regionFilter: payload };
    },
    changeCountryFilter: (state: Draft<ProductsSliceState>, { payload }: PayloadAction<Country | null>) => {
      return { ...state, countryFilter: payload };
    },
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  change,
  setPagination,
  setSort,
  setFilter,
  selectProduct,
  setProduct,
  changeRegionFilter,
  changeCountryFilter,
} = productsSlice.actions;

export const fetchProducts = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const products = await api.getProducts();
    dispatch(finishFetch(products));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectAndFetchProduct =
  (productId: string): AppThunk =>
  async dispatch => {
    await dispatch(fetchProducts());
    dispatch(selectProduct(productId));
  };

export const updateAndSelectProduct =
  (product: Product): AppThunk =>
  async (dispatch): Promise<Product | null> => {
    try {
      const updatedProduct = await api.updateProduct(product.id, toProductUpdateRequest(product));
      await dispatch(setProduct(updatedProduct));
      await dispatch(selectProduct(updatedProduct.id));

      toastService.success();
      return updatedProduct;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const onSort =
  (clickedColumn: string): AppThunk =>
  (dispatch, state) => {
    const { sortColumn, sortOrder } = state().products.sort;
    const { pagination } = state().products;

    let newOrder: SortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
    if (sortColumn !== clickedColumn) {
      newOrder = 'ascending';
    }

    dispatch(setPagination({ ...pagination, page: 1 }));
    dispatch(setSort({ sortColumn: clickedColumn, sortOrder: newOrder }));
  };

export const onSubmitFilter =
  (value: string): AppThunk =>
  (dispatch, state) => {
    const { filter } = state().products;
    if (value !== filter) {
      const { pagination } = state().products;
      dispatch(setFilter(value));
      dispatch(setPagination({ ...pagination, page: 1 }));
    }
  };

export const onChangeLimit =
  (limit: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().products;
    if (limit !== pagination.limit) {
      dispatch(setPagination({ limit, page: 1 }));
    }
  };

export const onChangePage =
  (page: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().products;
    if (page !== pagination.page) {
      dispatch(setPagination({ ...pagination, page }));
    }
  };

export const selectProductsState = (state: RootState) => state.products;
export const selectProducts = (state: RootState) => state.products.value;
export const selectIsFetchingProducts = (state: RootState) => state.products.isFetching;
export const selectedProduct = (state: RootState) => state.products.selectedProduct;

export const selectSort = (state: RootState) => state.products.sort;
export const selectFilter = (state: RootState) => state.products.filter;
export const selectPagination = (state: RootState) => state.products.pagination;
export const selectPaginationLimit = (state: RootState) => state.products.pagination.limit;
export const selectRegionFilter = (state: RootState) => state.products.regionFilter;
export const selectCountryFilter = (state: RootState) => state.products.countryFilter;

const selectFilteredProducts = createSelector(
  selectProducts,
  selectRegionFilter,
  selectCountryFilter,
  selectFilter,
  selectUserCountryCodes,
  (products, regionFilter, countryFilter, filter, countryCodes) => {
    return products
      .filter(p => !regionFilter || productContainsCountryCodes(p, getCountryCodesByRegion(regionFilter)))
      .filter(p => !countryFilter?.code || productContainsCountryCodes(p, [countryFilter.code]))
      .filter(p => productContainsString(p, filter))
      .filter(p => productContainsCountryCodes(p, countryCodes));
  }
);

const selectFilteredAndSortedProducts = createSelector(selectFilteredProducts, selectSort, (filteredProducts, sort) => {
  return sort.sortOrder === 'descending'
    ? _.sortBy(filteredProducts, [sort.sortColumn]).reverse()
    : _.sortBy(filteredProducts, [sort.sortColumn]);
});

export const selectFilteredAndSortedAndPaginatedProducts = createSelector(
  selectFilteredAndSortedProducts,
  selectPagination,

  (sortedData, pagination) => {
    const startIndex = pagination.page * pagination.limit - pagination.limit;
    const endIndex = startIndex + pagination.limit;
    return sortedData?.slice(startIndex, endIndex);
  }
);

export const selectTotalPagesAndCount = createSelector(
  selectFilteredProducts,
  selectPaginationLimit,
  (filteredData, paginationLimit) => {
    return {
      totalPages: Math.ceil((filteredData?.length || 0) / paginationLimit),
      totalCount: filteredData?.length || 0,
    };
  }
);

export default productsSlice.reducer;
