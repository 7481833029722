import { css } from '@emotion/react';

const productName = css`
  font-size: 1.5rem !important;
  font-weight: 500;
  padding: 0.5em !important;
  &&&& a {
    color: var(--jbmp-primary-color);
  }
`;

const date = css`
  font-size: 0.8rem;
  font-weight: 300;
  padding-left: 0.5833em;
`;

const orderDate = css`
  font-size: 0.8rem;
  font-weight: 300;
`;

const text = css`
  padding: 0.5833em;
  text-align: left;
`;

const attachments = css`
  && {
    background-color: white;
    border-radius: 10px;
    padding: 10px 0 10px;
    margin: 5px;
  }
  .no-file-label {
    margin: auto;
  }
`;

const orderPriceContainer = css`
  @media (min-width: 832px) {
    border-left: 2px solid var(--jbmp-blue-10);
    padding-left: 8px;
  }
`;

export default {
  date,
  text,
  productName,
  orderDate,
  attachments,
  orderPriceContainer,
};
