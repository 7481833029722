/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { Button, Icon, Label, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import style from './viewProduct.style';
import { Product } from '../features/jobBoards/model/product';
import { ViewPrice } from './ViewPrice';
import { checkRoles, ROLE } from '../features/auth/model/user';
import { selectUser } from '../features/auth/store/userSlice';
import { addProductToBasket } from '../features/basket/store/basketSlice';
import { AddToBasket } from '../features/basket/components/AddToBasket';
import { FilesDownload } from './FilesDownload';
import { JOB_BOARD_STATUS } from '../features/jobBoards/model/jobBoard';
import { isProductFinalPriceValid } from './utils';
import { AddToBasketItem } from '../features/basket/model/basket';
import { useAppDispatch } from '../core/coreHooks';

interface ViewProductProps {
  product: Product;
  jobBoardStatus: JOB_BOARD_STATUS;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const ViewProduct = ({ product, onEdit, onDelete, jobBoardStatus }: ViewProductProps): JSX.Element => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const isBroker = useCallback(() => checkRoles(user, [ROLE.BROKER]), [user]);
  const isRecruiter = useCallback(() => checkRoles(user, [ROLE.RECRUITER]), [user]);
  const dispatch = useAppDispatch();

  const onAddToBasket = (items: AddToBasketItem[]) => {
    dispatch(addProductToBasket(product, user.brands, items));
  };

  const jobBoardIsActive = () => {
    return jobBoardStatus === JOB_BOARD_STATUS.ACTIVE;
  };

  return (
    <div css={style.form} data-testid='create-product'>
      <Flex flexWrap='wrap' flex={1} overflow='auto' p='15px'>
        <Box width={[1, 1 / 2, 2 / 3]}>
          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Flex flexWrap='wrap'>
                  <Box width={1}>
                    <Label css={style.label}>{t('product.columns.name')}</Label>
                  </Box>
                  <Box width={1}>
                    <p css={style.text}>{product.name}</p>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.description')}</Label>
              </Box>
              <Box width={1}>
                <p css={style.description}>{product.description}</p>
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.target')}</Label>
              </Box>
              <Box width={1}>
                <p css={style.text}>{product.target}</p>
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.files')}</Label>
              </Box>
              <Box width={1}>
                <FilesDownload contents={product.contents} />
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.region')}</Label>
              </Box>
              <Box width={1}>
                <p css={style.text}>{product.region}</p>
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.country')}</Label>
              </Box>
              <Box width={1}>
                <p css={style.text}>{product.countryCodes.join(', ')}</p>
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.productType')}</Label>
              </Box>
              <Box width={1}>
                <p css={style.text}>{product.productType}</p>
              </Box>
            </Flex>
          </Box>

          {isProductFinalPriceValid(product) ? (
            <>
              <Box width={[1, 1 / 2]} p={1}>
                <Flex flexWrap='wrap'>
                  <Box width={1}>
                    <Label css={style.label}>{t('product.columns.finalPrice')}</Label>
                  </Box>
                  <Box width={1}>
                    <div css={style.text}>
                      <ViewPrice value={product.finalPrice?.value} currency={product.finalPrice?.currency} />
                    </div>
                  </Box>
                </Flex>
              </Box>
              <Box width={[1, 1 / 2]} p={1}>
                <Flex flexWrap='wrap'>
                  <Box width={1}>
                    <Label css={style.label}>{t('product.columns.validityEndDate')}</Label>
                  </Box>
                  <Box width={1}>
                    <div css={style.text}>
                      <Moment format='YYYY/MM/DD'>{product.validityEndDate}</Moment>
                    </div>
                  </Box>
                </Flex>
              </Box>
            </>
          ) : (
            <>
              <Box width={[1, 1 / 2]} p={1}>
                <Flex flexWrap='wrap'>
                  <Box width={1}>
                    <Label css={style.label}>{t('product.columns.marketPrice')}</Label>
                  </Box>
                  <Box width={1}>
                    <div css={style.text}>
                      <ViewPrice value={product.marketPrice} currency={product.marketPriceCurrency} />
                    </div>
                  </Box>
                </Flex>
              </Box>
              <Box width={[1, 1 / 2]} p={1}>
                <Flex flexWrap='wrap'>
                  <Box width={1}>
                    <Label css={style.label}>{t('product.columns.priceFrom')}</Label>
                  </Box>
                  <Box width={1}>
                    <div css={style.text}>
                      <ViewPrice value={product.priceFrom} currency={product.priceFromCurrency} />
                    </div>
                  </Box>
                </Flex>
              </Box>
              <Box width={[1, 1 / 2]} p={1}>
                <Flex flexWrap='wrap'>
                  <Box width={1}>
                    <Label css={style.label}>{t('product.columns.priceTo')}</Label>
                  </Box>
                  <Box width={1}>
                    <div css={style.text}>
                      <ViewPrice value={product.priceTo} currency={product.priceToCurrency} />
                    </div>
                  </Box>
                </Flex>
              </Box>
            </>
          )}
        </Box>
        <Box width={[1, 1 / 2, 1 / 3]}>
          <Flex justifyContent='flex-end' flexDirection='column'>
            {isRecruiter() && jobBoardIsActive() && (
              <Flex p={2}>
                <AddToBasket onAddToBasket={items => onAddToBasket(items)} product={product} />
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
      {isBroker() && onEdit && (
        <Box p='15px' marginLeft='auto' marginTop='auto' width='100%'>
          <Flex width='100%' flexWrap='wrap' justifyContent='flex-end'>
            <Button css={style.button} fluid onClick={() => onEdit()}>
              <Icon name='edit' />
              {t('jobBoard.edit')}
            </Button>

            <Modal
              trigger={
                <Button css={style.button} fluid>
                  <Icon name='trash alternate outline' />
                  {t('jobBoard.delete')}
                </Button>
              }
              header={t('confirmDelete')}
              content={t('product.deleteMessage')}
              actions={['Cancel', { key: 'done', content: 'Ok', onClick: () => onDelete && onDelete() }]}
            />
          </Flex>
        </Box>
      )}
    </div>
  );
};
