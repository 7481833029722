/** @jsxImportSource @emotion/react */
import React from 'react';
import { PrivacyModal } from '../features/auth/components/PrivacyModal';
import { Header } from './Header';
import style from './page.style';

interface PageProps {
  children?: React.ReactNode;
  title: string;
  backButton?: boolean;
}

export const Page: React.FunctionComponent<PageProps> = (props): JSX.Element => {
  const { children, title, backButton } = props;
  return (
    <>
      <Header title={title} backButton={backButton} />
      <PrivacyModal />
      <div css={style.container}>{children}</div>
    </>
  );
};
