/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import style from './productTypeFilter.style';
import { PRODUCT_TYPE } from '../features/jobBoards/model/product';

interface ProductTypeDropdownFilterProps {
  defaultValue?: string[] | string;
  changeProductTypeFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

export const ProductTypeFilter = ({
  defaultValue,
  changeProductTypeFilterHandler,
}: ProductTypeDropdownFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const productTypesOptions = Object.values(PRODUCT_TYPE).map(c => {
    return {
      key: c.toString(),
      text: c.toString(),
      value: c.toLowerCase(),
    };
  });

  const emptyDropdownField = { key: '', text: '-', value: '' };
  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2}>
          <div>{t('filters.productTypeFilterLabel')}</div>
        </Box>
        <Box p={2}>
          <Dropdown
            css={style.dropdown}
            placeholder='Select...'
            value={defaultValue}
            options={[emptyDropdownField, ...productTypesOptions]}
            onChange={(event, data) => changeProductTypeFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
