import { css } from '@emotion/react';

const formGroup = css`
  &&& {
    margin: 0;
  }
`;

const formField = css`
  &&&& {
    padding: 0;
  }
`;

export default {
  formGroup,
  formField,
};
