/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ViewProductContainer } from '../../../shared/ViewProductContainer';
import { AppDispatch } from '../../../core/store';

import {
  selectAndFetchProduct,
  selectedProduct,
  selectIsFetchingProducts,
  updateAndSelectProduct,
} from '../store/productsSlice';
import { selectUser } from '../../auth/store/userSlice';
import { Product } from '../../jobBoards/model/product';
import style from './productDetail.style';
import { useAppDispatch } from '../../../core/coreHooks';

export const ProductDetail = (): JSX.Element => {
  const { productId } = useParams<{ productId: string }>();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const product = useSelector(selectedProduct);
  const isFetchingProduct = useSelector(selectIsFetchingProducts);

  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(selectAndFetchProduct(productId || ''));
  }, [dispatch, user, productId]);

  const onUpdate = async (updatedProduct: Product) => {
    if (updatedProduct != null) {
      await dispatch(updateAndSelectProduct(updatedProduct));
    }
  };

  const onDelete = () => {
    navigate(`/products`);
  };

  return product ? (
    <ViewProductContainer
      jobBoardId={product.jobBoardId}
      jobBoardStatus={product.jobBoardStatus}
      product={product}
      onDelete={() => onDelete()}
      onUpdate={updatedProduct => onUpdate(updatedProduct)}
    />
  ) : !isFetchingProduct ? (
    <div css={style.deletedProductContainer}>
      <div>{t('product.deleted')}</div>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};
