import { getProductCountryCodes, Product, toProduct } from './product';
import { JobBoard, toJobBoard } from './jobBoard';

export interface JobBoardDetail extends JobBoard {
  products: Product[];
  countryCodes: string[];
}

export const toJobBoardDetail = (o: Record<string, unknown>): JobBoardDetail => {
  const products = (o.products as Record<string, unknown>[]).map(toProduct) as Product[];
  return {
    ...toJobBoard(o),
    products,
    countryCodes: products.flatMap(p => {
      return getProductCountryCodes(p);
    }),
  };
};
