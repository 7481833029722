import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../shared/global.style';
import fieldStyle from './field.style';

const picturesButtons = css`
  background-color: var(--jbmp-white-color);
  padding: 10px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  input {
    opacity: 0;
  }
`;

const picturesButton = css`
  ${fieldStyle.editIcon}
  &&& {
    width: 40px;
    height: 40px;
    position: relative;
    font-size: 1.429rem;
    cursor: pointer;
  }
`;

const picturesContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const picturesCarouselContainer = css`
  display: flex;
  background-color: var(--jbmp-white-color);
`;

const pictureCarouselHeader = css`
  ${globalStyle.subtitleNormal}
  && {
    padding: 0 10px;
    background-color: var(--jbmp-blue-20);
    display: flex;
    align-items: center;
  }
`;

const pictureCarousel = css`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--jbmp-white-color);
  padding: 5px;
  min-height: 50px;
`;

const pictureCarouselItem = css`
  && {
    width: 130px;
    height: 130px;
    overflow: hidden;
    background-color: var(--jbmp-primary-color-20);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 1px;
    position: relative;
    cursor: pointer;
    margin: 5px;
    align-items: center;
    justify-content: center;
`;

const pictureCarouselImageContainer = css`
  && { 
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--jbmp-primary-color);
    img {
      width: 100%;
      height: auto;
    }
`;

const pictureCarouselItemDeleteIcon = css`
  ${fieldStyle.editIcon};

  &&& {
    ${globalStyle.secondaryButton};
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: 0;
    padding: 5px;
    position: relative;
    margin: 5px;
  }
`;

const previewContainer = css`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--jbmp-primary-color);
    padding-bottom: 50px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const fileInput = (content: string): SerializedStyles => {
  return css`
    width: 40px;
    height: 40px;
    background-color: var(--jbmp-white-color);
    color: var(--jbmp-primary-color-80);
    border: solid 1px var(--jbmp-primary-color-40);
    border-radius: 4px;
    padding: 0;
    display: flex;
    font-size: 1.429rem;
    align-items: center;
    justify-content: center;
    position: relative;
    :hover {
      color: var(--jbmp-primary-color);
      border: solid 1px var(--jbmp-primary-color-60);
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
    }
    input::-webkit-file-upload-button {
      visibility: hidden;
    }
    input::file-selector-button {
      visibility: hidden;
    }
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '${content}';
      outline: none;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  `;
};

export default {
  picturesContainer,
  picturesCarouselContainer,
  pictureCarousel,
  pictureCarouselItem,
  pictureCarouselImageContainer,
  pictureCarouselItemDeleteIcon,
  previewContainer,
  pictureCarouselHeader,
  picturesButtons,
  picturesButton,
  fileInput,
};
