import { css, keyframes } from '@emotion/react';
import style from './global.style';

const button = css`
  &&& {
    color: var(--jbmp-white-color);
    background-color: var(--jbmp-primary-color);
    padding: 0 10px;

    &&:hover,
    &&:focus {
      color: var(--jbmp-primary-color-50);
      background-color: var(--jbmp-primary-color);
    }
  }
`;

const buttonIcon = css`
  && {
    font-size: 1.3rem;
  }
`;

const openLargePageSidebarAnimation = keyframes`
  0% {
    width: 45px;
  }
  100% {
    width: 280px;
  }
`;

const openSidebarAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 280px;
  }
`;

const closeLargePageSidebarAnimation = keyframes`
  0% {
    width: 280px;
  }
  100% {
    width: 45px;
  }
`;

const closeSidebarAnimation = keyframes`
  0% {
    width: 280px;
  }
  100% {
    width: 0;
  }
`;
const sidebar = css`
  &&&& {
    top: 45px;
    height: calc(100% - 45px) !important;
    display: flex;
    background-color: var(--jbmp-primary-color-90);
    width: max-content;
    z-index: 1901; // for overlay popup validation
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sidebarLargePageWithCloseAnimation = (animationMenu: boolean) => {
  if (animationMenu) {
    return css`
      ${sidebar};
      animation: ${closeLargePageSidebarAnimation} 0.3s ease both;
    `;
  }
  return css`
    ${sidebar};
  `;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sidebarWithCloseAnimation = (animationMenu: boolean) => {
  if (animationMenu) {
    return css`
      ${sidebar};
      animation: ${closeSidebarAnimation} 0.3s ease both;
    `;
  }
  return css`
    ${sidebar};
  `;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sidebarLargePageWithOpenAnimation = (animationMenu: boolean) => {
  if (animationMenu) {
    return css`
      ${sidebar};
      overflow: hidden;
      animation: ${openLargePageSidebarAnimation} 0.3s ease both;
    `;
  }
  return css`
    ${sidebar};
    overflow: hidden;
  `;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sidebarWithOpenAnimation = (animationMenu: boolean) => {
  if (animationMenu) {
    return css`
      ${sidebar};
      overflow: hidden;
      animation: ${openSidebarAnimation} 0.3s ease both;
    `;
  }
  return css`
    ${sidebar};
    overflow: hidden;
  `;
};

const popup = css`
  ${style.subtitleSmall}
  &&&& {
    padding: 5px 10px;
    background-color: var(--jbmp-primary-color-90);
    border-color: var(--jbmp-primary-color-90);
    color: var(--jbmp-white-color);
    :before {
      background: var(--jbmp-primary-color-90);
    }
  }
`;

const menuItem = css`
  &&& {
    ${style.bodySmall}
  }
  &&&.active.item {
    background-color: var(--jbmp-primary-color-40);
    > div {
      color: var(--jbmp-primary-color);
      font-weight: 500;
      text-decoration: underline;
    }
    > i {
      color: var(--jbmp-primary-color);
    }
  }

  &&&&& {
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    width: 100%;
    padding: 0.785714rem 5px;
    > div {
      margin-left: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > i {
      margin: 0 0.35714286rem 0 0;
      display: flex;
      min-width: 30px;
      justify-content: center;
    }
    :hover {
      background-color: var(--jbmp-primary-color-60);
      color: var(--jbmp-white-color);
    }
  }
`;

const menuGroup = css`
  //margin-bottom: 10px;
`;

const menuGroupHeader = css`
  ${style.bodySmall}
  padding: 10px 10px 1px;
  color: var(--jbmp-primary-color-20);
  border-bottom: solid 1px var(--jbmp-primary-color);
`;

export default {
  button,
  buttonIcon,
  sidebar,
  sidebarLargePageWithCloseAnimation,
  sidebarLargePageWithOpenAnimation,
  sidebarWithOpenAnimation,
  sidebarWithCloseAnimation,
  menuItem,
  menuGroup,
  menuGroupHeader,
  popup,
};
