import { css } from '@emotion/react';
import style from './global.style';

const filter = css`
  ${style.filter}
`;

const dropdown = css`
  ${style.filterDropdown}
`;

export default {
  filter,
  dropdown,
};
