/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent } from 'react';
import { Button, Checkbox, Dropdown, DropdownProps, Form, Grid, Input, Label, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { ManagedUserCreateRequest, USER_TYPE } from '../model/managedUser';
import style from './createManagedUser.style';
import { ROLE } from '../../auth/model/user';
import { brandsOptions } from './UpdateManagedUser';

interface CreateManagedUserProps {
  onSave: (request: ManagedUserCreateRequest) => void;
}

export const CreateManagedUser = ({ onSave }: CreateManagedUserProps): JSX.Element => {
  const [email, setEmail] = React.useState<string | null>(null);
  const [type, setType] = React.useState<USER_TYPE>(USER_TYPE.KERING);
  const [firstName, setFirstName] = React.useState<string | null>(null);
  const [lastName, setLastName] = React.useState<string | null>(null);
  const [roles, setRoles] = React.useState<string[]>([]);
  const [brands, setBrands] = React.useState<string[]>([]);

  const { t } = useTranslation();

  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onChangeType = (value: USER_TYPE) => {
    setType(value);
  };

  const onChangeRoles = (role: ROLE, value: boolean) => {
    let newRoles = [...roles];
    if (value) {
      if (!newRoles.includes(role)) {
        newRoles.push(role);
      }
    } else if (newRoles.includes(role)) {
      newRoles = newRoles.filter(r => r !== role);
    }
    setRoles(newRoles);
  };

  const onBrandsChange = (event: SyntheticEvent, data: DropdownProps) => {
    setBrands(data.value as string[]);
  };

  const onSubmit = async () => {
    try {
      onSave({
        email: email as string,
        type,
        firstName: type === USER_TYPE.EXTERNAL ? firstName : null,
        lastName: type === USER_TYPE.EXTERNAL ? lastName : null,
        roles,
        brands,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div css={style.form}>
      <Form>
        <Grid data-testid='create-managed-user'>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Label css={style.label}>{t('managedUser.type')}</Label>
              <Radio
                style={{ paddingRight: '5px' }}
                label='KERING'
                name='radioGroup'
                value='KERING'
                checked={type === 'KERING'}
                onChange={() => onChangeType(USER_TYPE.KERING)}
              />
              <Radio
                style={{ paddingRight: '5px' }}
                label='EXTERNAL'
                name='radioGroup'
                value='EXTERNAL'
                checked={type === 'EXTERNAL'}
                onChange={() => onChangeType(USER_TYPE.EXTERNAL)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <Label css={style.label}>{t('managedUser.roles')}</Label>
                <div css={style.rolesContainer}>
                  <Checkbox
                    style={{ padding: '5px' }}
                    checked={roles.includes(ROLE.ADMIN)}
                    label='Admin'
                    onChange={(e, { checked }) => onChangeRoles(ROLE.ADMIN, !!checked)}
                  />
                  <Checkbox
                    style={{ padding: '5px' }}
                    checked={roles.includes(ROLE.BROKER)}
                    label='Broker'
                    onChange={(e, { checked }) => onChangeRoles(ROLE.BROKER, !!checked)}
                  />
                  <Checkbox
                    style={{ padding: '5px' }}
                    checked={roles.includes(ROLE.RECRUITER)}
                    label='Recruiter'
                    onChange={(e, { checked }) => onChangeRoles(ROLE.RECRUITER, !!checked)}
                  />
                  <Checkbox
                    style={{ padding: '5px' }}
                    checked={roles.includes(ROLE.RECRUITER_SUPERVISOR)}
                    label='Recruiter Supervisor'
                    onChange={(e, { checked }) => onChangeRoles(ROLE.RECRUITER_SUPERVISOR, !!checked)}
                  />
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Label css={style.label}>{t('managedUser.email')}</Label>
                <Input
                  fluid
                  value={email}
                  type='text'
                  data-testid='managed-user-name-text-input'
                  onChange={event => onChangeEmail(event)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Label css={style.label}>{t('managedUser.brands')}</Label>
                <Dropdown
                  style={{ width: '100%' }}
                  placeholder={t('managedUser.brands')}
                  value={brands}
                  multiple
                  selection
                  search
                  options={brandsOptions}
                  onChange={(event, data) => onBrandsChange(event, data)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          {type === 'EXTERNAL' && (
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <Label css={style.label}>{t('managedUser.firstName')}</Label>
                  <Input
                    fluid
                    value={firstName}
                    type='text'
                    data-testid='managed-user-name-text-input'
                    onChange={event => onChangeFirstName(event)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <Label css={style.label}>{t('managedUser.lastName')}</Label>
                  <Input
                    fluid
                    value={lastName}
                    type='text'
                    data-testid='managed-user-name-text-input'
                    onChange={event => onChangeLastName(event)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        <div css={style.button}>
          <Button
            fluid
            primary
            data-testid='managed-user-save-button'
            onClick={onSubmit}
            disabled={
              email == null ||
              email === '' ||
              !validator.isEmail(email) ||
              (type === 'EXTERNAL' && (firstName == null || firstName === '')) ||
              (type === 'EXTERNAL' && (lastName == null || lastName === ''))
            }>
            {t('managedUser.create')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
