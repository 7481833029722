import { css } from '@emotion/react';

const container = css`
  &&& {
    background-color: var(--jbmp-blue-10);
    border-radius: 5px;
    flex: 1;
    @media (min-width: 640px) {
      max-height: calc(100vh - 160px);
    }
  }
`;

const containerHeader = css`
  &&& {
    gap: 5px;
  }
`;

const addToBasketItemContainer = css`
  &&& {
    overflow: auto;
  }
`;
const addToBasketItem = css`
  background-color: var(--jbmp-blue-20);
  padding: 2px 10px;
  border-radius: 5px;
  &&& {
    margin-bottom: 10px;
  }
`;

const flex = css`
  display: flex;
  flex-wrap: wrap;
`;

const buttonsContainer = css`
  &&& {
    margin-top: 10px;
  }
`;

const button = css`
  padding: 10px;
`;

const editLink = css`
  text-align: right;
  cursor: pointer;
`;

const label = css`
  &&& {
    font-size: 1em !important;
    background-color: transparent !important;
    flex: 20%;
    margin-top: 3px !important;
    white-space: nowrap;
    max-width: 100%;
  }
`;

const dropdown = css`
  margin-bottom: 15px;
  flex: 80%;
  border-radius: 4px;
  &&&&&.error {
    box-shadow: 0 0 0 0.5px var(--jbmp-error-light);
    .default {
      color: var(--jbmp-error);
    }
  }
`;

export default {
  container,
  containerHeader,
  addToBasketItemContainer,
  addToBasketItem,
  buttonsContainer,
  button,
  editLink,
  label,
  dropdown,
  flex,
};
