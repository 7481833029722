/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ContentRef, FiletType } from '../features/jobBoards/model/product';
import api from '../features/products/utils/api';
import style from './fileUpload.style';

interface FileUploadProps {
  onFileUpload: (content: ContentRef) => void;
  id: string;
  buttonLabel?: string;
  fileType?: FiletType;
}

export const FileUpload = ({ onFileUpload, id, buttonLabel, fileType }: FileUploadProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event != null && event.target != null && event.target.files != null && event.target.files.length > 0) {
      setLoading(true);
      await onFileUploadHandler(event.target.files[0]).finally(() => setLoading(false));
    }
  };

  const onFileUploadHandler = async (e: File) => {
    const formData = new FormData();
    formData.append('file', e, e.name);
    const content: ContentRef = { ...(await api.uploadFile(formData)), ...(fileType && { fileType }) };
    onFileUpload(content);
  };

  const inputId = `file-upload-${id}-${fileType || ''}`;

  return (
    <div css={style.container}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label css={style.button} htmlFor={inputId}>
        <Icon name='upload' />
        {buttonLabel || t('fileUpload')} {loading && <div className='ui mini active inline loader' />}
      </label>
      <Input style={{ display: 'none' }} id={inputId} type='file' onChange={event => onFileChange(event)} />
    </div>
  );
};
