/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from '../core/store';

import { fetchJobBoards, selectActiveJobBoards } from '../features/jobBoards/store/jobBoardsSlice';
import style from './jobBoardFilter.style';
import { useAppDispatch } from '../core/coreHooks';

interface JobBoardDropdownFilterProps {
  defaultValue?: string[] | string;
  changeJBFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

export const JobBoardFilter = ({ defaultValue, changeJBFilterHandler }: JobBoardDropdownFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const jobBoards = useSelector(selectActiveJobBoards);

  useEffect(() => {
    dispatch(fetchJobBoards());
  }, [dispatch]);

  const jobBoardsOptions = jobBoards.map(c => {
    return {
      key: c.id,
      text: c.name,
      value: c.id,
    };
  });

  const emptyDropdownField = { key: '', text: '-', value: '' };
  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2} width='max-content'>
          <div>{t('filters.jobBoardFilterLabel')}</div>
        </Box>
        <Box p={2} flex={1}>
          <Dropdown
            search
            css={style.dropdown}
            placeholder={t('filters.searchPlaceholder')}
            value={defaultValue}
            options={[emptyDropdownField, ...jobBoardsOptions]}
            onChange={(event, data) => changeJBFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
