import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
// eslint-disable-next-line import/no-cycle
import api from '../utils/api';
import { Order } from '../model/order';
import { toastService } from '../../../core/services/toastService';
// eslint-disable-next-line import/no-cycle
import { ALL_STATUSES, THREE_MONTHS } from '../../brokerOrders/store/brokerOrderSlice';
import { Company } from '../../companyProfiles/model/companyProfile';
import { checkValueInclude } from '../../../shared/utils';
import { Country, getRegionByCountry, REGION } from '../../jobBoards/model/countries';

interface OrderSliceState {
  value: Order[];
  selectedOrder: Order | null;
  isFetching: boolean;
  error: string;
  periodFilter: string;
  statusFilter: string;
  countryFilter: Country | null;
  jobBoardFilter: string;
  brandFilter: string;
  fromOrderDate: string | null;
  toOrderDate: string | null;
  productTypeFilter: string;
}

const initialState = {
  value: [],
  selectedOrder: null,
  isFetching: false,
  error: '',
  periodFilter: THREE_MONTHS,
  statusFilter: ALL_STATUSES,
  countryFilter: null,
  jobBoardFilter: '',
  brandFilter: '',
  fromOrderDate: null,
  toOrderDate: null,
  productTypeFilter: '',
} as OrderSliceState;

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    startFetch: (state: Draft<OrderSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<OrderSliceState>, action: PayloadAction<Order[]>) => {
      action.payload.sort((a: Order, b: Order) => {
        const ad = new Date(a.lastUpdate);
        const bd = new Date(b.lastUpdate);
        return ad.getTime() > bd.getTime() ? -1 : 1;
      });
      return {
        ...state,
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    updateOrder: (state: Draft<OrderSliceState>, action: PayloadAction<Order>) => {
      const ors = JSON.parse(JSON.stringify(state.value));
      const orIndex = ors.findIndex((i: Order) => i.id === action.payload.id);
      if (orIndex > -1) {
        ors[orIndex] = action.payload;
      }
      return {
        ...state,
        value: ors,
      };
    },
    changePeriodFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      periodFilter: action.payload,
    }),
    changeStatusFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      statusFilter: action.payload,
    }),
    changeCountryFilter: (state: Draft<OrderSliceState>, action: PayloadAction<Country | null>) => ({
      ...state,
      countryFilter: action.payload,
    }),
    changeJobBoardFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      jobBoardFilter: action.payload,
    }),
    changeBrandFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      brandFilter: action.payload,
    }),
    changeFromOrderDateFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string | null>) => ({
      ...state,
      fromOrderDate: action.payload,
    }),
    changeToOrderDateFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string | null>) => ({
      ...state,
      toOrderDate: action.payload,
    }),
    changeProductTypeFilter: (state: Draft<OrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      productTypeFilter: action.payload,
    }),
    selectOrder: (state: Draft<OrderSliceState>, action: PayloadAction<Order>) => ({
      ...state,
      selectedOrder: action.payload,
    }),
    httpError: (state: Draft<OrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  updateOrder,
  changePeriodFilter,
  changeStatusFilter,
  changeCountryFilter,
  changeJobBoardFilter,
  changeBrandFilter,
  changeFromOrderDateFilter,
  changeToOrderDateFilter,
  changeProductTypeFilter,
  selectOrder,
} = orderSlice.actions;

export const selectOrders = (state: RootState): Order[] => state.orders.value;
export const selectSelectedOrder = (state: RootState): Order | null => state.orders.selectedOrder;
export const selectIsFetchingOrders = (state: RootState): boolean => state.orders.isFetching;
export const selectPeriodFilter = (state: RootState): string => state.orders.periodFilter;
export const selectStatusFilter = (state: RootState): string => state.orders.statusFilter;
export const selectCountryFilter = (state: RootState): Country | null => state.orders.countryFilter;
export const selectJobBoardFilter = (state: RootState): string => state.orders.jobBoardFilter;
export const selectBrandFilter = (state: RootState): string => state.orders.brandFilter;
export const selectFromOrderDateFilter = (state: RootState): string | null => state.orders.fromOrderDate;
export const selectToOrderDateFilter = (state: RootState): string | null => state.orders.toOrderDate;
export const selectProductTypeFilter = (state: RootState): string => state.orders.productTypeFilter;

export const fetchOrders = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const orders = await api.getOrders(state().orders.periodFilter);
    dispatch(finishFetch(orders));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

const checkCountryFilter = (countryFilter: Country | null, countryCode: string, orderRegion: REGION) => {
  return (
    countryFilter == null ||
    countryFilter.code === '' ||
    countryCode === countryFilter.code ||
    (countryCode == null && (getRegionByCountry(countryFilter?.code) === orderRegion || orderRegion === REGION.GLOBAL))
  );
};

export const selectFilteredOrders = createSelector(
  selectOrders,
  selectStatusFilter,
  selectCountryFilter,
  selectJobBoardFilter,
  selectBrandFilter,
  selectFromOrderDateFilter,
  selectToOrderDateFilter,
  selectProductTypeFilter,
  (orders, statusFilter, countryFilter, jobBoardFilter, brandFilter, fromDate, toDate, productTypeFilter) => {
    return orders.filter(
      order =>
        (statusFilter === ALL_STATUSES || order.brokerOrderStatus === statusFilter) &&
        checkCountryFilter(countryFilter, order.country, order.product.region) &&
        checkValueInclude(order.product.jobBoardId, jobBoardFilter) &&
        (brandFilter == null || brandFilter === '' || order.brands.includes(brandFilter)) &&
        (fromDate === null || moment(order.creationDate).isSameOrAfter(moment(fromDate).startOf('day'))) &&
        (toDate === null || moment(order.creationDate).isSameOrBefore(moment(toDate).endOf('day'))) &&
        (checkValueInclude(order.product.productType, productTypeFilter) ||
          (productTypeFilter === '' && order.product.productType == null))
    );
  }
);

export const fetchOrder =
  (orderId: string): AppThunk =>
  async (dispatch, state) => {
    try {
      const order = await api.getOrder(orderId);
      dispatch(selectOrder(order));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const acceptPrice =
  (id: string, quantity: number): AppThunk =>
      async (dispatch, state) => {
    try {
      const order = await api.acceptPrice(id, quantity);
      await dispatch(updateOrder(order));
      if(state().orders.value.filter(o => o.brokerOrderId === order.brokerOrderId).length > 1) {
        dispatch(fetchOrders())
      }
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const rejectPrice =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      const order = await api.rejectPrice(id);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const changeQuantity =
  (id: string, quantity: number): AppThunk =>
  async dispatch => {
    try {
      const order = await api.changeQuantity(id, quantity);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateCompany =
  (id: string, cp: Company): AppThunk =>
  async dispatch => {
    try {
      const order = await api.updateCompany(id, cp);
      dispatch(updateOrder(order));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export default orderSlice.reducer;
