import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const dimmer = css`
  && .content {
    max-width: 1000px;
    max-height: 100%;
  }
`;

const modal = css`
  width: 100%;
  height: 100%;
  background-color: var(--jbmp-white-color);
  color: var(--jbmp-primary-color);
`;

const body = css`
  overflow: auto;
  padding: 20px;
  height: calc(100% - 60px);
  ${style.bodySmall};
  p {
    text-align: justify;
  }
`;

const actions = css`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--jbmp-primary-color-40);
  padding-right: 5px;
`;

export default {
  modal,
  body,
  actions,
  dimmer,
};
