import { css } from '@emotion/react';

const priceToLabel = css`
  padding-right: 0.7rem;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  text-align: right;
`;

const priceTo = css`
  text-align: right;
  font-size: 1.6rem;
  font-weight: 500;
  overflow-wrap: break-word;
  overflow: hidden;
`;

const date = css`
  text-align: right;
  font-size: 0.8rem;
  font-weight: 300;
`;

const quantity = css`
  justify-content: flex-end;
  && > input {
    //text-align: right;
    max-width: 120px;
  }
`;

export default {
  priceToLabel,
  priceTo,
  quantity,
  date,
};
