import { css } from '@emotion/react';
/*
import globalStyle from '../../../shared/global.style';
*/

const card = css`
  && {
    height: 100%;
    background-color: var(--jbmp-primary-color-10);
    margin: 0;
  }
`;

const cardContent = css`
  &&& {
    display: flex;
    padding: 0;
  }
`;

const contentContainer = css`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
`;

const title = css`
  padding: 15px 0px 25px;
  margin: 0;
`;

const button = css`
  width: 200px;
`;

const authenticationsContainer = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const authCard = css`
  && {
    margin: 0;
    flex: 1;
    max-width: 450px;
    margin-bottom: 10px;
    height: 100%;
    margin-right: 7px;
    margin-left: 7px;
  }
`;

const corporateAuthCard = css`
  ${authCard};
`;

const authCardTitle = css`
  border-bottom: 1px solid var(--jbmp-primary-color-40);
`;

const authCardContent = css`
  max-width: 600px;
  padding: 10px 0 20px 0;
`;

const userLoginFormContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0 0 0;
  > Input {
    padding-bottom: 10px;
  }
`;

const logInButton = css``;

const input = css`
  && {
    input:focus {
      border-color: var(--jbmp-primary-color-60);
    }
  }
`;

const passwordInput = css`
  ${input};
  padding: 15px 0;
`;

const emailInput = css`
  ${input};
`;

const forgotPassword = css`
  margin-left: auto;
  padding-bottom: 15px;
  text-decoration: underline;
`;

export default {
  contentContainer,
  title,
  button,
  cardContent,
  card,
  authenticationsContainer,
  authCard,
  corporateAuthCard,
  authCardTitle,
  authCardContent,
  userLoginFormContainer,
  logInButton,
  passwordInput,
  emailInput,
  forgotPassword,
};
