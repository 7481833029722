import { API_URL } from '../../../constants';

const AUTH_URL = `${API_URL}/auth`;

const paths = {
  getTokenKeringUser: (): string => `${AUTH_URL}/get-kering-token`,
  getTokenExternalUser: (): string => `${AUTH_URL}/get-external-token`,
  getUser: (): string => `${AUTH_URL}/me`,
  refreshToken: (): string => `${AUTH_URL}/refresh-token`,
  requestResetPassword: (): string => `${AUTH_URL}/request-password-reset`,
  updatePassword: (): string => `${AUTH_URL}/update-password`,
  putUserPolicy: (): string => `${AUTH_URL}/accept-policies/`,
  putUserRegionsAndCountries: (id: string): string => `${API_URL}/users/${id}/update-regions-and-countries`,
};

export default paths;
