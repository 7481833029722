/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import style from './brandFilter.style';

interface BrandDropdownOption {
  key: string;
  text: string;
  value: string;
}

interface BrandDropdownFilterProps {
  defaultValue?: string[] | string;
  options: BrandDropdownOption[];
  changeBrandFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

export const BrandFilter = ({
  defaultValue,
  options,
  changeBrandFilterHandler,
}: BrandDropdownFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const emptyDropdownField = { key: '', text: '-', value: '' };

  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2} width='max-content'>
          <div>{t('filters.brandFilterLabel')}</div>
        </Box>
        <Box p={2} flex={1}>
          <Dropdown
            search
            css={style.dropdown}
            placeholder={t('filters.searchPlaceholder')}
            value={defaultValue}
            options={[emptyDropdownField, ...options]}
            onChange={(event, data) => changeBrandFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
