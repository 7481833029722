import { BRANDS } from '../../common/brand';

export enum USER_TYPE {
  KERING = 'KERING',
  EXTERNAL = 'EXTERNAL',
}

export interface ManagedUser {
  id: string;
  email: string;
  roles: string[];
  policiesAccepted: boolean;
  firstName: string;
  lastName: string;
  type: USER_TYPE;
  brands: string[];
}

export const toManagedUser = (o: Record<string, unknown>): ManagedUser => {
  return {
    id: o.id as string,
    email: o.email as string,
    roles: o.roles as string[],
    policiesAccepted: o.policiesAccepted as boolean,
    firstName: o.firstName as string,
    lastName: o.lastName as string,
    type: o.type as USER_TYPE,
    brands: (o.brands as string[]).sort(),
  };
};

export const toManagedUserUpdateRequest = (user: ManagedUser): ManagedUserUpdateRequest => {
  return {
    policiesAccepted: user.policiesAccepted,
    firstName: user.firstName,
    lastName: user.lastName,
    roles: user.roles,
    brands: user.brands.filter(ub => BRANDS.map(b => b.key).includes(ub)),
  };
};

export interface ManagedUserUpdateRequest {
  policiesAccepted: boolean;
  firstName: string;
  lastName: string;
  roles: string[];
  brands: string[] | null;
}

export interface ManagedUserCreateRequest {
  email: string;
  type: USER_TYPE;
  roles: string[];
  firstName: string | null;
  lastName: string | null;
  brands: string[] | null;
}
