import { css } from '@emotion/react';

const form = css`
  color: black;
  font-size: 1.2rem;
  background-color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const label = css`
  float: left;
  background-color: white !important;
`;

const text = css`
  float: left;
  padding: 0.5833em;
  text-align: left;
`;

const button = css`
  &&&& {
    padding: 10px;
    width: min(200px, 40%);
    margin-bottom: 5px;
  }
`;

const description = css`
  ${text};
  overflow-wrap: break-word;
  white-space: break-spaces;
`;

export default {
  form,
  label,
  text,
  button,
  description,
};
