/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from 'semantic-ui-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import style from './header.style';
import { UserMenu } from './UserMenu';
import { fetchBasket, selectBasket } from '../features/basket/store/basketSlice';
import { AppDispatch } from '../core/store';
import { checkRoles, ROLE, User } from '../features/auth/model/user';
import { selectUser } from '../features/auth/store/userSlice';
import { MainMenu } from './MainMenu';
import { useAppDispatch } from '../core/coreHooks';

interface HeaderProps {
  title: string;
  backButton?: boolean;
}

export const Header = ({ title, backButton = false }: HeaderProps): JSX.Element => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const goBack = useCallback(() => {
    return state != null && typeof state === 'object' && (state as Record<string, unknown>).pathname != null
      ? navigate((state as Record<string, unknown>).pathname as string, { replace: true })
      : navigate('/');
  }, [navigate, state]);
  const user = useSelector(selectUser) as User;

  const dispatch: AppDispatch = useAppDispatch();
  const headerRef = useRef(null);

  const handleUserMenuOnClick = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const handleBasketMenuOnClick = () => {
    navigate('/basket');
  };

  useEffect(() => {
    dispatch(fetchBasket());
  }, [dispatch]);

  const basket = useSelector(selectBasket);

  return (
    <div css={style.container}>
      <div css={style.menuButtonContainer}>
        <MainMenu />
        {backButton && <Icon css={style.backIcon} name='arrow left' onClick={goBack} />}
      </div>
      <h5 css={style.title}>{title}</h5>
      {checkRoles(user, [ROLE.RECRUITER]) && (
        <div css={style.menuBasket} onClick={handleBasketMenuOnClick}>
          <Icon css={style.iconClass} size='large' name='shopping basket' />
          {basket && basket.items.length > 0 && (
            <div css={style.basketQuantity}>{basket && <span>{basket.items.length}</span>}</div>
          )}
        </div>
      )}
      <OutsideClickHandler onOutsideClick={handleUserMenuOnClick} disabled={!userMenuOpen}>
        <div ref={headerRef}>
          <Icon css={style.iconClass} size='large' name='user' onClick={handleUserMenuOnClick} />
          {userMenuOpen && headerRef.current != null && createPortal(<UserMenu />, headerRef.current)}
        </div>
      </OutsideClickHandler>
    </div>
  );
};
