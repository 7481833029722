import { css } from '@emotion/react';
// import style from "../../../shared/global.style";

const segmentContainer = css`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--jbmp-primary-color-10);
    border: none;
    box-shadow: none;
    margin: 0;
  }
`;

const productTableContainer = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const filterAndPageSizeContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 10px;
`;

const addButton = css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
  }
  @media (min-width: 800px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: unset;
      border-radius: 4px;
      :before {
        content: 'Add product';
        padding-right: 5px;
      }
    }
  }
`;

const totalCount = css`
  margin-right: auto;
  margin-left: 10px;
`;

const filter = css`
  padding: 8px;
`;

export default {
  segmentContainer,
  productTableContainer,
  filterAndPageSizeContainer,
  addButton,
  totalCount,
  filter,
};
