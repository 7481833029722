import { css } from '@emotion/react';
import { JOB_BOARD_STATUS } from '../features/jobBoards/model/jobBoard';

const scrollbar = css`
  *::-webkit-scrollbar {
    width: 10px;
  }
  // firefox
  * {
    scrollbar-width: thin;
  }
`;

const subtitleNormal = css`
  && {
    font-size: 1.143rem;
    letter-spacing: 0.007rem;
    line-height: 1.714rem;
    font-weight: 500;
  }
`;

const subtitleSmall = css`
  && {
    font-size: 1rem;
    letter-spacing: 0.007rem;
    line-height: 1.286rem;
    font-weight: 500;
  }
`;

const bodySmall = css`
  && {
    font-size: 1rem;
    letter-spacing: 0.007rem;
    line-height: 1.571rem;
    font-weight: normal;
  }
`;

const bodyNormal = css`
  && {
    font-size: 1.143rem;
    letter-spacing: 0.007rem;
    line-height: 1.714rem;
    font-weight: normal;
  }
`;

const primaryButton = css`
  && {
    color: var(--jbmp-white-color);
    background-color: var(--jbmp-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &&:hover,
    &&:focus {
      color: var(--jbmp-primary-color-40);
      background-color: var(--jbmp-primary-color);
    }
  }
`;

const secondaryButton = css`
  ${primaryButton};
  && {
    background-color: var(--jbmp-primary-color-80);
    &&:hover,
    &&:focus {
      color: var(--jbmp-primary-color-40);
      background-color: var(--jbmp-primary-color-80);
    }
  }
`;

const modalConfirm = css`
  && {
    max-width: 75%;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    > div {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;

const confirmButton = css`
  &&&& {
    background-color: var(--jbmp-primary-color);
  }
`;

const statusColor = (status: JOB_BOARD_STATUS | undefined): string => {
  switch (status) {
    case JOB_BOARD_STATUS.DRAFT:
      return `var(--jbmp-job-board-draft)`;
    case JOB_BOARD_STATUS.ACTIVE:
      return `var(--jbmp-job-board-active)`;
    default:
      return '';
  }
};

const filter = css`
  display: flex;
  height: max-content;
  width: 200px;
  min-width: 200px;
  max-width: 100%;
  align-items: center;
  border-bottom: solid 1px var(--jbmp-primary-color-60);
`;

const filterDropdown = css`
  &&&& {
    display: flex;
    align-items: center;
  }

  &&& .text {
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 20px);
    line-height: 20px;
    height: 20px;
  }
  &&& .dropdown.icon {
    margin-right: 0;
    margin-left: auto;
  }
`;

export default {
  subtitleNormal,
  subtitleSmall,
  bodySmall,
  bodyNormal,
  scrollbar,
  primaryButton,
  secondaryButton,
  modalConfirm,
  confirmButton,
  statusColor,
  filter,
  filterDropdown,
};
