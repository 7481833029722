/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Form, Grid, Input, Label, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../core/coreHooks';
import { createJobBoard } from '../store/jobBoardsSlice';
import style from './createJobBoard.style';
import { PicturesListView } from './PicturesListView';
import { PictureRef } from '../model/jobBoard';

interface CreateJobBoardProps {
  onSave?: () => void;
}

export const CreateJobBoard = ({ onSave = () => null }: CreateJobBoardProps): JSX.Element => {
  const [name, setName] = React.useState<string>('');
  const [about, setAbout] = React.useState<string>('');
  const [pic, setPic] = React.useState<PictureRef | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeAbout = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAbout(event.target.value);
  };

  const onSaveJobBoard = async () => {
    await dispatch(createJobBoard(name, about, pic ? pic.id : null));
    onSave();
  };

  return (
    <div css={style.form}>
      <Grid data-testid='create-job-board'>
        <Grid.Column floated='left'>
          <Form>
            <Form.Field>
              <Label css={style.label}>{t('jobBoard.name')}</Label>
              <Input
                fluid
                value={name}
                type='text'
                data-testid='job-board-name-text-input'
                onChange={event => onChangeName(event)}
              />
            </Form.Field>
            <Form.Field>
              <Label css={style.label}>{t('jobBoard.about')}</Label>
              <TextArea
                value={about}
                data-testid='job-board-about-text-area'
                onChange={event => onChangeAbout(event)}
              />
            </Form.Field>
            <Form.Field>
              <Label css={style.label}>{t('jobBoard.picture')}</Label>
              <PicturesListView
                pictures={pic != null ? [pic] : []}
                onDeletePicture={() => {
                  setPic(null);
                }}
                onAddPicture={picture => {
                  setPic(picture);
                }}
                editable
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
      <Button
        fluid
        primary
        data-testid='job-board-save-button'
        onClick={() => onSaveJobBoard()}
        disabled={name == null || name === '' || about == null || about === ''}>
        {t('jobBoard.create')}
      </Button>
    </div>
  );
};
