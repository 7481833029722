import axios, { AxiosResponse } from 'axios';
import paths from './paths';
import { toUser, User } from '../model/user';

const api = {
  getTokenKeringUser: async (
    tokenUrl: string,
    code: string | string[] | null | undefined,
    redirectUri: string,
    clientId: string
  ): Promise<AxiosResponse> =>
    axios({
      method: 'post',
      url: paths.getTokenKeringUser(),
      data: {
        code,
        grant_type: 'authorization_code',
      },
      headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': 'true',
      },
    }),
  getTokenExternalUser: async ({ username, password }: { username: string; password: string }): Promise<string> => {
    const response = await axios.post(paths.getTokenExternalUser(), { username, password });
    return response.data.access_token;
  },
  getUser: async (): Promise<User> => {
    const response = await axios.get(paths.getUser());
    return toUser(response.data);
  },
  refreshToken: async (): Promise<AxiosResponse> => {
    return axios.get(paths.refreshToken());
  },
  requestResetPassword: async (email: string): Promise<void> => {
    await axios.put(paths.requestResetPassword(), { email });
  },
  updatePassword: async (token: string, password: string): Promise<void> => {
    await axios.put(paths.updatePassword(), { token, password });
  },
  putUserPolicy: async (): Promise<User> => {
    const response = await axios.put(paths.putUserPolicy());
    return toUser(response.data);
  },
  putUserRegionsAndCountries: async (id: string, regions: string[], countryCodes: string[]): Promise<User> => {
    const response = await axios.put(paths.putUserRegionsAndCountries(id), { regions, countryCodes });
    return toUser(response.data);
  },
};

export default api;
