import { css } from '@emotion/react';

const inlineLabel = css`
  float: left;
  padding-left: 0.5833em;
  font-size: 0.85714286rem;
  color: rgba(0, 0, 0, 0.6);
  overflow-wrap: break-word;
  white-space: pre-line;
  width: 100%;
  margin-bottom: 5px;
`;

const inlineText = css`
  float: left;
  text-align: left;
  padding-left: 0.5833em;
  padding-right: 0.9em;
  font-size: 1rem;
  font-weight: 500;
  overflow-wrap: break-word;
  white-space: pre-line;
  width: 100%;
  padding-bottom: 10px;
`;

export default {
  inlineLabel,
  inlineText,
};
