/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Icon, Image, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './resetPassword.style';
import keringLogo from '../../../assets/keringLogo_scritta.png';
import api from '../utils/api';
import { toastService } from '../../../core/services/toastService';

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ResetPassword = (): JSX.Element => {
  return (
    <Card fluid css={style.card}>
      <Card.Content css={style.cardContent}>
        <div css={style.contentContainer}>
          <Link style={{ paddingBottom: '5px' }} to='/login'>
            <Icon name='chevron left' /> <span>Return to login</span>
          </Link>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              alignItems: 'center',
            }}>
            <Image src={keringLogo} wrapped />
            <ResetPasswordForm />
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export const ResetPasswordForm = (): JSX.Element => {
  const { t } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState('');
  const [confirmedPassword, setConfirmedPassword] = React.useState('');
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onChangeConfirmedPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedPassword(event.target.value);
  };

  const reset = () => {
    if (password !== '' && confirmedPassword !== '' && password.localeCompare(confirmedPassword) === 0)
      api.updatePassword(query.get('token') || '', password).then(() => {
        toastService.success();
        navigate('login');
      });
  };

  return (
    <Card css={style.resetPasswordCard}>
      <Card.Content>
        <h4 css={style.resetPasswordCardTitle}>{t('login.resetPassword')}</h4>
        <div css={style.resetPasswordCardContent}>
          <div css={style.formContainer}>
            <Input
              value={password}
              type='password'
              onChange={event => onChangePassword(event)}
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              css={style.passwordInput}
            />
            <Input
              value={confirmedPassword}
              type='password'
              onChange={event => onChangeConfirmedPassword(event)}
              icon='lock'
              iconPosition='left'
              placeholder='Confirm password'
              css={style.confirmedPasswordInput}
              onKeyPress={(e: KeyboardEvent) => {
                if (e.code === 'Enter') {
                  reset();
                }
              }}
            />
            <Button
              style={{ 'margin-top': '15px' }}
              onClick={reset}
              disabled={password === '' || confirmedPassword === '' || password !== confirmedPassword}>
              {t('login.reset')}
            </Button>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};
