/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Form, InputOnChangeData, Popup } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import style from './productFilter.style';
import { stringIsNotUndefinedOrEmpty } from '../../../../shared/utils';

const regex = /^[a-zA-Z0-9 ]+$/;

interface ProductFilterProps {
  filter: string;
  totalCount: number;
  loading?: boolean;
  onSubmitFilter(value: string): void;
}

interface ProductFilterState {
  filter: string;
  filterValid: boolean;
}

export const ProductFilter: React.FC<ProductFilterProps> = ({ totalCount, loading, onSubmitFilter, filter }) => {
  const [state, setState] = useState<ProductFilterState>({
    filter,
    filterValid: true,
  });
  const { t } = useTranslation();

  const debounce = useDebouncedCallback((value: string) => {
    if (value !== '' && !regex.test(value)) {
      setState({ filter: value, filterValid: false });
    } else {
      setState({ filter: value, filterValid: true });
      onSubmitFilter(value);
    }
  }, 500);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    debounce(value);
  };

  let popupMessage = '';
  if (!state.filterValid) {
    popupMessage = t('product.invalidCharacter');
  } else if (totalCount === 0) {
    popupMessage = t('product.noResultsFound');
  }

  return (
    <Form>
      <Form.Group css={style.formGroup}>
        <Form.Field css={style.formField}>
          <Popup
            trigger={
              <Form.Input
                placeholder={t('product.enterFilter')}
                name='filter'
                error={!state.filterValid}
                onChange={handleOnChange}
                icon='search'
                loading={loading}
              />
            }
            content={popupMessage}
            on='click'
            open={!state.filterValid || (totalCount === 0 && stringIsNotUndefinedOrEmpty(state.filter))}
            position='right center'
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
