/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { THREE_MONTHS } from '../features/brokerOrders/store/brokerOrderSlice';
import style from './periodFilter.style';

interface PeriodFilterProps {
  defaultValue: string;
  changePeriodFilterHandler: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

export const PeriodFilter = ({ changePeriodFilterHandler, defaultValue }: PeriodFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const periodOptions = () => {
    const options = [];
    const YEAR_ZERO = 2021;
    const currentYear = new Date().getFullYear();

    for (let i = YEAR_ZERO; i <= currentYear; i += 1) {
      options.unshift({ key: i.toString(), value: i.toString(), text: i.toString() });
    }
    options.unshift({ key: THREE_MONTHS, value: THREE_MONTHS, text: 'last 3 months' });
    return options;
  };

  return (
    <Flex>
      <div css={style.filter}>
        <Box p={2} width='max-content'>
          <div>{t('filters.periodFilterLabel')}</div>
        </Box>
        <Box p={2} flex={1}>
          <Dropdown
            css={style.dropdown}
            inline
            options={periodOptions()}
            defaultValue={defaultValue}
            onChange={(event, data) => changePeriodFilterHandler(event, data)}
          />
        </Box>
      </div>
    </Flex>
  );
};
