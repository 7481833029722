import { API_URL } from '../../../constants';

const JOB_BOARDS__URL = `${API_URL}/job-boards`;

const paths = {
  getJobBoards: (): string => `${JOB_BOARDS__URL}`,
  getJobBoard: (id: string): string => `${JOB_BOARDS__URL}/${id}`,
  createJobBoardProduct: (jobBoardId: string): string => `${JOB_BOARDS__URL}/${jobBoardId}/products`,
  updateJobBoardProduct: (jobBoardId: string, productId: string): string =>
    `${JOB_BOARDS__URL}/${jobBoardId}/products/${productId}`,
  deleteJobBoardProduct: (jobBoardId: string, productId: string): string =>
    `${JOB_BOARDS__URL}/${jobBoardId}/products/${productId}`,
  createJobBoard: (): string => `${JOB_BOARDS__URL}`,
  updateJobBoard: (id: string): string => `${JOB_BOARDS__URL}/${id}`,
  deleteJobBoard: (id: string): string => `${JOB_BOARDS__URL}/${id}`,
  uploadPicture: (): string => `${API_URL}/pictures`,
  getPicture: (id: string): string => `${API_URL}/pictures/${id}`,
  updateJobBoardPicture: (id: string): string => `${JOB_BOARDS__URL}/${id}/update-picture`,
};

export default paths;
