/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Image, Input } from 'semantic-ui-react';
import validator from 'validator';
import { Link } from 'react-router-dom';
import { OauthSenderButton } from '../components/OauthSenderButton';
import { OAUTH_AUTHORIZE_URL, OAUTH_CLIENT_ID, REDIRECT_URI } from '../../../constants';
import keringLogo from '../../../assets/keringLogo_scritta.png';
import style from './login.style';
import globalStyle from '../../../shared/global.style';
import api from '../utils/api';

interface LoginPros {
  onExternalAuthSuccess: (token: string) => void;
}

export const Login = ({ onExternalAuthSuccess }: LoginPros): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Card fluid css={style.card}>
      <Card.Content css={style.cardContent}>
        <div css={style.contentContainer}>
          <Image src={keringLogo} wrapped />
          <h3 css={style.title}>{t('login.title')}</h3>
          <div css={style.authenticationsContainer}>
            <CorporateAuthCard />
            <ExternalUserAuthCard onExternalAuthSuccess={onExternalAuthSuccess} />
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

const CorporateAuthCard = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card css={style.corporateAuthCard}>
      <Card.Content>
        <h4 css={style.authCardTitle}>{t('login.corporateAuthTitle')}</h4>
        <div css={style.authCardContent}>
          <span css={globalStyle.subtitleNormal}>{t('login.corporateAuthSubTitle')}</span>
          <br />
          <span css={globalStyle.bodySmall}>{t('login.corporateAuthBody')}</span>
        </div>
        <OauthSenderButton authorizeUrl={OAUTH_AUTHORIZE_URL} clientId={OAUTH_CLIENT_ID} redirectUri={REDIRECT_URI} />
      </Card.Content>
    </Card>
  );
};

const ExternalUserAuthCard = ({ onExternalAuthSuccess }: LoginPros): JSX.Element => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const login = () => {
    if (!(email === '' || !validator.isEmail(email) || password === null))
      api.getTokenExternalUser({ username: email, password }).then(onExternalAuthSuccess);
  };

  return (
    <Card css={style.authCard}>
      <Card.Content>
        <h4 css={style.authCardTitle}>{t('login.userAuthTitle')}</h4>
        <div css={style.authCardContent}>
          <span css={globalStyle.subtitleNormal}>{t('login.userAuthSubTitle')}</span>
          <br />
          <span css={globalStyle.bodySmall}>{t('login.userAuthBody')}</span>
          <div css={style.userLoginFormContainer}>
            <Input
              value={email}
              error={!(email !== '' && validator.isEmail(email)) && email !== ''}
              type='text'
              onChange={event => onChangeEmail(event)}
              icon='mail'
              iconPosition='left'
              placeholder='Email'
              css={style.emailInput}
            />
            <Input
              value={password}
              type='password'
              onChange={event => onChangePassword(event)}
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              css={style.passwordInput}
              onKeyPress={(e: KeyboardEvent) => {
                if (e.code === 'Enter') {
                  login();
                }
              }}
            />
            <Link css={style.forgotPassword} to='/forgot-password'>
              {t('login.forgotPassword')}
            </Link>
            <Button css={style.logInButton} onClick={login}>
              Log in
            </Button>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};
