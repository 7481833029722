import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
// eslint-disable-next-line import/no-cycle
import api from '../utils/api';
import { BrokerOrder } from '../model/brokerOrder';
import { checkValueInclude } from '../../../shared/utils';
import { Country } from '../../jobBoards/model/countries';

export const THREE_MONTHS = 'THREE_MONTHS';
export const ALL_STATUSES = 'ALL_STATUSES';

interface BrandOrderSliceState {
  value: BrokerOrder[];
  isFetching: boolean;
  error: string;
  periodFilter: string;
  statusFilter: string;
  countryFilter: Country | null;
  jobBoardFilter: string;
  brandFilter: string;
  productTypeFilter: string;
  fromOrderDate: string | null;
  toOrderDate: string | null;
}

const initialState = {
  value: [],
  isFetching: false,
  error: '',
  periodFilter: THREE_MONTHS,
  statusFilter: ALL_STATUSES,
  countryFilter: null,
  jobBoardFilter: '',
  brandFilter: '',
  productTypeFilter: '',
  fromOrderDate: null,
  toOrderDate: null,
} as BrandOrderSliceState;

export const brandOrderSlice = createSlice({
  name: 'brandOrders',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrandOrderSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<BrandOrderSliceState>, action: PayloadAction<BrokerOrder[]>) => {
      action.payload.sort((a: BrokerOrder, b: BrokerOrder) => {
        const ad = new Date(a.lastUpdate);
        const bd = new Date(b.lastUpdate);
        return ad.getTime() > bd.getTime() ? -1 : 1;
      });

      return {
        ...state,
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    changePeriodFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      periodFilter: action.payload,
    }),
    changeStatusFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      statusFilter: action.payload,
    }),
    changeCountryFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<Country | null>) => ({
      ...state,
      countryFilter: action.payload,
    }),
    changeJobBoardFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      jobBoardFilter: action.payload,
    }),
    changeBrandFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      brandFilter: action.payload,
    }),
    changeFromOrderDateFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string | null>) => ({
      ...state,
      fromOrderDate: action.payload,
    }),
    changeToOrderDateFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string | null>) => ({
      ...state,
      toOrderDate: action.payload,
    }),
    changeProductTypeFilter: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      productTypeFilter: action.payload,
    }),

    httpError: (state: Draft<BrandOrderSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  changePeriodFilter,
  changeStatusFilter,
  changeCountryFilter,
  changeJobBoardFilter,
  changeBrandFilter,
  changeFromOrderDateFilter,
  changeToOrderDateFilter,
  changeProductTypeFilter,
} = brandOrderSlice.actions;

export const selectBrandBrokerOrders = (state: RootState): BrokerOrder[] => state.brandOrders.value;

export const selectIsFetchingBrandBrokerOrders = (state: RootState): boolean => state.brandOrders.isFetching;
export const selectBrandPeriodFilter = (state: RootState): string => state.brandOrders.periodFilter;
export const selectBrandStatusFilter = (state: RootState): string => state.brandOrders.statusFilter;
export const selectBrandCountryFilter = (state: RootState): Country | null => state.brandOrders.countryFilter;
export const selectBrandJobBoardFilter = (state: RootState): string => state.brandOrders.jobBoardFilter;
export const selectBrandFilter = (state: RootState): string => state.brandOrders.brandFilter;
export const selectFromOrderDateFilter = (state: RootState): string | null => state.brandOrders.fromOrderDate;
export const selectToOrderDateFilter = (state: RootState): string | null => state.brandOrders.toOrderDate;
export const selectProductTypeFilter = (state: RootState): string => state.brandOrders.productTypeFilter;

export const fetchBrandBrokerOrders = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const orders = await api.getBrokerOrdersByBrands(state().brandOrders.periodFilter);
    dispatch(finishFetch(orders));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

const checkCountryFilter = (countryFilter: Country | null, order: BrokerOrder) =>
  countryFilter == null || countryFilter.code === '' || order.orders.some(o => o.country === countryFilter.code);

const checkBrandFilter = (brandFilter: string | null, order: BrokerOrder) =>
  brandFilter == null ||
  brandFilter === '' ||
  order.orders.some(o => o.brands != null && o.brands.includes(brandFilter));

const checkDateFilter = (fromDate: string | null, toDate: string | null, order: BrokerOrder) => {
  return order.orders.some(
    o =>
      (fromDate === null || moment(o.creationDate).isSameOrAfter(moment(fromDate).startOf('day'))) &&
      (toDate === null || moment(o.creationDate).isSameOrBefore(moment(toDate).endOf('day')))
  );
};

export const selectFilteredBrandBrokerOrders = createSelector(
  selectBrandBrokerOrders,
  selectBrandStatusFilter,
  selectBrandCountryFilter,
  selectBrandJobBoardFilter,
  selectBrandFilter,
  selectFromOrderDateFilter,
  selectToOrderDateFilter,
  selectProductTypeFilter,
  (orders, statusFilter, countryFilter, jobBoardFilter, brandFilter, fromDate, toDate, productType) => {
    return orders.filter(
      order =>
        (statusFilter === ALL_STATUSES || order.status === statusFilter) &&
        checkCountryFilter(countryFilter, order) &&
        checkValueInclude(order.product.jobBoardId, jobBoardFilter) &&
        checkBrandFilter(brandFilter, order) &&
        checkDateFilter(fromDate, toDate, order) &&
        (checkValueInclude(order.product.productType, productType) ||
          (productType === '' && order.product.productType == null))
    );
  }
);

export default brandOrderSlice.reducer;
