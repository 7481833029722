import React from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ClassNames, Global } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import './theme/colors.css';
import './theme/override.css';
import './theme/font.css';
import { setErrorInterceptor, setJwtInterceptor } from './core/interceptors';
import { store } from './core/store';
import style from './shared/global.style';
import '@fortawesome/fontawesome-free/css/all.min.css';

setJwtInterceptor();
setErrorInterceptor();

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ClassNames>
          {({ css, cx }) => {
            const toastClassName = css`
              .Toastify__toast-container& {
                width: 100px;
              }
              
              .Toastify__toast& {
                color: var(--jbmp-white-color);
              }
              .Toastify__toast--warning& {
                background-color: var(--jbmp-warning);
              }

              .Toastify__toast--success& {
                background-color: var(--jbmp-success);
              }

              .Toastify__toast--error& {
                background-color: var(--jbmp-error);
                white-space: pre-wrap
              }

              .Toastify__toast--info& {
                background-color: var(--jbmp-info);
              }`;
            return (
              <ToastContainer
                toastClassName={toastClassName}
                position='top-right'
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
                theme='colored'
                style={{ textAlign: 'center' }}
              />
            );
          }}
        </ClassNames>
        <Global styles={style.scrollbar} />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
