/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useRef, useState } from 'react';
import { Icon, Image } from 'semantic-ui-react';
import Resizer from 'react-image-file-resizer';
import api from '../utils/api';
import style from './picturesListView.style';
import { ModalPage } from '../../../shared/ModalPage';
import loadingImg from '../../../assets/loading.gif';
import { PictureRef } from '../model/jobBoard';

interface PicturesListViewProps {
  pictures: PictureRef[];
  onDeletePicture: (pic: PictureRef) => void;
  onAddPicture: (pic: PictureRef) => void;
  editable: boolean;
}

const base64toBlob = (dataURI: string): File => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab]) as File;
};

const resizeFile = (file: File | string): Promise<string> => {
  const f = typeof file === 'string' ? base64toBlob(file as string) : file;
  const quality = f.size > 1000000 ? 80 : 99;
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      f,
      1920,
      1920,
      'JPEG',
      quality,
      0,
      uri => {
        resolve(uri as string);
      },
      'base64'
    );
  });
};

export const PicturesListView = ({
  pictures,
  onDeletePicture,
  onAddPicture,
  editable,
}: PicturesListViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [previewActive, setPreviewActive] = useState(false);
  const [picturePreview, setPicturePreview] = useState<string | null>(null);
  const uploadInput = useRef<HTMLInputElement>(null);

  const showPreview = useCallback(async (id: string) => {
    const picture = await api.downloadPicture(id);
    setPicturePreview(picture);
    setPreviewActive(true);
  }, []);

  const deletePicture = useCallback(
    (p: PictureRef) => {
      onDeletePicture(p);
    },
    [onDeletePicture]
  );

  const onSelectPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files != null ? e.target.files[0] : null;

    if (reader != null && file != null) {
      reader.onload = async (readerEvt: ProgressEvent<FileReader>) => {
        e.target.value = '';
        await uploadPicture(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadPicture = async (pictureData: string | File) => {
    const loading = { id: `${new Date().getTime()}`, data: '' };
    onAddPicture(loading);
    const base64Image = await resizeFile(pictureData);
    const px = await api.uploadPictures({ data: base64Image, contentType: 'image/jpeg' });
    onDeletePicture(loading);
    onAddPicture(px);
  };

  return (
    <div css={style.picturesContainer}>
      <div css={style.picturesCarouselContainer}>
        <div css={style.pictureCarousel}>
          {pictures.map(p => (
            <div key={p.id} css={style.pictureCarouselItem}>
              <div css={style.pictureCarouselImageContainer}>
                {p.data !== '' ? (
                  <Image src={`data:image/jpeg;base64,${p.data}`} onClick={() => showPreview(p.id)} />
                ) : (
                  <Image src={loadingImg} size='mini' />
                )}
              </div>
              {editable && (
                <div css={style.pictureCarouselItemDeleteIcon} onClick={() => deletePicture(p)}>
                  <Icon name='times' />
                </div>
              )}
            </div>
          ))}
        </div>
        {editable && (
          <div css={style.picturesButtons}>
            <div css={style.fileInput('\\f07c')}>
              <input type='file' accept='image/*' onChange={onSelectPicture} ref={uploadInput} />
            </div>
          </div>
        )}
        {previewActive && (
          <ModalPage onClose={() => setPreviewActive(false)} title={t('assessment.checkList.picturePreview')}>
            <div css={style.previewContainer}>
              <Image src={`data:image/jpeg;base64,${picturePreview}`} alt='loading...' />
            </div>
          </ModalPage>
        )}
      </div>
    </div>
  );
};
