import { css } from '@emotion/react';

const button = css`
  margin: 2px;
  background-color: #f9f9fa;
  padding: 8px;
  border-radius: 5px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

const container = css`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const loader = css`
  float: right;
  padding-left: 5px;
`;

export default {
  button,
  container,
  loader,
};
