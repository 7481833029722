import { css } from '@emotion/react';

const container = css`
  overflow: auto;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
`;

const header = css`
  display: flex;
  padding: 10px;
  align-items: center;
  height: max-content;
`;

const emptyContainer = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border: solid 1px var(--jbmp-primary-color-40);
    height: 50px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--jbmp-primary-color-60);
  }
`;

const addButton = css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
  }
  @media (min-width: 800px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: unset;
      border-radius: 4px;
      :before {
        content: 'Create User';
        padding-right: 5px;
      }
    }
  }
`;

const searchBox = css`
  margin-left: auto;
  flex: 1;
  max-width: 400px;
`;

export default {
  container,
  header,
  addButton,
  searchBox,
  emptyContainer,
};
