import { css } from '@emotion/react';

const card = css`
  && {
    height: 100%;
    background-color: var(--jbmp-primary-color-10);
    margin: 0;
  }
`;

const cardContent = css`
  &&& {
    display: flex;
    padding: 0;
  }
`;

const contentContainer = css`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const resetPasswordCard = css`
  && {
    margin: 0;
    width: auto;
    max-width: 450px;
    // box-shadow: none;
  }
`;

const resetPasswordCardTitle = css`
  border-bottom: 1px solid var(--jbmp-primary-color-40);
  text-align: center;
`;

const resetPasswordCardContent = css`
  max-width: 600px;
  padding: 10px 0 20px 0;
`;

const formContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > Input {
    padding-bottom: 10px;
  }
`;

const input = css`
  && {
    input:focus {
      border-color: var(--jbmp-primary-color-60);
    }
  }
`;

const passwordInput = css`
  ${input};
`;

const confirmedPasswordInput = css`
  ${input};
  && {
    margin-top: 10px;
  }
`;

export default {
  card,
  cardContent,
  contentContainer,
  resetPasswordCard,
  resetPasswordCardTitle,
  resetPasswordCardContent,
  passwordInput,
  confirmedPasswordInput,
  formContainer,
};
