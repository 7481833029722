/** @jsxImportSource @emotion/react */

import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Product } from '../../model/product';
import style from './productTable.style';
import { isProductFinalPriceValid } from '../../../../shared/utils';

interface ProductRowProps {
  product: Product;
  withJobBoardColumns?: boolean;
  onClickRow?: (product: Product) => void;
  onClickCloneProduct?: (product: Product) => void;
}

export const ProductRow: React.FC<ProductRowProps> = ({
  product,
  withJobBoardColumns,
  onClickRow,
  onClickCloneProduct,
}) => {
  const { t } = useTranslation();
  const finalPriceIsValid = isProductFinalPriceValid(product);
  return (
    <Table.Row onClick={() => onClickRow && onClickRow(product)} style={onClickRow && { cursor: 'pointer' }}>
      <Table.Cell>{product.name}</Table.Cell>
      {withJobBoardColumns && <Table.Cell>{product.jobBoardName}</Table.Cell>}
      <Table.Cell css={style.textAreaCell}>{product.description}</Table.Cell>
      <Table.Cell>{product.region}</Table.Cell>
      <Table.Cell>{product.countries.join(', ')}</Table.Cell>
      <Table.Cell>{product.target}</Table.Cell>
      <Table.Cell>{product.productType}</Table.Cell>
      <Table.Cell>{finalPriceIsValid ? '' : `${product.marketPrice} ${product.marketPriceCurrency}`}</Table.Cell>
      <Table.Cell>{finalPriceIsValid ? '' : `${product.priceFrom} ${product.priceFromCurrency}`}</Table.Cell>
      <Table.Cell>{finalPriceIsValid ? '' : `${product.priceTo} ${product.priceToCurrency}`}</Table.Cell>
      <Table.Cell>{finalPriceIsValid ? `${product.finalPrice?.value} ${product.finalPrice?.currency}` : ''}</Table.Cell>
      <Table.Cell>{finalPriceIsValid ? <Moment format='YYYY/MM/DD'>{product.validityEndDate}</Moment> : ''}</Table.Cell>
      {onClickCloneProduct && (
        <Table.Cell
          textAlign='center'
          onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
          style={{ cursor: 'default' }}>
          <Button icon='clone' title={t('product.cloneProduct')} onClick={() => onClickCloneProduct(product)} basic />
        </Table.Cell>
      )}
    </Table.Row>
  );
};
