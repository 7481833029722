/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent, useCallback } from 'react';
import _ from 'lodash';
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Input,
  Label,
  TextArea,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'rebass';
import DatePicker from 'react-datepicker';
import style from './updateProduct.style';
import { ContentRef, Price, Product, PRODUCT_TYPE } from '../features/jobBoards/model/product';
import { PriceInput } from './PriceInput';
import { countriesAreSelected, regionsAreSelected, stringIsNotUndefinedOrEmpty } from './utils';
import { productTypeOptions } from '../features/jobBoards/components/CreateProduct';
import { FileUpload } from './FileUpload';
import { FilesDownload } from './FilesDownload';
import { COUNTRIES, getCountriesByRegion, getRegionByCountry, REGION } from '../features/jobBoards/model/countries';
import { selectUserCountryCodes, selectUserRegions } from '../features/auth/store/userSlice';

interface UpdateProductProps {
  product: Product;
  onChange: (product: Product) => void;
  onCancel: () => void;
}

export const UpdateProduct = ({ onChange, product: productProp, onCancel }: UpdateProductProps): JSX.Element => {
  const [product, setProduct] = React.useState<Product>(productProp);

  const [finalPrice, setFinalPrice] = React.useState<Price | undefined>(product.finalPrice);
  const [validityEndDate, setValidityEndDate] = React.useState<Date | undefined>(
    product.validityEndDate ? new Date(product.validityEndDate) : undefined
  );
  const [finalPriceChecked, setFinalPriceChecked] = React.useState<boolean>(product.finalPrice != null);

  const { t } = useTranslation();

  const userRegions = useSelector(selectUserRegions);

  const userCountries = useSelector(selectUserCountryCodes);

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduct({
      ...product,
      name: event.target.value,
    });
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProduct({
      ...product,
      description: event.target.value,
    });
  };

  const onChangeTarget = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduct({
      ...product,
      target: event.target.value,
    });
  };

  const onCountryChange = (event: SyntheticEvent, data: DropdownProps) => {
    setProduct({
      ...product,
      countryCodes: data.value as string[],
    });
  };

  const onRegionChange = (event: SyntheticEvent, data: DropdownProps) => {
    setProduct({
      ...product,
      region: data.value as REGION,
      countryCodes: [],
      countries: [],
    });
  };

  const onChangeProductType = (event: SyntheticEvent, data: DropdownProps) => {
    setProduct({
      ...product,
      productType: data.value as PRODUCT_TYPE,
    });
  };

  const onMarketPriceChange = (value: number, currency: string) => {
    setProduct({
      ...product,
      marketPrice: value,
      marketPriceCurrency: currency,
    });
  };

  const onPriceFromChange = (value: number, currency: string) => {
    setProduct({
      ...product,
      priceFrom: value,
      priceFromCurrency: currency,
    });
  };

  const onPriceToChange = (value: number, currency: string) => {
    setProduct({
      ...product,
      priceTo: value,
      priceToCurrency: currency,
    });
  };

  const onFinalPriceCheckedChange = (checked?: boolean) => {
    setFinalPriceChecked(!!checked);
    if (checked && validityEndDate == null) {
      setValidityEndDate(new Date(new Date().getFullYear(), 11, 31));
    }
  };

  const onProductUpdate = async () => {
    onChange({
      ...product,
      region: product.region || REGION.GLOBAL,
      finalPrice: finalPriceChecked ? finalPrice : undefined,
      validityEndDate: finalPriceChecked ? validityEndDate?.toUTCString() : undefined,
    });
  };

  const isValid = () => {
    return (
      stringIsNotUndefinedOrEmpty(product.name) &&
      stringIsNotUndefinedOrEmpty(product.description) &&
      stringIsNotUndefinedOrEmpty(product.target) &&
      product.marketPrice != null &&
      product.priceFrom != null &&
      product.priceTo != null &&
      stringIsNotUndefinedOrEmpty(product.marketPriceCurrency) &&
      stringIsNotUndefinedOrEmpty(product.priceFromCurrency) &&
      stringIsNotUndefinedOrEmpty(product.priceToCurrency) &&
      (countriesAreSelected(product.countryCodes) || regionsAreSelected(product.region)) &&
      (!finalPriceChecked || (finalPrice != null && finalPrice.value > 0 && validityEndDate != null))
    );
  };

  const fileUploadHandler = (content: ContentRef) => {
    setProduct({
      ...product,
      contents: product.contents != null ? [...product.contents, content] : [content],
    });
  };

  const onDeleteHandler = (content: ContentRef) => {
    setProduct({
      ...product,
      contents: (product.contents || []).filter(c => c.id !== content.id),
    });
  };

  const getRegionOptions = useCallback((): DropdownItemProps[] => {
    const productRegions = product.countryCodes.flatMap(c => getRegionByCountry(c));

    const result = [...userRegions, ...productRegions, product.region].map(c => ({
      key: c,
      text: c,
      value: c,
    }));
    return _.uniqBy([{ key: REGION.GLOBAL, text: REGION.GLOBAL, value: REGION.GLOBAL }, ...result], 'key');
  }, [product.countryCodes, product.region, userRegions]);

  const getCountryOptions = useCallback(() => {
    const result =
      product.region === REGION.GLOBAL || product.region == null
        ? COUNTRIES.filter(c => userCountries.includes(c.code) || product.countryCodes.includes(c.code))
        : getCountriesByRegion(product.region).filter(
            c => userCountries.includes(c.code) || product.countryCodes.includes(c.code)
          );

    return _.uniqBy(
      result.map(c => ({
        key: c.code,
        text: c.name,
        value: c.code,
        flag: c.code.toLowerCase(),
      })),
      'key'
    );
  }, [product.countryCodes, product.region, userCountries]);

  return (
    <div css={style.formContainer}>
      <div css={style.form} data-testid='create-product'>
        <Flex flexWrap='wrap'>
          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.name')}</Label>
              </Box>
              <Box width={1}>
                <Input
                  fluid
                  value={product.name}
                  type='text'
                  data-testid='product-name-text-input'
                  onChange={event => onChangeName(event)}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.description')}</Label>
              </Box>
              <Box width={1}>
                <TextArea
                  css={style.textArea}
                  value={product.description}
                  data-testid='product-description-text-area'
                  onChange={event => onChangeDescription(event)}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.target')}</Label>
              </Box>
              <Box width={1}>
                <Input
                  fluid
                  value={product.target}
                  type='text'
                  data-testid='product-target-text-input'
                  onChange={event => onChangeTarget(event)}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.files')}</Label>
              </Box>
              <Box width={1}>
                <FilesDownload onDelete={onDeleteHandler} contents={product.contents} />
                <FileUpload id={product.id} onFileUpload={(content: ContentRef) => fileUploadHandler(content)} />
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.region')}</Label>
              </Box>
              <Box width={1}>
                <Dropdown
                  style={{ width: '100%' }}
                  placeholder={t('menu.region')}
                  value={product.region.toString()}
                  fluid
                  search
                  selection
                  options={getRegionOptions()}
                  onChange={(event, data) => onRegionChange(event, data)}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.country')}</Label>
              </Box>
              <Box width={1}>
                <Dropdown
                  style={{ width: '100%' }}
                  placeholder={t('menu.allCountries')}
                  value={product.countryCodes}
                  multiple
                  fluid
                  search
                  selection
                  options={getCountryOptions()}
                  onChange={(event, data) => onCountryChange(event, data)}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.productType')}</Label>
              </Box>
              <Box width={1}>
                <Dropdown
                  style={{ width: '100%' }}
                  placeholder={t('menu.productType')}
                  value={product.productType}
                  fluid
                  selection
                  options={productTypeOptions}
                  onChange={(event, data) => onChangeProductType(event, data)}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box>
                <Label css={style.label}>{t('product.columns.finalPrice')}</Label>
                <Checkbox
                  style={{ paddingTop: '4px', paddingLeft: '10px' }}
                  checked={finalPriceChecked}
                  onChange={(e, { checked }) => onFinalPriceCheckedChange(checked)}
                />
              </Box>
              <Box width={1}>
                <PriceInput
                  currency={finalPrice?.currency}
                  value={finalPrice?.value}
                  onChange={(value, currency) => setFinalPrice({ value, currency })}
                  disabled={!finalPriceChecked}
                />
              </Box>
            </Flex>
          </Box>
          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.validityEndDate')}</Label>
              </Box>
              <Box>
                <DatePicker
                  css={style.datepicker}
                  minDate={
                    validityEndDate ? new Date(Math.min(new Date().getTime(), validityEndDate.getTime())) : new Date()
                  }
                  isClearable={finalPriceChecked}
                  selected={validityEndDate}
                  dateFormat='yyyy-MM-dd'
                  onChange={(v?: Date | null) => setValidityEndDate(v || undefined)}
                  placeholderText='yyyy-mm-dd'
                  customInput={<Input css={style.datePickerInput} />}
                  disabled={!finalPriceChecked}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.marketPrice')}</Label>
              </Box>
              <Box width={1}>
                <PriceInput
                  currency={product.marketPriceCurrency}
                  value={product.marketPrice}
                  onChange={(value, currency) => onMarketPriceChange(value, currency)}
                />
              </Box>
            </Flex>
          </Box>
          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.priceFrom')}</Label>
              </Box>
              <Box width={1}>
                <PriceInput
                  currency={product.priceFromCurrency}
                  value={product.priceFrom}
                  onChange={(value, currency) => onPriceFromChange(value, currency)}
                />
              </Box>
            </Flex>
          </Box>
          <Box width={[1, 1 / 2]} p={1}>
            <Flex flexWrap='wrap'>
              <Box width={1}>
                <Label css={style.label}>{t('product.columns.priceTo')}</Label>
              </Box>
              <Box width={1}>
                <PriceInput
                  currency={product.priceToCurrency}
                  value={product.priceTo}
                  onChange={(value, currency) => onPriceToChange(value, currency)}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
      <Flex flexWrap='wrap' css={style.formButtons as never}>
        <Button css={style.button} primary fluid onClick={() => onCancel()}>
          {t('product.cancel')}
        </Button>
        <Button
          primary
          css={style.button}
          fluid
          data-testid='product-update-button'
          onClick={() => onProductUpdate()}
          disabled={!isValid()}>
          {t('product.update')}
        </Button>
      </Flex>
    </div>
  );
};
