import { css } from '@emotion/react';

const basketItem = css`
  &&& {
    margin-bottom: 15px;
  }
`;

const form = css`
  padding: 10px;
  color: black;
  font-size: 1.2rem;
  background-color: white;
`;

const label = css`
  background-color: white !important;
`;

const productName = css`
  font-size: 1.5rem !important;
  font-weight: 500;
  padding: 0.5em !important;
  &&&& a {
    color: var(--jbmp-primary-color);
  }
  //display: flex;
  //align-items: center;
`;

const date = css`
  font-size: 0.8rem;
  font-weight: 300;
  padding-left: 0.5833em;
`;

const text = css`
  padding: 0.5833em;
  padding-top: 0;
  text-align: left;
  overflow-wrap: break-word;
  white-space: break-spaces;
  margin: 0;
`;

const priceToLabel = css`
  padding-right: 0.7rem;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  text-align: right;
  width: 100%;
`;

const priceTo = css`
  text-align: right;
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
  overflow-wrap: break-word;
  overflow: hidden;
`;

const button = css`
  padding: 10px;
`;

const container = css`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 10px;
`;

const confirmFinalPrice = css`
  padding-bottom: 6px;
`;

const link = css`
  cursor: pointer;
`;

export default {
  form,
  label,
  date,
  text,
  button,
  productName,
  priceTo,
  priceToLabel,
  container,
  confirmFinalPrice,
  link,
  basketItem,
};
