/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { Card, Icon, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { useAppDispatch } from '../../../core/coreHooks';
import { ACCEPTANCE_STATUS, Order, ORDER_STATUS, ProductOrder } from '../model/order';
import { CompanyProfileCard } from '../../companyProfiles/components/CompanyProfileCard';
import style from './viewOrder.style';
import { updateCompany } from '../store/orderSlice';
import { FilesDownload } from '../../../shared/FilesDownload';
import { ModalPage } from '../../../shared/ModalPage';
import { UpdateCompanyProfile } from '../../companyProfiles/components/UpdateCompanyProfile';
import { Company, CompanyProfile } from '../../companyProfiles/model/companyProfile';
import { FiletType } from '../../jobBoards/model/product';
import { OrderFinalPrice } from './OrderFinalPrice';
import { ViewProductDetails } from '../../../shared/ViewProductDetails';

interface ViewOrderProps {
  order: Order;
}

const VALID_COMPANY_EDIT_STATUSES = [ORDER_STATUS.PRE_ORDER, ORDER_STATUS.PRICED, ORDER_STATUS.FINAL_CONFIRMATION];

interface ProductTitleAndDescriptionProps {
  product: ProductOrder;
  acceptanceStatus?: ACCEPTANCE_STATUS;
  creationDate?: string;
  flagDescription: boolean;
}

const ProductTitleAndDescription = ({
  product,
  acceptanceStatus,
  creationDate,
  flagDescription,
}: ProductTitleAndDescriptionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column'>
      <Box width={[1]} p={1} css={style.productName as never}>
        <Flex flexDirection='row'>
          <Box>
            <Link to={`/products/${product.id}`}>
              <Icon color='blue' name='linkify' size='small' />
            </Link>
          </Box>
          <Box>
            <Link to={`/products/${product.id}`}>
              {product.name} ({product.jobBoardName})
            </Link>
          </Box>
          {acceptanceStatus && acceptanceStatus !== ACCEPTANCE_STATUS.TO_BE_ACCEPTED && (
            <AcceptanceStatus status={acceptanceStatus} />
          )}
        </Flex>
      </Box>
      {creationDate != null && (
        <Box width={[1]} p={1}>
          <div css={style.creationDate}>
            {t('order.creationDate')}{' '}
            <strong>
              <Moment format='YYYY/MM/DD HH:mm'>{creationDate}</Moment>
            </strong>
          </div>
        </Box>
      )}
      <Box width={[1]} p={1}>
        <div hidden={!flagDescription} css={style.text}>
          {product.description}
        </div>
      </Box>
    </Flex>
  );
};

export const ViewOrder = ({ order }: ViewOrderProps): JSX.Element => {
  const { t } = useTranslation();
  const o = order;

  const dispatch = useAppDispatch();

  const [selectedCompanyForEdit, setSelectedCompanyForEdit] = useState<null | Company>(null);

  const canEditCompany = useMemo(
    () => VALID_COMPANY_EDIT_STATUSES.includes(o.brokerOrderStatus),
    [o.brokerOrderStatus]
  );

  const invoices = useMemo(
    () => o.contents.filter(c => !c.fileType || c.fileType === FiletType.ORDER_INVOICE),
    [o.contents]
  );

  const proposals = useMemo(() => o.contents.filter(c => c.fileType === FiletType.ORDER_PROPOSAL), [o.contents]);

  const onCompanyUpdateHandler = (cp: Company) => {
    dispatch(updateCompany(o.id, cp));
    setSelectedCompanyForEdit(null);
  };

  // const onChangeQuantityOnPreorderHandler = (qty: number) => {
  //   if (qty > -1) {
  //     setQuantity(qty);
  //     debounce(qty);
  //   }
  // };

  // const debounce = useDebouncedCallback((qty: number) => {
  //   dispatch(changeQuantity(o.id, qty));
  // }, 500);

  return (
    <>
      {selectedCompanyForEdit && (
        <ModalPage title={t('companyProfile.editLabel')} onClose={() => setSelectedCompanyForEdit(null)}>
          <UpdateCompanyProfile
            companyProfile={selectedCompanyForEdit as CompanyProfile}
            onUpdate={cp => onCompanyUpdateHandler(cp)}
          />
        </ModalPage>
      )}
      <Card fluid>
        <Flex p={2} flexWrap='wrap'>
          <Box width={[1, 1, 2 / 3, 3 / 4]} pl={3} pr={3}>
            <Flex flexDirection='row'>
              <Box>
                <OrderStatus status={o.brokerOrderStatus} userRole='recruiter' />
              </Box>
              <Box pt={1} css={style.date as never}>
                {t('order.updated')} <Moment fromNow>{o.lastUpdate}</Moment>
              </Box>
            </Flex>

            <ProductTitleAndDescription
              product={o.product}
              acceptanceStatus={o.acceptanceStatus}
              creationDate={o.creationDate}
              flagDescription={false}
            />

            <ViewProductDetails product={o.product} countryCodes={[o.country]} showPriceTo />
            {o.brokerOrderStatus === ORDER_STATUS.CLOSED && o.acceptanceStatus === ACCEPTANCE_STATUS.ACCEPTED && (
              <Flex flexDirection='column'>
                <Box>
                  <strong>{t('order.invoices')}</strong>
                  <FilesDownload contents={invoices} />
                </Box>
                <Box>
                  <strong>{t('order.proposals')}</strong>
                  <FilesDownload contents={proposals} />
                </Box>
              </Flex>
            )}
            <CompanyProfileCard
              onClick={canEditCompany ? () => setSelectedCompanyForEdit(o.company) : undefined}
              companyProfile={o.company}
            />
          </Box>
          <Box css={style.orderPriceContainer as never} width={[1, 1, 1 / 3, 1 / 4]}>
            <OrderFinalPrice order={o} />
          </Box>
        </Flex>
      </Card>
    </>
  );
};

export const AcceptanceStatus = ({ status }: { status: ACCEPTANCE_STATUS }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {status === ACCEPTANCE_STATUS.TO_BE_ACCEPTED && (
        <Label as='div' color='orange'>
          {t('acceptanceStatus.TO_BE_ACCEPTED')}
        </Label>
      )}
      {status === ACCEPTANCE_STATUS.ACCEPTED && (
        <Label as='div' color='green'>
          {t('acceptanceStatus.ACCEPTED')}
        </Label>
      )}
      {status === ACCEPTANCE_STATUS.REJECTED && (
        <Label as='div' color='red'>
          {t('acceptanceStatus.REJECTED')}
        </Label>
      )}
    </>
  );
};

export const OrderStatus = ({
  status,
  userRole,
}: {
  status: ORDER_STATUS;
  userRole: 'recruiter' | 'broker';
}): JSX.Element => {
  const { t } = useTranslation();
  const role = userRole;
  return (
    <>
      {status === ORDER_STATUS.PRE_ORDER && (
        <Label as='div' color='blue' ribbon>
          {t(`orderStatus.PRE_ORDER.${role}`)}
        </Label>
      )}
      {status === ORDER_STATUS.CLOSED && (
        <Label as='div' color='green' ribbon>
          {t(`orderStatus.CLOSED.${role}`)}
        </Label>
      )}
      {status === ORDER_STATUS.FINAL_CONFIRMATION && (
        <Label as='div' color='yellow' ribbon>
          {t(`orderStatus.FINAL_CONFIRMATION.${role}`)}
        </Label>
      )}
      {status === ORDER_STATUS.PRICED && (
        <Label as='div' color='orange' ribbon>
          {t(`orderStatus.PRICED.${role}`)}
        </Label>
      )}
      {status === ORDER_STATUS.CANCELED && (
        <Label as='div' color='red' ribbon>
          {t(`orderStatus.CANCELED.${role}`)}
        </Label>
      )}
    </>
  );
};
