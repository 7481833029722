/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Flex } from 'rebass';
import { Card } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import style from './selectCompanyProfiles.style';
import { selectCompanyProfiles } from '../store/companyProfilesSlice';
import { CompanyProfile } from '../model/companyProfile';

interface SelectCompanyProfileProps {
  onSelected: (companyProfile: CompanyProfile) => void;
}

export const SelectCompanyProfile = ({ onSelected = () => null }: SelectCompanyProfileProps): JSX.Element => {
  const { t } = useTranslation();

  const companyProfiles = useSelector(selectCompanyProfiles);

  const navigate = useNavigate();

  return (
    <Flex flexWrap='wrap'>
      {companyProfiles &&
        companyProfiles.map(c => (
          <Box width={[1]} key={c.id} p={2}>
            <Card css={style.card} onClick={() => onSelected(c)}>
              <Flex flexDirection='column' style={{ height: '100%' }}>
                <Box>
                  <strong>{c.name}</strong>, {c.address}
                </Box>
                {c.vatNumber && (
                  <Box>
                    <span css={style.vat}>{t('companyProfile.vatNumber')}:</span> {c.vatNumber}
                  </Box>
                )}
                {c.invoiceContact && (
                  <Box>
                    <span css={style.invoiceContact}>{t('companyProfile.invoiceContact')}:</span> {c.invoiceContact}
                  </Box>
                )}
              </Flex>
            </Card>
          </Box>
        ))}
      {companyProfiles && companyProfiles.length === 0 && (
        <Flex flexWrap='wrap' p={3}>
          <Box width={[1]} p={2}>
            <Card css={style.addCard} onClick={() => navigate('/company-profiles')}>
              <div css={style.addCardText}>{t('companyProfile.noCompanyProfile')}</div>
            </Card>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
