import { css } from '@emotion/react';

const container = css`
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const emptyContainer = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border: solid 1px var(--jbmp-primary-color-40);
    height: 50px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--jbmp-primary-color-60);
  }
`;

export default {
  container,
  emptyContainer,
};
