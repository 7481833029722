import axios from 'axios';
import paths from './paths';
import { JOB_BOARD_STATUS, JobBoard, PictureRef, toJobBoard } from '../model/jobBoard';
import { JobBoardDetail, toJobBoardDetail } from '../model/JobBoardDetail';
import { ProductCreationRequest, ProductUpdateRequest } from '../model/product';

const api = {
  getJobBoards: async (): Promise<JobBoardDetail[]> => {
    const response = await axios.get(paths.getJobBoards());
    return response.data.map(toJobBoardDetail);
  },
  getJobBoardDetail: async (id: string): Promise<JobBoardDetail> => {
    const response = await axios.get(paths.getJobBoard(id));
    return toJobBoardDetail(response.data);
  },
  createJobBoardProduct: async (jobBoardId: string, request: ProductCreationRequest): Promise<JobBoardDetail> => {
    const response = await axios.post(paths.createJobBoardProduct(jobBoardId), request);
    return toJobBoardDetail(response.data);
  },
  updateJobBoardProduct: async (
    jobBoardId: string,
    productId: string,
    request: ProductUpdateRequest
  ): Promise<JobBoardDetail> => {
    const response = await axios.put(paths.updateJobBoardProduct(jobBoardId, productId), request);
    return toJobBoardDetail(response.data);
  },
  deleteJobBoardProduct: async (jobBoardId: string, productId: string): Promise<JobBoardDetail> => {
    const response = await axios.delete(paths.deleteJobBoardProduct(jobBoardId, productId));
    return toJobBoardDetail(response.data);
  },
  createJobBoard: async (name: string, about: string): Promise<JobBoard> => {
    const response = await axios.post(paths.createJobBoard(), { name, about });
    return toJobBoard(response.data);
  },
  updateJobBoard: async (id: string, name: string, about: string, status: JOB_BOARD_STATUS): Promise<JobBoard> => {
    const response = await axios.put(paths.updateJobBoard(id), { name, about, status });
    return toJobBoard(response.data);
  },
  deleteJobBoard: async (id: string): Promise<void> => {
    await axios.delete(paths.deleteJobBoard(id));
  },
  uploadPictures: async (picture: PictureBase64CreationRequest): Promise<PictureRef> => {
    const response = await axios.post(paths.uploadPicture(), picture);
    return response.data as PictureRef;
  },
  downloadPicture: async (id: string): Promise<string> => {
    return axios.get(paths.getPicture(id)).then(r => r.data);
  },
  updateJobBoardPicture: async (jobBoardId: string, pictureId: string): Promise<JobBoard> => {
    const response = await axios.put(paths.updateJobBoardPicture(jobBoardId), { pictureId });
    return toJobBoard(response.data);
  },
};

export default api;

export interface PictureBase64CreationRequest {
  data: string;
  contentType: string;
}
