/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from '../utils/api';
import { JobBoardDetail } from '../model/JobBoardDetail';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
import {
  Pagination,
  Product,
  productContainsCountryCodes,
  productContainsString,
  ProductCreationRequest,
  SortField,
  SortOrder,
  toProductUpdateRequest,
} from '../model/product';
import { toastService } from '../../../core/services/toastService';
// eslint-disable-next-line import/no-cycle
import { selectUserCountryCodes } from '../../auth/store/userSlice';
import { Country, getCountryCodesByRegion } from '../model/countries';

interface JobBoardDetailSliceState {
  value: JobBoardDetail | null;
  isFetching: boolean;
  error: string;
  pagination: Pagination;
  sort: SortField;
  filter: string;
  regionFilter: string | null;
  countryFilter: Country | null;
}

const initialState = {
  value: null,
  isFetching: false,
  error: '',
  pagination: { page: 1, limit: 10 },
  sort: { sortColumn: 'name', sortOrder: 'ascending' },
  filter: '',
  regionFilter: null,
  countryFilter: null,
} as JobBoardDetailSliceState;

export const jobBoardDetailSlice = createSlice({
  name: 'jobBoardDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<JobBoardDetailSliceState>) => ({
      ...state,
      pagination: { page: 1, limit: 10 },
      sort: { sortColumn: 'name', sortOrder: 'ascending' } as SortField,
      filter: '',
      isFetching: true,
    }),
    finishFetch: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<JobBoardDetail>) => {
      return {
        ...state,
        isFetching: false,
        value: payload,
        error: '',
      };
    },
    httpError: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    reset: () => initialState,
    change: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<JobBoardDetail>) => {
      return {
        ...state,
        isFetching: false,
        value: payload,
        error: '',
      };
    },
    setPagination: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<Pagination>) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setSort: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<SortField>) => {
      return {
        ...state,
        sort: payload,
      };
    },
    setFilter: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        filter: payload,
      };
    },
    updateJobBoardDetail: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<JobBoardDetail>) => {
      return {
        ...state,
        value: payload,
        error: '',
      };
    },
    changeRegionFilter: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<string | null>) => {
      return { ...state, regionFilter: payload };
    },
    changeCountryFilter: (state: Draft<JobBoardDetailSliceState>, { payload }: PayloadAction<Country | null>) => {
      return { ...state, countryFilter: payload };
    },
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  change,
  setPagination,
  setSort,
  setFilter,
  updateJobBoardDetail,
  changeRegionFilter,
  changeCountryFilter,
} = jobBoardDetailSlice.actions;

export const fetchJobBoardDetail =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const jobBoard = await api.getJobBoardDetail(id);
      dispatch(finishFetch(jobBoard));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const onSort =
  (clickedColumn: string): AppThunk =>
  (dispatch, state) => {
    const { sortColumn, sortOrder } = state().jobBoardDetail.sort;
    const { pagination } = state().jobBoardDetail;

    let newOrder: SortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
    if (sortColumn !== clickedColumn) {
      newOrder = 'ascending';
    }

    dispatch(setPagination({ ...pagination, page: 1 }));
    dispatch(setSort({ sortColumn: clickedColumn, sortOrder: newOrder }));
  };

export const onSubmitFilter =
  (value: string): AppThunk =>
  (dispatch, state) => {
    const { filter } = state().jobBoardDetail;
    if (value !== filter) {
      const { pagination } = state().jobBoardDetail;
      dispatch(setFilter(value));
      dispatch(setPagination({ ...pagination, page: 1 }));
    }
  };

export const onChangeLimit =
  (limit: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().jobBoardDetail;
    if (limit !== pagination.limit) {
      dispatch(setPagination({ limit, page: 1 }));
    }
  };

export const onChangePage =
  (page: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().jobBoardDetail;
    if (page !== pagination.page) {
      dispatch(setPagination({ ...pagination, page }));
    }
  };

export const selectJobBoardDetailState = (state: RootState) => state.jobBoardDetail;
export const selectJobBoardDetail = (state: RootState) => state.jobBoardDetail.value;
export const selectIsFetchingJobBoardDetail = (state: RootState) => state.jobBoardDetail.isFetching;

export const selectSort = (state: RootState) => state.jobBoardDetail.sort;
export const selectFilter = (state: RootState) => state.jobBoardDetail.filter;
export const selectPagination = (state: RootState) => state.jobBoardDetail.pagination;
export const selectPaginationLimit = (state: RootState) => state.jobBoardDetail.pagination.limit;
export const selectRegionFilter = (state: RootState) => state.jobBoardDetail.regionFilter;
export const selectCountryFilter = (state: RootState) => state.jobBoardDetail.countryFilter;

const selectFilteredProducts = createSelector(
  selectJobBoardDetail,
  selectRegionFilter,
  selectCountryFilter,
  selectFilter,
  selectUserCountryCodes,
  (detail, regionFilter, countryFilter, filter, countryCodes) => {
    return detail?.products
      .filter(p => !regionFilter || productContainsCountryCodes(p, getCountryCodesByRegion(regionFilter)))
      .filter(p => !countryFilter?.code || productContainsCountryCodes(p, [countryFilter.code]))
      .filter(p => productContainsString(p, filter))
      .filter(p => productContainsCountryCodes(p, countryCodes));
  }
);

const selectFilteredAndSortedProducts = createSelector(selectFilteredProducts, selectSort, (filteredProducts, sort) => {
  return sort.sortOrder === 'descending'
    ? _.sortBy(filteredProducts, [sort.sortColumn]).reverse()
    : _.sortBy(filteredProducts, [sort.sortColumn]);
});

export const selectFilteredAndSortedAndPaginatedProducts = createSelector(
  selectFilteredAndSortedProducts,
  selectPagination,
  (sortedData, pagination) => {
    const startIndex = pagination.page * pagination.limit - pagination.limit;
    const endIndex = startIndex + pagination.limit;
    return sortedData?.slice(startIndex, endIndex);
  }
);

export const selectTotalPagesAndCount = createSelector(
  selectFilteredProducts,
  selectPaginationLimit,
  (filteredData, paginationLimit) => {
    return {
      totalPages: Math.ceil((filteredData?.length || 0) / paginationLimit),
      totalCount: filteredData?.length || 0,
    };
  }
);

export const createJobBoardProduct =
  (jobBoardId: string, request: ProductCreationRequest): AppThunk =>
  async (dispatch): Promise<JobBoardDetail | null> => {
    try {
      const updatedJobBoardDetail = await api.createJobBoardProduct(jobBoardId, request);
      dispatch(updateJobBoardDetail(updatedJobBoardDetail));
      toastService.success();
      return updatedJobBoardDetail;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const updateJobBoardProduct =
  (jobBoardId: string, product: Product): AppThunk =>
  async (dispatch): Promise<JobBoardDetail | null> => {
    try {
      const updatedJobBoardDetail = await api.updateJobBoardProduct(
        jobBoardId,
        product.id,
        toProductUpdateRequest(product)
      );
      dispatch(updateJobBoardDetail(updatedJobBoardDetail));
      toastService.success();
      return updatedJobBoardDetail;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const deleteProduct =
  (jobBoardId: string, product: Product): AppThunk =>
  async (dispatch): Promise<JobBoardDetail | null> => {
    try {
      const deleteJobBoardDetail = await api.deleteJobBoardProduct(jobBoardId, product.id);
      dispatch(updateJobBoardDetail(deleteJobBoardDetail));
      toastService.success();
      return deleteJobBoardDetail;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export default jobBoardDetailSlice.reducer;
