/** @jsxImportSource @emotion/react */
import Dinero, { Currency } from 'dinero.js';
import React from 'react';

import { Box, Flex } from 'rebass';

interface ViewPriceProps {
  currency: string | undefined;
  value: number | undefined;
}

export const ViewPrice = ({ currency, value }: ViewPriceProps): JSX.Element => {
  const c = Math.floor((value || 0) * 100);
  const price =
    currency != null
      ? Dinero({
          amount: c,
          currency: currency as Currency,
        })
      : null;

  return price ? (
    <Flex>
      <Box width={1}>{price.toFormat()}</Box>
    </Flex>
  ) : (
    <> </>
  );
};
