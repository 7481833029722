/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, NavigateFunction } from 'react-router-dom';

import './App.css';
import './i18n';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Login } from './features/auth/pages/Login';
import { OAUTH_CLIENT_ID, OAUTH_TOKEN_URL, REDIRECT_URI } from './constants';
import api from './features/auth/utils/api';
import {
  getJwtToken,
  getRedirectPagePath,
  resetRedirectPagePath,
  setJwtToken,
} from './core/services/webStorageService';
import { fetchUser } from './features/auth/store/userSlice';
import { OauthReceiver } from './features/auth/components/OauthReceiver';
import { ForgotPassword } from './features/auth/pages/ForgotPassword';
import { ResetPassword } from './features/auth/pages/ResetPassword';
import { PageUnderSecurity } from './shared/PageUnderSecurity';
import { JobBoardList } from './features/jobBoards/pages/JobBoardList';
import { JobBoardDetail } from './features/jobBoards/pages/JobBoardDetail';
import { ProductList } from './features/products/pages/ProductList';
import { Basket } from './features/basket/pages/Basket';
import { ProductDetail } from './features/products/pages/productDetail';
import { JobBoardProductDetail } from './features/jobBoards/pages/jobBoardProductDetail';
import { CompanyProfilesList } from './features/companyProfiles/pages/CompanyProfilesList';
import { Orders } from './features/orders/pages/Orders';
import { BrokerOrders } from './features/brokerOrders/pages/BrokerOrders';
import { OrderDetails } from './features/orders/pages/OrderDetails';
import { UsersAdmin } from './features/usersAdmin/pages/UsersAdmin';
import { BrandOrders } from './features/brokerOrders/pages/BrandOrders';
import { ContactAdmin } from './features/auth/pages/ContactAdmin';
import { useAppDispatch } from './core/coreHooks';

const App = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const refreshAndSetTimeout = async () => {
    const response = await api.refreshToken();
    setTokenAndTimeout(response.data.access_token);
  };

  const setTokenAndTimeout = (token: string) => {
    setJwtToken(token);
    setTimeout(async () => {
      await refreshAndSetTimeout();
    }, 45 * 60000);
    dispatch(fetchUser());
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleExternalAuthSuccess = async (token: string, navigate: NavigateFunction) => {
    await setTokenAndTimeout(token);
    navigate(getRedirectPagePath());
    setTimeout(resetRedirectPagePath, 2000); //
  };

  useEffect(() => {
    const token = getJwtToken();
    if (token) {
      refreshAndSetTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path='/' element={<Navigate to='job-boards' replace />} />
      <Route
        path='/job-boards'
        element={
          <PageUnderSecurity title={t('menu.jobBoardList')}>
            <JobBoardList />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/job-boards/:id'
        element={
          <PageUnderSecurity backButton title='Job board detail'>
            <JobBoardDetail />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/job-boards/:id/products/:productId'
        element={
          <PageUnderSecurity backButton title={t('menu.productDetail')}>
            <JobBoardProductDetail />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/products'
        element={
          <PageUnderSecurity title={t('menu.productList')}>
            <ProductList />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/products/:productId'
        element={
          <PageUnderSecurity backButton title={t('menu.productDetail')}>
            <ProductDetail />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/basket'
        element={
          <PageUnderSecurity title={t('menu.basket')}>
            <Basket />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/company-profiles'
        element={
          <PageUnderSecurity title={t('menu.companyProfiles')}>
            <CompanyProfilesList />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/orders'
        element={
          <PageUnderSecurity title={t('menu.orders')}>
            <Orders />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/orders/:orderId'
        element={
          <PageUnderSecurity title={t('menu.order')}>
            <OrderDetails />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/client-orders'
        element={
          <PageUnderSecurity title={t('menu.clientOrders')}>
            <BrokerOrders />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/brand-orders'
        element={
          <PageUnderSecurity title={t('menu.brandOrders')}>
            <BrandOrders />
          </PageUnderSecurity>
        }
      />
      <Route
        path='/users-admin'
        element={
          <PageUnderSecurity title={t('menu.usersAdmin')}>
            <UsersAdmin />
          </PageUnderSecurity>
        }
      />

      <Route
        path='/login'
        element={
          <Login
            onExternalAuthSuccess={(token: string) => {
              handleExternalAuthSuccess(token, navigate).then();
            }}
          />
        }
      />
      <Route path='/forgot-password' element={ForgotPassword()} />
      <Route path='/reset-password' element={ResetPassword()} />
      <Route
        path='/login-callback'
        element={
          <OauthReceiver
            location={location}
            tokenUrl={OAUTH_TOKEN_URL}
            redirectUri={REDIRECT_URI}
            clientId={OAUTH_CLIENT_ID}
            onAuthSuccess={(token: string) => {
              setTokenAndTimeout(token);
            }}
            onAuthError={(err: AxiosError) => {
              // eslint-disable-next-line no-console
              console.error('err', err);
              navigate('login-error');
            }}
            render={(processing: boolean) => {
              const redirectPath = getRedirectPagePath();
              setTimeout(resetRedirectPagePath, 2000);
              return processing ? (
                <Dimmer active>
                  <Loader size='big' />
                </Dimmer>
              ) : (
                <Navigate to={redirectPath} />
              );
            }}
          />
        }
      />
      <Route path='/login-error' element={<ContactAdmin />} />
    </Routes>
  );
};

export default App;
