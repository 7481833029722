// eslint-disable-next-line import/no-cycle
import { ContentRef, Price, PRODUCT_TYPE } from '../../jobBoards/model/product';
import { Company, toCompany } from '../../companyProfiles/model/companyProfile';
import { REGION } from '../../jobBoards/model/countries';

export interface ProductOrder {
  id: string;
  name: string;
  description: string;
  productType: PRODUCT_TYPE;
  region: REGION;
  countryCodes: string[];
  target: string;
  marketPrice: number;
  marketPriceCurrency: string;
  priceFrom: number;
  priceFromCurrency: string;
  priceTo: number;
  priceToCurrency: string;
  finalPrice?: Price;
  validityEndDate?: string;
  jobBoardName: string;
  jobBoardId: string;
  contents: ContentRef[];
}

export interface Order {
  id: string;
  company: Company;
  lastUpdate: string;
  creationDate: string;
  closedDate: string;
  acceptanceStatus: ACCEPTANCE_STATUS;
  quantity: number;
  brokerOrderId: string;
  brokerOrderStatus: ORDER_STATUS;
  product: ProductOrder;
  finalPrice: Price | null;
  totalPrice: Price;
  contents: ContentRef[];
  brands: string[];
  country: string;
}

export enum ACCEPTANCE_STATUS {
  TO_BE_ACCEPTED = 'TO_BE_ACCEPTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum ORDER_STATUS {
  PRE_ORDER = 'PRE_ORDER',
  PRICED = 'PRICED',
  FINAL_CONFIRMATION = 'FINAL_CONFIRMATION',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
}

export const toProductOrder = (o: Record<string, unknown>): ProductOrder => {
  return {
    id: o.id as string,
    name: o.name as string,
    description: o.description as string,
    productType: o.productType as PRODUCT_TYPE,
    region: o.region as REGION,
    countryCodes: o.countryCodes as string[],
    target: o.target as string,
    marketPrice: (o.marketPrice as Price).value,
    marketPriceCurrency: (o.marketPrice as Price).currency,
    priceFrom: (o.priceFrom as Price).value,
    priceFromCurrency: (o.priceFrom as Price).currency,
    priceTo: (o.priceTo as Price).value,
    priceToCurrency: (o.priceTo as Price).currency,
    finalPrice: o.finalPrice as Price,
    validityEndDate: o.validityEndDate as string,
    jobBoardName: o.jobBoardName as string,
    jobBoardId: o.jobBoardId as string,
    contents: o.contents as ContentRef[],
  };
};

export const toOrder = (o: Record<string, unknown>): Order => {
  return {
    acceptanceStatus: o.acceptanceStatus as ACCEPTANCE_STATUS,
    brokerOrderId: o.brokerOrderId as string,
    brokerOrderStatus: o.brokerOrderStatus as ORDER_STATUS,
    creationDate: o.creationDate as string,
    closedDate: o.brokerOrderClosedDate as string,
    company: toCompany(o.company as Record<string, unknown>),
    product: toProductOrder(o.product as Record<string, unknown>),
    quantity: o.quantity as number,
    id: o.id as string,
    lastUpdate: o.lastUpdate as string,
    finalPrice: o.finalPrice as Price,
    totalPrice: o.totalPrice as Price,
    contents: o.contents as ContentRef[],
    brands: o.brands as string[],
    country: o.country as string,
  };
};
