/** @jsxImportSource @emotion/react */
import React from 'react';
import { Pagination, PaginationProps, Table } from 'semantic-ui-react';
import { Product } from '../../model/product';
import { ProductRow } from './ProductRow';
import { ProductTableHeader } from './ProductTableHeader';
import style from './productTable.style';

interface ProductTableProps {
  elements: Product[];
  totalPages: number;
  currentPage: number;
  column?: string;
  direction?: 'ascending' | 'descending';
  onChangePage(page: number): void;
  handleSort(clickedColumn: string): void;
  onClickRow?(product: Product): void;
  withJobBoardColumns?: boolean;
  onClickCloneProduct?: (product: Product) => void;
}

export const ProductTable: React.FC<ProductTableProps> = ({
  elements,
  totalPages,
  column,
  currentPage,
  direction,
  handleSort,
  onChangePage,
  onClickRow,
  withJobBoardColumns,
  onClickCloneProduct,
}) => {
  const productRows = elements.map((product, index) => (
    <ProductRow
      // eslint-disable-next-line react/no-array-index-key
      key={`${product.name}-${index}`}
      product={product}
      withJobBoardColumns={withJobBoardColumns}
      onClickRow={onClickRow}
      onClickCloneProduct={onClickCloneProduct}
    />
  ));
  const handleChangePage = (event: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
    onChangePage(activePage as number);
  };

  return (
    <>
      <div css={style.tableContainer}>
        <Table celled selectable sortable striped fixed>
          <ProductTableHeader
            column={column}
            direction={direction}
            handleSort={handleSort}
            withJobBoardColumns={withJobBoardColumns}
            withCloneColumn={onClickCloneProduct != null}
          />
          <Table.Body>{productRows}</Table.Body>
        </Table>
        {productRows.length === 0 && <div style={{ margin: 'auto', fontWeight: 500 }}>No products</div>}
      </div>
      <Pagination
        css={style.paginationContainer}
        totalPages={totalPages}
        activePage={currentPage}
        onPageChange={handleChangePage}
      />
    </>
  );
};
