/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dimmer, Icon } from 'semantic-ui-react';
import style from './modalPage.style';

interface ModalPageProps {
  children?: React.ReactNode;
  onClose: () => void;
  title?: string;
}

export const ModalPage: React.FunctionComponent<ModalPageProps> = (props): JSX.Element => {
  const { children, onClose, title } = props;

  return (
    <Dimmer active css={style.dimmer}>
      <Header onClose={onClose} title={title} />
      <div css={style.container}>{children}</div>
    </Dimmer>
  );
};

interface HeaderProps {
  onClose: () => void;
  title?: string;
}

const Header = ({ onClose, title }: HeaderProps): JSX.Element => {
  return (
    <div css={style.header}>
      <div css={style.title}>{title}</div>
      <Icon name='times' onClick={onClose} css={style.icon} />
    </div>
  );
};
