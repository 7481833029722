/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../core/store';
import { Country } from '../model/countries';

interface JobBoardsFilterSliceState {
  filter: string;
  regionFilter: string | null;
  countryFilter: Country | null;
}

const initialState = {
  filter: '',
  regionFilter: null,
  countryFilter: null,
} as JobBoardsFilterSliceState;

export const jobBoardsFilterSlice = createSlice({
  name: 'jobBoardsFilter',
  initialState,
  reducers: {
    changeFilter: (state: Draft<JobBoardsFilterSliceState>, { payload }: PayloadAction<string>) => {
      return { ...state, filter: payload };
    },
    changeRegionFilter: (state: Draft<JobBoardsFilterSliceState>, { payload }: PayloadAction<string | null>) => {
      return { ...state, regionFilter: payload };
    },
    changeCountryFilter: (state: Draft<JobBoardsFilterSliceState>, { payload }: PayloadAction<Country | null>) => {
      return { ...state, countryFilter: payload };
    },
  },
});

export const { changeFilter, changeRegionFilter, changeCountryFilter } = jobBoardsFilterSlice.actions;

export const selectJobBoardsFilter = (state: RootState) => state.jobBoardsFilter.filter;
export const selectJobBoardsRegionFilter = (state: RootState) => state.jobBoardsFilter.regionFilter;
export const selectJobBoardsCountryFilter = (state: RootState) => state.jobBoardsFilter.countryFilter;

export default jobBoardsFilterSlice.reducer;
