import axios from 'axios';
import paths from './paths';
import { Order, toOrder } from '../model/order';
// eslint-disable-next-line import/no-cycle
import { THREE_MONTHS } from '../../brokerOrders/store/brokerOrderSlice';
import { Company } from '../../companyProfiles/model/companyProfile';

const api = {
  getOrders: async (periodFilter: string): Promise<Order[]> => {
    const yearFilter = periodFilter === THREE_MONTHS ? '' : `?creationYear=${periodFilter}`;
    const response = await axios.get(paths.getOrders(yearFilter));
    return response.data.map((o: Record<string, unknown>) => toOrder(o));
  },
  getOrder: async (orderId: string): Promise<Order> => {
    const response = await axios.get(paths.getOrder(orderId));
    return toOrder(response.data);
  },
  acceptPrice: async (id: string, quantity: number): Promise<Order> => {
    const response = await axios.put(paths.getAcceptPrice(id), { quantity });
    return toOrder(response.data);
  },
  rejectPrice: async (id: string): Promise<Order> => {
    const response = await axios.put(paths.gerRejectPrice(id));
    return toOrder(response.data);
  },
  changeQuantity: async (id: string, quantity: number): Promise<Order> => {
    const response = await axios.put(paths.getUpdateQuantity(id), { quantity });
    return toOrder(response.data);
  },
  updateCompany: async (id: string, company: Company): Promise<Order> => {
    const response = await axios.put(paths.getUpdateOrderCompany(id), company);
    return toOrder(response.data);
  },
};

export default api;
