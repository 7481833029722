/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next';
import React from 'react';
import { Box, Flex } from 'rebass';
import { Button, Input, Modal } from 'semantic-ui-react';
import Moment from 'react-moment';
import { useAppDispatch } from '../../../core/coreHooks';
import style from './orderFinalPrice.style';
import { acceptPrice, rejectPrice } from '../store/orderSlice';
import { ACCEPTANCE_STATUS, Order, ORDER_STATUS } from '../model/order';
import { Price } from '../../jobBoards/model/product';
import { ViewPrice } from '../../../shared/ViewPrice';

export const OrderFinalPrice = ({ order: o }: { order: Order }): JSX.Element => {
  return o.brokerOrderStatus === ORDER_STATUS.PRICED &&
    o.acceptanceStatus === ACCEPTANCE_STATUS.TO_BE_ACCEPTED &&
    o.finalPrice ? (
    <EditOrderFinalPrice order={o} />
  ) : (
    <Flex flexWrap='wrap' height='100%' flexDirection='column'>
      <ViewOrderFinalPrice
        unitPrice={o.finalPrice}
        quantity={o.quantity}
        totalPrice={o.totalPrice}
        closedDate={o.closedDate}
      />
    </Flex>
  );
};

const EditOrderFinalPrice = ({ order: o }: { order: Order }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [quantity, setQuantity] = React.useState<number>(o.quantity);
  const [totalPrice, setTotalPrice] = React.useState<Price>(o.totalPrice);

  const onChangeQuantity = (qty: number) => {
    if (qty > -1) {
      setQuantity(qty);
      if (o.finalPrice != null) {
        setTotalPrice({
          ...o.totalPrice,
          value: o.finalPrice.value * qty,
        });
      }
    }
  };

  const acceptPriceHandler = () => {
    dispatch(acceptPrice(o.id, quantity));
  };

  const rejectPriceHandler = () => {
    dispatch(rejectPrice(o.id));
  };

  return (
    <Flex flexWrap='wrap' height='100%' flexDirection='column'>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 3} css={style.priceToLabel as never}>
            {t('order.finalUnitPrice')}
          </Box>
          <Box width={2 / 3} css={style.priceTo as never}>
            <ViewPrice currency={o.finalPrice?.currency} value={o.finalPrice?.value} />
          </Box>
        </Flex>
      </Box>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 3} css={style.priceToLabel as never}>
            {t('order.quantity')}
          </Box>
          <Box width={2 / 3}>
            <Input
              css={style.quantity}
              fluid
              placeholder={t('order.changeQuantity')}
              value={quantity}
              type='number'
              onChange={(event, data) => onChangeQuantity(Number(data.value))}
            />
          </Box>
        </Flex>
      </Box>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 3} css={style.priceToLabel as never}>
            {t('order.totalPrice')}
          </Box>
          <Box width={2 / 3} css={style.priceTo as never}>
            <ViewPrice currency={totalPrice.currency} value={totalPrice.value} />
          </Box>
        </Flex>
      </Box>
      <Box width={1} style={{ marginTop: 'auto' }} mb={2}>
        <Flex flexWrap='wrap'>
          <Box width={1} padding='16px 4px 8px'>
            {t('order.acceptFinalPriceQuestion')}
          </Box>
          <Box width={1}>
            <Flex justifyContent='flex-end'>
              <Box width={1} p={1}>
                <Modal
                  size='tiny'
                  trigger={<Button fluid>{t('order.accept')}</Button>}
                  header={t('order.acceptFinalPrice')}
                  content={
                    <Flex flexDirection='column'>
                      <Box width={1} p={2} maxWidth='300px' margin='auto'>
                        <ViewOrderFinalPrice unitPrice={o.finalPrice} quantity={quantity} totalPrice={totalPrice} />
                      </Box>
                      <Box padding='1.5rem'>{t('order.acceptFinalPriceMessage')}</Box>
                    </Flex>
                  }
                  actions={['Cancel', { key: 'done', content: 'Ok', onClick: acceptPriceHandler }]}
                />
              </Box>
              <Box width={1} p={1}>
                <Modal
                  size='tiny'
                  trigger={<Button fluid>{t('order.reject')}</Button>}
                  header={t('order.rejectFinalPrice')}
                  content={t('order.rejectFinalPriceMessage')}
                  actions={['Cancel', { key: 'done', content: 'Ok', onClick: rejectPriceHandler }]}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

interface ViewOrderFinalPriceProps {
  unitPrice: Price | null;
  quantity: number;
  totalPrice: Price;
  closedDate?: string;
}

const ViewOrderFinalPrice = ({
  unitPrice,
  quantity,
  totalPrice,
  closedDate,
}: ViewOrderFinalPriceProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {unitPrice && (
        <Box width={1} p={2}>
          <Flex justifyContent='flex-end' alignItems='center'>
            <Box width={1 / 2} css={style.priceToLabel as never}>
              {t('order.finalUnitPrice')}
            </Box>
            <Box width={1 / 2} css={style.priceTo as never}>
              <ViewPrice currency={unitPrice.currency} value={unitPrice.value} />
            </Box>
          </Flex>
        </Box>
      )}
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 2} css={style.priceToLabel as never}>
            {t('order.quantity')}
          </Box>
          <Box width={1 / 2} css={style.priceTo as never}>
            {quantity}
          </Box>
        </Flex>
      </Box>
      <Box width={1} p={2}>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Box width={1 / 2} css={style.priceToLabel as never}>
            {t('order.totalPrice')}
          </Box>
          <Box width={1 / 2} css={style.priceTo as never}>
            <ViewPrice currency={totalPrice.currency} value={totalPrice.value} />
          </Box>
        </Flex>
      </Box>
      {closedDate != null && (
        <Box width={1} p={2}>
          <Flex justifyContent='flex-end' alignItems='center'>
            <Box width={1} css={style.date as never}>
              {t('order.closedDate')}{' '}
              <strong>
                <Moment format='YYYY/MM/DD HH:mm'>{closedDate}</Moment>
              </strong>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};
