import { css } from '@emotion/react';

const form = css`
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &&&& button {
    margin-top: auto;
    margin-left: auto;
    width: min(200px, 40%);
    margin-bottom: 5px;
  }
`;

const label = css`
  float: left;
`;

const picturesListContainer = css`
  padding: 0 10px 10px;
`;

export default {
  form,
  label,
  picturesListContainer,
};
