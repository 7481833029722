import { css } from '@emotion/react';

const container = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--astra-primary-color-10);

  div {
    font-size: 1.2rem;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--astra-white-color);
    height: 220px;
    width: 100%;
    max-width: 1200px;
  }
`;

export default {
  container,
};
