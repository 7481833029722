/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { AppDispatch } from '../../../core/store';
import style from './usersAdmin.style';
import { UserCard } from '../components/UserCard';
import {
  createManagedUser,
  fetchManagedUsers,
  selectIsFetchingManagedUsers,
  selectManagedUsers,
  selectManagedUsersFilter,
  setManagedUsersFilter,
  updateManagedUser,
} from '../store/usersAdminSlice';
import { ModalPage } from '../../../shared/ModalPage';
import { ManagedUser, ManagedUserCreateRequest } from '../model/managedUser';
import { UpdateManagedUser } from '../components/UpdateManagedUser';
import { checkRoles, ROLE } from '../../auth/model/user';
import { fetchUser, selectUser } from '../../auth/store/userSlice';
import { CreateManagedUser } from '../components/CreateManagedUser';
import { SearchBox } from '../../../shared/SearchBox';
import { useAppDispatch } from '../../../core/coreHooks';

export const UsersAdmin = (): JSX.Element => {
  const isFetching = useSelector(selectIsFetchingManagedUsers);
  const dispatch: AppDispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedManagedUSerForEdit, setSelectedManagedUSerForEdit] = useState<null | ManagedUser>(null);

  useEffect(() => {
    dispatch(fetchManagedUsers());
  }, [dispatch]);

  const users = useSelector(selectManagedUsers);

  const onUpdateManagedUserHandler = (user: ManagedUser) => {
    dispatch(updateManagedUser(user));
    dispatch(fetchUser());
    setSelectedManagedUSerForEdit(null);
  };

  return isFetching ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : (
    <>
      <Header />
      {selectedManagedUSerForEdit && (
        <ModalPage
          title={`${selectedManagedUSerForEdit.email} - ${selectedManagedUSerForEdit.type}`}
          onClose={() => setSelectedManagedUSerForEdit(null)}>
          <UpdateManagedUser
            user={selectedManagedUSerForEdit}
            onSubmit={(user: ManagedUser) => onUpdateManagedUserHandler(user)}
          />
        </ModalPage>
      )}
      <div css={style.container}>
        {users && (
          <Flex flexWrap='wrap'>
            <Box width={[1]} p={2}>
              <div>
                {users.map(u => (
                  <UserCard key={u.id} user={u} onUserEdit={() => setSelectedManagedUSerForEdit(u)} />
                ))}
              </div>
            </Box>
          </Flex>
        )}
        {users && users.length === 0 && (
          <div css={style.emptyContainer}>
            <div>{t('managedUser.noUsers')}</div>
          </div>
        )}
      </div>
    </>
  );
};

const Header = (): JSX.Element => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSave = async (request: ManagedUserCreateRequest) => {
    const r = await dispatch(createManagedUser(request));
    if (r != null) {
      setCreateModalOpen(false);
    }
  };

  const user = useSelector(selectUser);
  const isAdmin = useCallback(() => checkRoles(user, [ROLE.ADMIN]), [user]);

  return (
    <div css={style.header}>
      {isCreateModalOpen && (
        <ModalPage title={t('managedUser.createModal')} onClose={() => setCreateModalOpen(false)}>
          <CreateManagedUser onSave={onSave} />
        </ModalPage>
      )}
      {isAdmin() && (
        <div>
          <Button
            icon='plus'
            primary
            css={style.addButton}
            data-testid='create-admin-user-button'
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
      )}
      <div css={style.searchBox}>
        <SearchUserBox />
      </div>
    </div>
  );
};

const SearchUserBox = (): JSX.Element => {
  const filter = useSelector(selectManagedUsersFilter, () => true);
  const dispatch: AppDispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(setManagedUsersFilter(value));
  }, 500);

  return <SearchBox defaultValue={filter} onChange={value => debounced(value)} />;
};
