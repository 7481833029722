import { css } from '@emotion/react';

const productName = css`
  font-size: 1.5rem !important;
  font-weight: 500;
  padding: 0.5em !important;
  &&&& a {
    color: var(--jbmp-primary-color);
  }
`;

const text = css`
  padding: 0.5833em;
  text-align: left;
`;

const date = css`
  text-align: right;
  font-size: 0.8rem;
  font-weight: 300;
`;

const creationDate = css`
  font-size: 0.8rem;
  font-weight: 300;
  padding-left: 0.5833em;
`;

const orderPriceContainer = css`
  @media (min-width: 832px) {
    border-left: 2px solid var(--jbmp-blue-10);
    padding-left: 8px;
  }
`;

export default {
  text,
  date,
  productName,
  creationDate,
  orderPriceContainer,
};
