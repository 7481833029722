import { css } from '@emotion/react';
import style from './global.style';

const container = css`
  background-color: var(--jbmp-primary-color-10);
  height: calc(100% - 60px);
  overflow: auto;
`;

const header = css`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: var(--jbmp-primary-color);
  align-items: center;
`;

const title = css`
  && {
    ${style.subtitleNormal}
    margin: 0;
    color: var(--jbmp-white-color);
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const icon = css`
  && {
    justify-self: flex-end;
    margin-right: 10px;
    margin-left: auto;
    flex-shrink: 0;
    font-size: 1.714rem;
    cursor: pointer;
    :hover {
      color: var(--jbmp-primary-color-20);
    }
  }
`;

const dimmer = css`
  && {
    padding: 0;
    .content {
      width: calc(100% - 20px);
      height: calc(100% - 50px);
      max-width: 800px;
      max-height: 1200px;
    }
  }
`;

export default {
  container,
  header,
  dimmer,
  icon,
  title,
};
