export const BRANDS: Brand[] = [
  { key: 'AlexanderMcQueen', name: 'AlexanderMcQueen' },
  { key: 'Balenciaga', name: 'Balenciaga' },
  { key: 'BottegaVeneta', name: 'BottegaVeneta' },
  { key: 'Boucheron', name: 'Boucheron' },
  { key: 'Brioni', name: 'Brioni' },
  { key: 'Dodo', name: 'Dodo' },
  { key: 'Gucci', name: 'Gucci' },
  { key: 'KeringAMER', name: 'KeringAMER' },
  { key: 'KeringAPAC', name: 'KeringAPAC' },
  { key: 'KeringBeauté', name: 'KeringBeauté' },
  { key: 'KeringEyewear', name: 'KeringEyewear' },
  { key: 'KeringSA', name: 'KeringSA' },
  { key: 'KeringSPA', name: 'KeringSPA' },
  { key: 'KeringUK', name: 'KeringUK' },
  { key: 'Pomellato', name: 'Pomellato' },
  { key: 'Qeelin', name: 'Qeelin' },
  { key: 'RichardGinori', name: 'RichardGinori' },
  { key: 'YvesSaintLaurent', name: 'YvesSaintLaurent' },
];

export interface Brand {
  key: string;
  name: string;
}

export interface BrandsOption {
  key: string;
  text: string;
  value: string;
}
