/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
// circular dependency for type export is not a real circular dependency
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../core/store';
import { toastService } from '../../../core/services/toastService';
import api from '../utils/api';
import { CompanyProfile, CompanyProfileRequest, toCompanyProfileRequest } from '../model/companyProfile';

interface CompanyProfilesSliceState {
  value: CompanyProfile[];
  isFetching: boolean;
  error: string;
}

const initialState = {
  value: [],
  isFetching: false,
  error: '',
} as CompanyProfilesSliceState;

export const companyProfilesSlice = createSlice({
  name: 'companyProfiles',
  initialState,
  reducers: {
    startFetch: (state: Draft<CompanyProfilesSliceState>): CompanyProfilesSliceState => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<CompanyProfilesSliceState>,
      action: PayloadAction<CompanyProfile[]>
    ): CompanyProfilesSliceState => {
      return {
        ...state,
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    setCompanyProfile: (state: Draft<CompanyProfilesSliceState>, action: PayloadAction<CompanyProfile>) => {
      const foundCompanyProfileIndex = state.value.findIndex(p => p.id === action.payload.id);
      if (foundCompanyProfileIndex > -1) {
        state.value[foundCompanyProfileIndex] = action.payload;
      }
    },
    removeCompanyProfile: (state: Draft<CompanyProfilesSliceState>, action: PayloadAction<string>) => {
      state.value = state.value.filter(p => p.id !== action.payload);
    },
    addCompanyProfile: (state: Draft<CompanyProfilesSliceState>, action: PayloadAction<CompanyProfile>) => {
      state.value.push(action.payload);
    },
    httpError: (state: Draft<CompanyProfilesSliceState>, action: PayloadAction<string>): CompanyProfilesSliceState => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
    change: (
      state: Draft<CompanyProfilesSliceState>,
      { payload }: PayloadAction<CompanyProfile[]>
    ): CompanyProfilesSliceState => {
      return {
        isFetching: false,
        value: payload,
        error: '',
      };
    },
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  change,
  setCompanyProfile,
  addCompanyProfile,
  removeCompanyProfile,
} = companyProfilesSlice.actions;

export const fetchCompanyProfiles = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const companyProfiles = await api.getCompanyProfiles();
    dispatch(finishFetch(companyProfiles));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const updateCompanyProfile =
  (companyProfile: CompanyProfile): AppThunk =>
  async (dispatch): Promise<CompanyProfile | null> => {
    try {
      const updatedCompanyProfile = await api.updateCompanyProfile(
        companyProfile.id,
        toCompanyProfileRequest(companyProfile)
      );
      await dispatch(setCompanyProfile(updatedCompanyProfile));

      toastService.success();
      return updatedCompanyProfile;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const deleteCompanyProfile =
  (id: string): AppThunk =>
  async (dispatch): Promise<null> => {
    try {
      await api.deleteCompanyProfile(id);
      await dispatch(removeCompanyProfile(id));

      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
    return null;
  };

export const createCompanyProfile =
  (name: string, address: string, vatNumber: string, invoiceContact: string): AppThunk =>
  async (dispatch): Promise<CompanyProfile | null> => {
    try {
      const createdCompanyProfile = await api.createCompanyProfile({
        name,
        address,
        vatNumber,
        invoiceContact,
      } as CompanyProfileRequest);
      await dispatch(addCompanyProfile(createdCompanyProfile));
      toastService.success();
      return createdCompanyProfile;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const selectCompanyProfilesState = (state: RootState) => state.companyProfiles;
export const selectCompanyProfiles = (state: RootState) => state.companyProfiles.value;
export const selectIsFetchingCompanyProfiles = (state: RootState) => state.companyProfiles.isFetching;
export const selectDefaultCompanyProfile = (state: RootState) =>
  state.companyProfiles.value.length > 0 ? state.companyProfiles.value[0] : null;

export default companyProfilesSlice.reducer;
