/* eslint-disable import/no-cycle */
import { Action, AnyAction, combineReducers, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit';
import { deleteJwtToken } from './services/webStorageService';

// circular dependency for type export is not a real circular dependency
import userReducer from '../features/auth/store/userSlice';
import jobBoardReducer from '../features/jobBoards/store/jobBoardsSlice';
import jobBoardsFilterReducer from '../features/jobBoards/store/jobBoardsFilterSlice';
import jobBoardDetailReducer from '../features/jobBoards/store/jobBoardDetailSlice';
import productsReducer from '../features/products/store/productsSlice';
import basketReducer from '../features/basket/store/basketSlice';
import companyProfilesReducer from '../features/companyProfiles/store/companyProfilesSlice';
import orderReducer from '../features/orders/store/orderSlice';
import brokerOrderReducer from '../features/brokerOrders/store/brokerOrderSlice';
import usersAdminReducer from '../features/usersAdmin/store/usersAdminSlice';
import brandOrderReducer from '../features/brokerOrders/store/brandOrderSlice';

export const logoutAction = createAction<void>('LOGOUT');
const combinedReducer = combineReducers({
  user: userReducer,
  jobBoards: jobBoardReducer,
  jobBoardsFilter: jobBoardsFilterReducer,
  jobBoardDetail: jobBoardDetailReducer,
  products: productsReducer,
  basket: basketReducer,
  companyProfiles: companyProfilesReducer,
  orders: orderReducer,
  brokerOrders: brokerOrderReducer,
  usersAdmin: usersAdminReducer,
  brandOrders: brandOrderReducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'LOGOUT') {
    deleteJwtToken();
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
