export interface Company {
  name: string;
  address: string;
  vatNumber: string;
  invoiceContact: string;
}

export interface CompanyProfile extends Company {
  id: string;
}

export const toCompany = (o: Record<string, unknown>): Company => {
  return {
    name: o.name as string,
    address: o.address as string,
    vatNumber: o.vatNumber as string,
    invoiceContact: o.invoiceContact as string,
  };
};

export const toCompanyProfile = (o: Record<string, unknown>): CompanyProfile => {
  return {
    ...toCompany(o),
    id: o.id as string,
  };
};

export interface CompanyProfileRequest {
  name: string;
  address: string;
  vatNumber: string;
  invoiceContact: string;
}

export const toCompanyProfileRequest = (companyProfile: CompanyProfile): CompanyProfileRequest => {
  return {
    name: companyProfile.name,
    address: companyProfile.address,
    vatNumber: companyProfile.vatNumber,
    invoiceContact: companyProfile.invoiceContact,
  };
};
