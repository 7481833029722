/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps, Input, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import style from './price.style';

interface PriceProps {
  currency: string | undefined;
  value: number | undefined;
  onChange: (value: number, currency: string) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

export const PriceInput = ({
  currency,
  value,
  onChange,
  placeholder,
  label,
  disabled = false,
}: PriceProps): JSX.Element => {
  const { t } = useTranslation();
  const [priceValue, setPriceValue] = React.useState<string>(value ? value.toFixed(2).toString() : '');
  const [priceCurrency, setPriceCurrency] = React.useState<string>(currency || 'EUR');

  let start: number | null = 0;

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, '');
    val = val.replace(/^([0.])/, '0');
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    let localValue = '';
    if (match) {
      localValue = match[1] + match[2];
    }
    if (val.length > 0) {
      localValue = Number(localValue).toFixed(2);
      e.target.value = localValue;
      e.target.setSelectionRange(start, start);
    } else {
      e.target.value = localValue;
    }
    setPriceValue(localValue);
    onChange(Number(localValue), priceCurrency);
  };

  const onPriceCurrencyChange = (event: SyntheticEvent, data: DropdownProps) => {
    setPriceCurrency(data.value as string);
    onChange(Number(priceValue), data.value as string);
  };

  return (
    <Flex data-testid='create-product' flexWrap='wrap'>
      {label && (
        <Box width={1}>
          <Label style={{ width: '100%', marginBottom: '1px' }}>{label}</Label>
        </Box>
      )}
      <Box width={[1 / 3, 1 / 4]}>
        <Dropdown
          css={style.currency}
          placeholder={t('price.currency')}
          value={priceCurrency}
          fluid
          search
          selection
          options={CURRENCIES_OPTIONS}
          onChange={(event, data) => onPriceCurrencyChange(event, data)}
          disabled={disabled}
        />
      </Box>
      <Box width={[2 / 3, 3 / 4]}>
        <Input
          placeholder={placeholder}
          css={style.price}
          value={priceValue}
          type='text'
          data-testid='price-number-input'
          onChange={event => onChangeValue(event)}
          disabled={disabled}
        />
      </Box>
    </Flex>
  );
};

export const CURRENCIES_OPTIONS = [{ key: 'EUR', text: 'Euro', symbol: '€', value: 'EUR' }] as CurrencyOption[];

export interface CurrencyOption {
  key: string;
  text: string;
  value: string;
  symbol: string;
}
