/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent, useCallback } from 'react';
import _ from 'lodash';

import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Input,
  Label,
  TextArea,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'rebass';
import DatePicker from 'react-datepicker';
import style from './createProduct.style';
import { createJobBoardProduct } from '../store/jobBoardDetailSlice';
import { ContentRef, Price, Product, PRODUCT_TYPE, ProductCreationRequest } from '../model/product';
import { COUNTRIES, getCountriesByRegion, REGION } from '../model/countries';
import { PriceInput } from '../../../shared/PriceInput';
import {
  countriesAreSelected,
  priceIsNotUndefinedOrEmpty,
  regionsAreSelected,
  stringIsNotUndefinedOrEmpty,
} from '../../../shared/utils';
import { FilesDownload } from '../../../shared/FilesDownload';
import { FileUpload } from '../../../shared/FileUpload';
import { selectUserCountryCodes, selectUserRegions } from '../../auth/store/userSlice';
import { useAppDispatch } from '../../../core/coreHooks';

interface CreateProductProps {
  jobBoardId: string;
  onSave?: () => void;
  cloneFrom?: Product;
}

export interface ProductTypeOption {
  key: string;
  text: string;
  value: string;
}

export const CreateProduct = ({ onSave = () => null, jobBoardId, cloneFrom }: CreateProductProps): JSX.Element => {
  const [newProductRequest, setNewProductRequest] = React.useState<ProductCreationRequest>({
    name: cloneFrom ? cloneFrom.name : undefined,
    description: cloneFrom ? cloneFrom.description : undefined,
    productType: cloneFrom ? cloneFrom.productType : undefined,
    target: cloneFrom ? cloneFrom.target : undefined,
    region: cloneFrom ? cloneFrom.region : REGION.GLOBAL,
    countryCodes: cloneFrom ? cloneFrom.countryCodes : [],
    marketPrice: undefined,
    priceFrom: undefined,
    priceTo: undefined,
    contents: cloneFrom ? cloneFrom.contents : [],
  });

  const [finalPrice, setFinalPrice] = React.useState<Price | undefined>(undefined);
  const [validityEndDate, setValidityEndDate] = React.useState<Date | undefined>(undefined);
  const [finalPriceChecked, setFinalPriceChecked] = React.useState<boolean>(finalPrice != null);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userRegions = useSelector(selectUserRegions);

  const userCountries = useSelector(selectUserCountryCodes);

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProductRequest({
      ...newProductRequest,
      name: event.target.value,
    });
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewProductRequest({
      ...newProductRequest,
      description: event.target.value,
    });
  };

  const onChangeTarget = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProductRequest({
      ...newProductRequest,
      target: event.target.value,
    });
  };

  const onCountryChange = (event: SyntheticEvent, data: DropdownProps) => {
    setNewProductRequest({
      ...newProductRequest,
      countryCodes: data.value as string[],
    });
  };

  const onRegionChange = (event: SyntheticEvent, data: DropdownProps) => {
    setNewProductRequest({
      ...newProductRequest,
      region: data.value as REGION,
    });
  };

  const onChangeProductType = (event: SyntheticEvent, data: DropdownProps) => {
    setNewProductRequest({
      ...newProductRequest,
      productType: data.value as PRODUCT_TYPE,
    });
  };

  const onMarketPriceChange = (value: number, currency: string) => {
    setNewProductRequest({
      ...newProductRequest,
      marketPrice: { value, currency },
    });
  };

  const onPriceFromChange = (value: number, currency: string) => {
    setNewProductRequest({
      ...newProductRequest,
      priceFrom: { value, currency },
    });
  };

  const onPriceToChange = (value: number, currency: string) => {
    setNewProductRequest({
      ...newProductRequest,
      priceTo: { value, currency },
    });
  };

  const onSaveProduct = () => {
    dispatch(
      createJobBoardProduct(jobBoardId, {
        ...newProductRequest,
        finalPrice: finalPriceChecked ? finalPrice : undefined,
        validityEndDate: finalPriceChecked ? validityEndDate : undefined,
      })
    );
    onSave();
  };

  const isValid = () => {
    return (
      stringIsNotUndefinedOrEmpty(newProductRequest.name) &&
      stringIsNotUndefinedOrEmpty(newProductRequest.description) &&
      stringIsNotUndefinedOrEmpty(newProductRequest.target) &&
      priceIsNotUndefinedOrEmpty(newProductRequest.marketPrice) &&
      priceIsNotUndefinedOrEmpty(newProductRequest.priceFrom) &&
      priceIsNotUndefinedOrEmpty(newProductRequest.priceTo) &&
      stringIsNotUndefinedOrEmpty(newProductRequest.productType) &&
      (countriesAreSelected(newProductRequest.countryCodes) || regionsAreSelected(newProductRequest.region)) &&
      (!finalPriceChecked || (finalPrice != null && finalPrice.value > 0 && validityEndDate != null))
    );
  };

  const fileUploadHandler = (content: ContentRef) => {
    setNewProductRequest({
      ...newProductRequest,
      contents: newProductRequest.contents != null ? [...newProductRequest.contents, content] : [content],
    });
  };

  const onDeleteHandler = (content: ContentRef) => {
    setNewProductRequest({
      ...newProductRequest,
      contents: (newProductRequest.contents || []).filter(c => c.id !== content.id),
    });
  };

  const onFinalPriceCheckedChange = (checked?: boolean) => {
    setFinalPriceChecked(!!checked);
    if (checked && validityEndDate == null) {
      setValidityEndDate(new Date(new Date().getFullYear(), 11, 31));
    }
  };

  const getRegionOptions = useCallback((): DropdownItemProps[] => {
    const result = userRegions.map(c => ({
      key: c,
      text: c,
      value: c,
    }));
    return _.uniqBy([{ key: REGION.GLOBAL, text: REGION.GLOBAL, value: REGION.GLOBAL }, ...result], 'key');
  }, [userRegions]);

  const getCountryOptions = useCallback(() => {
    const result =
      newProductRequest.region === REGION.GLOBAL || newProductRequest.region == null
        ? COUNTRIES.filter(c => userCountries.includes(c.code))
        : getCountriesByRegion(newProductRequest.region).filter(c => userCountries.includes(c.code));

    return result.map(c => ({
      key: c.code,
      text: c.name,
      value: c.code,
      flag: c.code.toLowerCase(),
    }));
  }, [newProductRequest.region, userCountries]);

  return (
    <div css={style.form} data-testid='create-product'>
      <Flex flexWrap='wrap'>
        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.name')}</Label>
            </Box>
            <Box width={1}>
              <Input
                fluid
                value={newProductRequest.name}
                type='text'
                data-testid='product-name-text-input'
                onChange={event => onChangeName(event)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.description')}</Label>
            </Box>
            <Box width={1}>
              <TextArea
                css={style.textArea}
                value={newProductRequest.description}
                data-testid='product-description-text-area'
                onChange={event => onChangeDescription(event)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.target')}</Label>
            </Box>
            <Box width={1}>
              <Input
                fluid
                value={newProductRequest.target}
                type='text'
                data-testid='product-target-text-input'
                onChange={event => onChangeTarget(event)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.files')}</Label>
            </Box>
            <Box width={1} css={style.fileUpload as never}>
              {newProductRequest.contents != null && (
                <FilesDownload onDelete={onDeleteHandler} contents={newProductRequest.contents} />
              )}
              <FileUpload id='newProduct' onFileUpload={(content: ContentRef) => fileUploadHandler(content)} />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.region')}</Label>
            </Box>
            <Box width={1}>
              <Dropdown
                style={{ width: '100%' }}
                placeholder={t('menu.region')}
                value={newProductRequest.region}
                fluid
                search
                selection
                options={getRegionOptions()}
                onChange={(event, data) => onRegionChange(event, data)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.country')}</Label>
            </Box>
            <Box width={1}>
              <Dropdown
                style={{ width: '100%' }}
                placeholder={t('menu.allCountries')}
                value={newProductRequest.countryCodes}
                multiple
                fluid
                search
                selection
                clearable
                options={getCountryOptions()}
                onChange={(event, data) => onCountryChange(event, data)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.productType')}</Label>
            </Box>
            <Box width={1}>
              <Dropdown
                style={{ width: '100%' }}
                placeholder={t('menu.productType')}
                value={newProductRequest.productType}
                fluid
                selection
                options={productTypeOptions}
                onChange={(event, data) => onChangeProductType(event, data)}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1, 1 / 2]} p={1}>
          <Flex flexWrap='wrap'>
            <Box>
              <Label css={style.label}>{t('product.columns.finalPrice')}</Label>
              <Checkbox
                style={{ paddingTop: '4px', paddingLeft: '10px' }}
                checked={finalPriceChecked}
                onChange={(e, { checked }) => onFinalPriceCheckedChange(checked)}
              />
            </Box>
            <Box width={1}>
              <PriceInput
                currency={finalPrice?.currency}
                value={finalPrice?.value}
                onChange={(value, currency) => setFinalPrice({ value, currency })}
                disabled={!finalPriceChecked}
              />
            </Box>
          </Flex>
        </Box>
        <Box width={[1, 1 / 2]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.validityEndDate')}</Label>
            </Box>
            <Box>
              <DatePicker
                css={style.datepicker}
                minDate={new Date()}
                isClearable={finalPriceChecked}
                selected={validityEndDate}
                dateFormat='yyyy-MM-dd'
                onChange={(v?: Date | null) => setValidityEndDate(v || undefined)}
                placeholderText='yyyy-mm-dd'
                customInput={<Input css={style.datePickerInput} />}
                disabled={!finalPriceChecked}
              />
            </Box>
          </Flex>
        </Box>

        <Box width={[1, 1 / 2]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.marketPrice')}</Label>
            </Box>
            <Box width={1}>
              <PriceInput
                currency={newProductRequest.marketPrice?.currency}
                value={newProductRequest.marketPrice?.value}
                onChange={(value, currency) => onMarketPriceChange(value, currency)}
              />
            </Box>
          </Flex>
        </Box>
        <Box width={[1, 1 / 2]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.priceFrom')}</Label>
            </Box>
            <Box width={1}>
              <PriceInput
                currency={newProductRequest.priceFrom?.currency}
                value={newProductRequest.priceFrom?.value}
                onChange={(value, currency) => onPriceFromChange(value, currency)}
              />
            </Box>
          </Flex>
        </Box>
        <Box width={[1, 1 / 2]} p={1}>
          <Flex flexWrap='wrap'>
            <Box width={1}>
              <Label css={style.label}>{t('product.columns.priceTo')}</Label>
            </Box>
            <Box width={1}>
              <PriceInput
                currency={newProductRequest.priceTo?.currency}
                value={newProductRequest.priceTo?.value}
                onChange={(value, currency) => onPriceToChange(value, currency)}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Button
        css={style.createButton}
        primary
        fluid
        data-testid='product-save-button'
        onClick={() => onSaveProduct()}
        disabled={!isValid()}>
        {t('product.create')}
      </Button>
    </div>
  );
};

export const productTypeOptions: ProductTypeOption[] = Object.keys(PRODUCT_TYPE).map(k => {
  return {
    key: k.toString(),
    text: k.toString(),
    value: k.toString(),
  };
});
