import { API_URL } from '../../../constants';

const RECRUITER_ORDERS__URL = `${API_URL}/orders/recruiter`;

const paths = {
  getOrders: (yearFilter: string): string => `${RECRUITER_ORDERS__URL}${yearFilter}`,
  getOrder: (orderId: string): string => `${RECRUITER_ORDERS__URL}/${orderId}`,
  getAcceptPrice: (id: string): string => `${RECRUITER_ORDERS__URL}/${id}/accept`,
  gerRejectPrice: (id: string): string => `${RECRUITER_ORDERS__URL}/${id}/reject`,
  getUpdateQuantity: (id: string): string => `${RECRUITER_ORDERS__URL}/${id}/update-quantity`,
  getUpdateOrderCompany: (id: string): string => `${RECRUITER_ORDERS__URL}/${id}/update-company`,
};

export default paths;
